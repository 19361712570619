import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import NewQuoteForm from './NewQuoteForm';

const NewQuoteModal = ({ handleSubmit, openModal, closeModal }) => (
  <Modal
    show={openModal === 'newQuoteModal'}
    onHide={closeModal}
    className="modal-address"
  >
    <Modal.Header closeButton>
      <Modal.Title>Request a new quote</Modal.Title>
    </Modal.Header>
    <NewQuoteForm />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(NewQuoteModal);
