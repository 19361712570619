import React, { useEffect, useState } from 'react';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Accordion } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import AddBillingAddressModal from './AddBillingAddressModal';
import { months, getYears } from "../../../../utils/date-utils";
import { isEqual } from "lodash";

const required = value => value || value === 0 ? undefined : 'Required';

const getAddress = ({ Address1, Address2 } = {}) => {
  if (!Address2) return Address1;
  return `${Address1} ${Address2}`;
}

const FORM_NAME = 'checkout-payment-form';

const CheckoutPaymentForm = props => {
  const {
    handleSubmit,
    showModal,
    locations,
    shippingAddress,
    handleChange,
    closeModal,
    billingAddress,
    paymentOption,
    paymentOptions,
    saveBillingAddressLoading
  } = props;

  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);

  const [activeAddress, setActiveAddress] = useState(false);

  const toggleAddressBook = value => {
    setIsAddressBookOpen(value);
    if (value === false) return setActiveAddress(null);
    const { ID } = billingAddress || {};
    setActiveAddress(ID || -1)
  }

  const handleSetActiveAddress = ({ ID }) => {
    setActiveAddress(ID);
  }

  const setPaymentOption = value => {
    handleChange(FORM_NAME, "paymentOption", value);
  }

  const handleAddressBookSubmit = () => {
    const x = activeAddress === -1 ?
      billingAddress :
      (locations || []).find(({ ID }) => ID === activeAddress);

    if (!x) return;

    const address = {
      ...x,
      nameFirst: shippingAddress.nameFirst,
      nameLast: shippingAddress.nameLast
    }
    handleChange(FORM_NAME, "billingAddress", address);
    toggleAddressBook(false)
  }

  const isCustomAddress = !!billingAddress && !billingAddress.ID;

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <h5>Billing Details</h5>
      <div className="box box-ship-to">
        <div>
          {isAddressBookOpen &&
            <div>
              <a
                className="float-right"
                onClick={() => toggleAddressBook(false)}
              >
                Close
              </a>
              <h5>Change Billing Address</h5>
              {(locations || []).map((location, index) => {
                const isActive = activeAddress === location.ID;
                return (
                  <div
                    className={isActive ? 'address active' : 'address'}
                    key={index}
                    onClick={() => handleSetActiveAddress(location)}
                  >
                    {isActive && <Icon name="dot-circle" />}
                    {!isActive && <Icon name="circle" />}
                    <div style={{ pointerEvents: "none" }}>
                      <div>
                        {getAddress(location)}
                        {' '}
                        {location.City}
                        ,
                        {' '}
                        {location.PostalCode}
                        ,
                        {' '}
                        {location.StateProvince}
                        ,
                        {' '}
                        {location.zip}
                        {' '}
                        {location.state}
                      </div>
                      <div>
                        {location.Name}
                      </div>
                    </div>
                  </div>
                );
              })}
              {isCustomAddress && (
                <div
                  className={activeAddress === -1 ? 'address active' : 'address'}
                  onClick={() => handleSetActiveAddress({ ID: -1 })}
                >
                  {activeAddress === -1 && <Icon name="dot-circle" />}
                  {activeAddress !== -1 && <Icon name="circle" />}
                  <div>
                    <div>
                      {getAddress(billingAddress)}
                      {' '}
                      {billingAddress.City}
                      ,
                      {' '}
                      {billingAddress.PostalCode}
                      ,
                      {' '}
                      {billingAddress.StateProvince}
                      ,
                      {' '}
                      {billingAddress.zip}
                      {' '}
                      {billingAddress.state}
                    </div>
                  </div>
                </div>
              )}
              <Row className="actions">
                {locations &&
                  locations.length === 0 &&
                  <h5 style={{ paddingLeft: '1em', paddingBottom: '1em' }}>
                    No address book found. Please enter a billing address to continue.
                  </h5>
                }
                <Col sm={6}>
                  <Button
                    type="button"
                    variant="outline-primary"
                    onClick={() => showModal('AddBillingAddressModal')}
                  >
                    {locations && locations.length > 0 ? 'Bill To Different Address' : 'Bill to Address'}
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={!activeAddress}
                    onClick={handleAddressBookSubmit}
                  >
                    Use This Address
                  </Button>
                </Col>
              </Row>
            </div>}
          {!isAddressBookOpen &&
            <div>
              <div className="float-right">
                <a
                  className="float-right"
                  onClick={() => {
                    handleChange(FORM_NAME, "billingAddress", shippingAddress);
                  }}
                >
                  Use shipping address
                </a>
                <a
                  style={{ marginRight: '10px' }}
                  className="float-right"
                  onClick={() => toggleAddressBook(true)}
                >
                  Change
                </a>
              </div>
              {billingAddress && (
                <address>
                  {billingAddress.nameFirst} {billingAddress.nameLast}<br />
                  {getAddress(billingAddress)}<br />
                  {billingAddress.City}, {billingAddress.PostalCode}, {billingAddress.StateProvince}<br />
                  {billingAddress.specialInstructions}
                  {/* Store Name - Store Id<br /> */}
                </address>
              )}
            </div>
          }
        </div>
        {/* {!isBillingAddressOpen &&
                    <div>
                        <a
                            className="float-right"
                            onClick={() => toggleBillingAddress(true)}
                        >
                            Change
                        </a>
                        <span className="text-primary">Billing address:</span>
                        {' '}
                        {isEqual(billingAddress, shippingAddress) ?
                            `Same as shipping address` :
                            (
                                billingAddress &&
                                <address>
                                    {billingAddress.nameFirst} {billingAddress.nameLast}<br />
                                    {getAddress(billingAddress)}<br />
                                    {billingAddress.City}, {billingAddress.PostalCode}, {billingAddress.StateProvince}<br />
                                </address>
                            )

                        }

                    </div>
                } */}
      </div>

      {paymentOptions && paymentOptions.length > 0 && <h5>Payment Method</h5>}
      <div className="box box-accordion">
        <Accordion activeKey={`${paymentOption}`}>
          {paymentOptions
            && paymentOptions.some((option) => option.paymentOption === 1)
            && (
              <div>
                <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => { setPaymentOption(1) }}>
                  {paymentOption === 1 ?
                    (<><Icon name="dot-circle" /> Credit Card</>)
                    :
                    (<><Icon name="circle" /> Credit Card</>)
                  }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div>
                    <div className="billing-alert">
                      <p>
                        Convenience fee will be applied to all credit card orders.
                      </p>
                    </div>
                    <Row>
                      <Col sm={12}>
                        <Field
                          name="details.cardNumber"
                          type="tel"
                          component={FormField.Input}
                          placeholder="Card Number"
                          validate={paymentOption === 1 ? [required] : []}
                          format={
                            (value) => {
                              if (value) {
                                if (value.length < 4) {
                                  return value;
                                } else {
                                  // if (value.length > 16) return previousValue;
                                  let v = '';
                                  value.split('').map((c, i) => {
                                    return (i % 4 === 0 && i !== 0) ? v += " - " + c : v += c;
                                  })
                                  return v;
                                }
                              } else {
                                return "";
                              }
                            }
                          }
                          normalize={
                            (value, previousValue) => {
                              const regExp = /^[0-9]+$/g;
                              value = value.trim();
                              value = value.replace(/\s+/g, "");
                              value = value.replace(/-+/g, "");
                              const result = value.match(regExp);
                              if (result) {
                                return value;
                              } else {
                                if (value.length > 0) {
                                  return previousValue;
                                }
                              }
                            }
                          }
                        />
                      </Col>
                      <Col sm={12}>
                        <Field
                          name="details.nameOnCard"
                          type="text"
                          component={FormField.Input}
                          placeholder="Name on Card"
                          validate={paymentOption === 1 ? [required] : []}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          name="details.month"
                          type="text"
                          component={FormField.SelectReact}
                          placeholder="Month"
                          validate={paymentOption === 1 ? [required] : []}
                          options={months}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          name="details.year"
                          type="text"
                          component={FormField.SelectReact}
                          placeholder="Year"
                          validate={paymentOption === 1 ? [required] : []}
                          options={getYears()}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          name="details.cardSecurityCode"
                          type="text"
                          component={FormField.Input}
                          placeholder="Security Code"
                          normalize={
                            (value, previousValue) => {
                              const regExp = /^[0-9]+$/g;
                              const result = value.match(regExp);
                              if (result) {
                                return value;
                              }
                              if (value.length > 0) return previousValue;
                            }
                          }
                          validate={paymentOption === 1 ? [required] : []}
                        />
                      </Col>
                    </Row>
                  </div>
                </Accordion.Collapse>
              </div>
            )
          }

          {paymentOptions
            &&
            paymentOptions.some((option) => option.paymentOption === 2)
            &&
            (
              <div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  onClick={() => setPaymentOption(2)}>
                  {paymentOption === 2 ?
                    (<><Icon name="dot-circle" /> Purchase Order</>)
                    :
                    (<><Icon name="circle" /> Purchase Order</>)
                  }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div>
                    <Field
                      name="details.poNumber"
                      type="text"
                      component={FormField.Input}
                      placeholder="Purchase Order Number"
                    // validate={paymentOption === 2 ? [required] : []}
                    />
                    <Field
                      name="details.poDate"
                      type="text"
                      component={FormField.Datepicker}
                      placeholder="Purchase Order Date"
                      validate={paymentOption === 2 ? [required] : []}
                    />
                    <Field
                      name="details.poName"
                      type="text"
                      component={FormField.Input}
                      placeholder="Purchase Order Approved by Name"
                    // validate={paymentOption === 2 ? [required] : []}
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            )
          }

          {paymentOptions
            && paymentOptions.some((option) => option.paymentOption === 3)
            && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                  onClick={() => setPaymentOption(3)}>
                  {paymentOption === 3 ?
                    (<><Icon name="dot-circle" /> Check</>)
                    :
                    (<><Icon name="circle" /> Check</>)
                  }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <div>
                    <div className="billing-alert">
                      <p>
                        Items will be released upon receipt of check. Details of where to send check will be provided on order confirmation.
                      </p>
                    </div>
                    <Field
                      name="details.checkName"
                      type="text"
                      component={FormField.Input}
                      placeholder="Business Name on Check"
                      validate={paymentOption === 3 ? [required] : []}
                    />
                    <Field
                      name="details.checkNumber"
                      type="text"
                      component={FormField.Input}
                      placeholder="Check Number"
                      validate={paymentOption === 3 ? [required] : []}
                      normalize={
                        (value, previousValue) => {
                          if (value.length > 10) return previousValue
                          return value;
                        }
                      }
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            )
          }

          {paymentOptions
            && paymentOptions.some((option) => option.paymentOption === 4)
            &&
            (
              <div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  onClick={() => setPaymentOption(4)}>
                  {paymentOption === 4 ?
                    (<><Icon name="dot-circle" /> Bank ACH</>)
                    :
                    (<><Icon name="circle" /> Bank ACH</>)
                  }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <div>
                    <div className="billing-alert">
                      <p>
                        Items will be released upon receipt of Bank Transfer. Details of how to process the ACH will be provided on order confirmation.
                      </p>
                    </div>
                    <Field
                      name="details.bankName"
                      type="text"
                      component={FormField.Input}
                      placeholder="Bank Name"
                      validate={paymentOption === 4 ? [required] : []}
                    />
                    <Field
                      name="details.nameOnAccount"
                      type="text"
                      component={FormField.Input}
                      placeholder="Name on Account"
                      validate={paymentOption === 4 ? [required] : []}
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            )
          }
        </Accordion>
      </div>

      {paymentOptions &&
        paymentOptions.length === 0 &&
        (
          <h5>
            You currently do not have any payment options enabled. Please contact Concept Services.
          </h5>
        )
      }
      <Button
        type="submit"
        variant="primary"
        disabled={
          saveBillingAddressLoading ||
          (paymentOptions && paymentOptions.length === 0) ||
          paymentOption === -1
        }>
        Review Order
      </Button>


      <AddBillingAddressModal
        showModal={showModal}
        handleSubmit={(address) => {
          closeModal('add-billing-address-form');
          toggleAddressBook(false);
          handleChange(FORM_NAME, "billingAddress", address);
        }}
      />
    </form>
  );
};

const selector = formValueSelector(FORM_NAME)

const mapState = state => {
  const { billingAddress, paymentOption } = selector(state, 'billingAddress', 'paymentOption')
  return {
    billingAddress,
    paymentOption
  };
};

const mapDispatch = dispatch => bindActionCreators({
  handleChange: change,
  ...actionCreators
}, dispatch);

const form = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(CheckoutPaymentForm);

export default connect(mapState, mapDispatch)(form)
