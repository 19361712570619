import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';
const max = maximum => value => (value || []).length > maximum ? `Maximum ${maximum} files` : undefined;

const HelpModalForm = props => {
  const {
    handleSubmit,
    closeModal,
    submitting,
    result
  } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Modal.Body>
        {result && (
          <div className="general-alert">
            <p>
              Your request has been submitted and will be responded to shortly.
            </p>
          </div>
        )}

        <Field
          name="comments"
          type="text"
          validate={[required]}
          component={FormField.Textarea}
          label="How can we help you?"
          placeholder="Type here"
          rows={5}
        />

        <div className="file-info">
          <Field
            name="upload"
            multiple
            maximum={3}
            component={FormField.InputFileDropzone}
            instructions="Please upload any attachments you would like use to review as part of your question or request"
          />
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submitting}
        >
          Send
        </Button>
      </Modal.Footer>

    </form>
  );
};

export default reduxForm({
  form: 'help-form'
})(HelpModalForm);
