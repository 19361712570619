import React from 'react';
import classNames from 'classnames';
import { getStatusLabel } from '../routes/quotes/statusDict';
import moment from 'moment';

const QuoteStatus = ({ status, quote }) => {
  const {
    ExpirationDate
  } = quote || {};

  const hasExpired = status === 5 || moment(ExpirationDate).isBefore(moment());

  return (
    <div
      className={classNames(
        'quote-status',
        { gray: status === 0 },
        { blue: status === 1 },
        { purple: status === 2 },
        { green: status === 3 },
        { red: (status === 4) || (hasExpired) },
        { red: status === 5 },
        { red: status === 6 }
      )}
    >
      <span className="circle" /> {getStatusLabel(status, hasExpired)}
    </div>
  );
}

export default QuoteStatus;
