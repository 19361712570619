import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

const CardFeaturedPackage = ({ name, description, numProducts, totalSell, id, shop, hidePrice }) => (
  <div className="box-featured-package">
    <h4>{name}</h4>
    <h5>{numProducts || 0} Products</h5>
    <p>{description}</p>
    {!hidePrice && (
      <div className="price">
        <sup>$</sup>{numeral(totalSell).format('0,0.00')}
      </div>
    )}
    <Link to={`/shop/packages?packageId=${id}&shop=${shop}`}>View Package</Link>
  </div>
);

export default CardFeaturedPackage;
