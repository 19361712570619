import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import FormError from './FormError';
import Toggle from '../ui/Toggle';
import Icon from '../ui/Icon';
import classNames from 'classnames';
import moment from 'moment';

const getValidationState = meta => {
  if (meta.touched) {
    if (meta.valid === false) {
      return 'has-error';
    }
    if (meta.warning) {
      return 'has-warning';
    }
    if (meta.valid === true) {
      // return 'success';
    }
  }

  return null;
};

const Input = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectHTML = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} as="select" {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectReact = (
  {
    input,
    label,
    type,
    meta,
    help,
    selectStyles,
    ...props
  }
) => {
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <ReactSelect
          {...props}
          value={(props.options || []).find(o => o.value === input.value)}
          onChange={value => input.onChange((value || {}).value)}
          onBlur={() => input.onBlur(input.value)}
          classNamePrefix="react-select"
          styles={selectStyles}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const SelectReactAsync = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => {
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <AsyncSelect
          {...props}
          value={(props.options || []).find(o => o.value === input.value)}
          onChange={value => input.onChange((value || {}).value)}
          onBlur={() => input.onBlur(input.value)}
          classNamePrefix="react-select"
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const Creatable = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <ReactSelect.Creatable
        {...props}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
      />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const InputGroup = (
  {
    input,
    label,
    type,
    meta,
    help,
    children,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      {children}
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const formatFiles = value => {
  if (!value) return [];
  const keys = Object.keys(value);
  return (keys || []).map(key => value[key]);
};

const InputFileUpload = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    ...props
  }
) => {
  const handleFileChange = e => {
    e.preventDefault();
    const { files: inputFiles } = e.target;
    if (!inputFiles) return;
    const f = formatFiles(inputFiles);
    if (!multiple) {
      input.onChange(f[0]);
      return;
    }
    input.onChange([...input.value, ...f]);
  };
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <div className="file-upload">
          <div className="btn-file-upload btn btn-secondary">
            Choose Files
            <Form.Control
              value=""
              type="file"
              name={input.name}
              onChange={handleFileChange}
              multiple={multiple || false}
            />
          </div>
        </div>
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const InputFileDropzone = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    disabled,
    instructions,
    selectedFiles,
    removeFile,
    removeAllFiles,
    maximum,
    ...props
  }
) => {

  const { value } = input || {};

  const onDrop = (files, r, e) => {
    e.preventDefault();
    if (!multiple) {
      input.onChange([files[0]]);
      return;
    }
    const payload = [...(value || []), ...(files || [])];
    input.onChange(maximum ? payload.slice(0, maximum) : payload);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Form.Group
      controlId={input.name}
      className={getValidationState(
        Object.assign({}, meta, { touched: meta.touched || meta.dirty })
      )}
    >
      <div className="form-container">
        {value && value.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            {(value || []).map((file, idx) => {
              const handleRemove = () => {
                const payload = (value || []).filter((f, i) => i !== idx);
                input.onChange(payload);
              }
              return (
                <div style={{ padding: '10px 0'}}>
                  <span style={{ color: '#c5a44d' }}>
                    {file.name}
                  </span>
                  <span
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={handleRemove}
                  >
                    <Icon name="times" />
                  </span>
                </div>
              )
            })}

          </div>
        )}
      <div className="file-import" {...getRootProps()}>
        {label && <Form.Label>{label}</Form.Label>}
          <div className="file-upload">
            <Form.Control
              value=""
              type="file"
              name={input.name}
              onChange={e => e.preventDefault()}
              {...getInputProps({
                disabled: disabled || false,
                multiple: multiple || false,
                onChange: e => e.preventDefault()
              })}
              // {...(props || {})}
            />
            <div>
              <div><Icon name="arrow-circle-up" /></div>
              <span className="btn btn-outline-primary">Add Files</span>
              {instructions
                ? <p style={{ top: '72%' }}>{instructions}</p>
                : <p style={{ top: '72%' }}>
                  Drag 'n' drop some files here, or click to select files
                </p>}
            </div>
          </div>
        </div>
      </div>
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || meta.dirty) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </Form.Group>
  );
};

const InputFileDropzone2 = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    disabled,
    instructions,
    selectedFiles,
    removeFile,
    removeAllFiles,
    ...props
  }
) => {
  const onDrop = useCallback(
    files => {
      if (!multiple) {
        input.onChange(files[0]);
        return;
      }
      input.onChange(files);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Form.Group
      controlId={input.name}
      className={getValidationState(
        Object.assign({}, meta, { touched: meta.touched || meta.dirty })
      )}
    >
      <div className="file-import" {...getRootProps()}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className="form-container">
          {
            selectedFiles && selectedFiles.length > 0 ?
              <>
                {
                  selectedFiles.length <= 3 ?
                    selectedFiles.map((file, index) => {
                      return (
                        <div key={index} className='row'
                          style={{
                            justifyContent: 'space-between',
                            paddingLeft: '2em',
                            paddingRight: '2em',
                            maringBottom: '1em'
                          }}
                        >
                          <span>{file.name}</span>
                          <a href="#remove" onClick={
                            () => {
                              removeFile(file)
                            }
                          }>
                            Remove
                          </a>
                        </div>
                      )
                    })
                    :
                    <span>You can only upload a maximum of 3 files</span>
                }
                <div style={{
                  marginTop: '3rem',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  bottom: '0.25rem',
                  right: '3rem'
                }}>
                  <div onClick={() => removeAllFiles()}>
                    <a href='#removeall'>Reset</a>
                  </div>
                </div>
              </>
              :
              <div className="file-upload">
                <Form.Control
                  value=""
                  type="file"
                  name={input.name}
                  {...getInputProps({
                    disabled: disabled || false,
                    multiple: multiple || false
                  })}
                />
                <div>
                  <div><Icon name="arrow-circle-up" /></div>
                  <span className="btn btn-outline-primary">Add Files</span>
                  {instructions
                    ? <p style={{ top: '72%' }}>{instructions}</p>
                    : <p style={{ top: '72%' }}>
                      Drag 'n' drop some files here, or click to select files
                    </p>}
                </div>
              </div>
          }

          {/* <div className="file-upload">
            <Form.Control
              value=""
              type="file"
              name={input.name}
              {...getInputProps({
                disabled: disabled || false,
                multiple: multiple || false
              })}
            />
            <div>
              <div><Icon name="arrow-circle-up" /></div>
              <span className="btn btn-outline-primary">Add Files</span>
              {instructions
                ? <p style={{ top: '72%' }}>{instructions}</p>
                : <p style={{ top: '72%' }}>
                  Drag 'n' drop some files here, or click to select files
                </p>}
            </div>
          </div> */}
        </div>
      </div>
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || meta.dirty) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </Form.Group>
  );
};

const InputToggle = (
  {
    input,
    label,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    <div className="form-container">
      {props.type === 'radio' &&
        <Toggle.Radio {...input} {...props} label={label} />}
      {props.type === 'checkbox' &&
        <Toggle.Checkbox {...input} {...props} label={label} />}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Textarea = (
  {
    input,
    label,
    type,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} as="textarea" />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Datepicker = (
  {
    input,
    label,
    meta,
    help,
    required,
    disableWeekends,
    ...props
  }
) => {
  return (
    <Form.Group
      controlId={input.name}
      className={classNames('form-group-datepicker', getValidationState(meta))}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={
          disableWeekends ? 'form-container disable-weekends' : 'form-container'
        }
      >
        <DatePicker
          className="form-control"
          onChange={value => input.onChange(value.toString())}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur()}
          dateFormat="MM/dd/yyyy"
          selected={
            input.value && moment(input.value).isValid()
              ? moment(input.value).toDate()
              : null
          }
        />
        <Icon name="calendar" />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const getClassInlineCheckbox = (required, disabled) => {
  if (required && disabled) return 'required text-muted';
  if (required) return 'required';
  if (disabled) return 'text-muted';
  return '';
};

const InlineCheckbox = (
  {
    input,
    label,
    altLabel,
    meta,
    required,
    help,
    disabled,
    hideErrorMessage,
    style = {},
    checkBoxProps = {},
    inputContainerStyle,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={classNames(getValidationState(meta))}
    style={disabled ? { opacity: '.75', ...style } : style}
  >
    <div
      className="form-container inline-checkbox"
      style={inputContainerStyle || {}}
    >
      <label className="checkbox">
        <Form.Control
          {...input}
          type="checkbox"
          {...props}
          disabled={disabled}
          checked={input.value === true}
        />
        <div className="checkbox-slider" {...checkBoxProps}>
          <Icon name="check" />
        </div>
      </label>
      {label &&
        <strong className={getClassInlineCheckbox(required, disabled)}>
          {label}
        </strong>}
      {altLabel && altLabel}
      {help && <Form.Text>{help}</Form.Text>}
      {!hideErrorMessage &&
        meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

export default {
  Input,
  InputGroup,
  InputFileUpload,
  InputFileDropzone,
  InputToggle,
  Textarea,
  SelectHTML,
  SelectReact,
  SelectReactAsync,
  Creatable,
  Datepicker,
  InlineCheckbox
};
