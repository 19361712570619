import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';

import QuoteStatus from '../../../ui/QuoteStatus';
import LocationMap from './LocationMap';
import { authenticatedHref } from '../../../../api/fetch';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/d/Y');
};

const LocationOverview = ({ handleTabChange, result }) => {
  const {
    logoHref,
    locationName,
    address1,
    address2,
    city,
    state,
    postalCode,
    orders,
    quotes,
    invoices,
    documents,
    statuses,
    progressDisplay,
    lat,
    lng
  } = result || {};

  const completedStatuses = (statuses || [])
    .filter(({ status }) => status === 'Complete');
  const percentComplete = ((statuses || []).length
    ? (completedStatuses || []).length / (statuses || []).length
    : 0) * 100;
  const location = !!lat && !!lng ? [{ lat, lng, locationName }] : [];

  return (
    <div className="box-location-overview">
      <Row>
        <Col md={12}>
          <h2>Location Overview</h2>

          <Row>
            <Col sm={8} >
              <div className="details">
              <div>
                <img src={logoHref} alt={locationName} />
              </div>
              <table>
                <tbody>
                  <tr>
                    <th><Icon name="flag" fixedWidth /> Location Name</th>
                    <td>{locationName}</td>
                  </tr>
                  <tr>
                    <th><Icon name="map-marker" fixedWidth /> Address</th>
                    <td>
                      {address1}{' '}{address2}<br />
                      {city}, {state} {postalCode || ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </Col>
            <Col sm={4}>
              <div class="summary">
                <div class="box-body">
                  <LocationMap geocodes={location}  containerHeight="190" />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {!!(statuses || []).length && progressDisplay &&
              <Col sm={4}>
                <div className="summary">
                  <div className="summary-header">
                    <h3>Project Tracker</h3>
                  </div>
      
                    <div className="summary-body">

                      {!!(statuses || []).length &&
                        progressDisplay &&
                        <div>
                          <div>
                            <b>{(completedStatuses || []).length}</b>
                            {' '}
                            of
                            {' '}
                            <b>{(statuses || []).length}</b>
                            {' '}
                            Steps Completed
                          </div>
                          <ProgressBar now={percentComplete} />
                        </div>}
                    </div>
                  <a
                    onClick={() => handleTabChange('project')}
                    className="summary-link"
                  >
                    View Project Tracker
                  </a>
                </div>
              </Col>}
            {(quotes || {}).length > 0 &&
              <Col sm={4}>
                <div className="summary">
                  <div className="summary-header">
                    <h3>Quotes</h3>
                  </div>
                  <table>
                    <tbody>
                      {((result || {}).quotes || []).map((q, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`/quotes/${q.Id}`}>
                                {q.Name}
                              </Link>
                            </td>
                            <td><QuoteStatus status={q.Status} quote={q}/></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <a
                    onClick={() => handleTabChange('quotes')}
                    className="summary-link"
                  >
                    View All Quotes
                  </a>
                </div>
              </Col>}
            {(orders || []).length > 0 &&
              <Col sm={4}>
                <div className="summary">
                  <div className="summary-header">
                    <h3>Orders</h3>
                  </div>
                  <table>
                    <tbody>
                      {(orders || []).map((o, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`/orders/${o.orderNo}`}>{o.name}</Link>
                            </td>
                            <td className="text-right">
                              {formatTime(o.dateOrder)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <a
                    onClick={() => handleTabChange('orders')}
                    className="summary-link"
                  >
                    View All Orders
                  </a>
                </div>
              </Col>}
            {(invoices || []).length > 0 &&
              <Col sm={4}>
                <div className="summary">
                  <div className="summary-header">
                    <h3>Invoices</h3>
                  </div>
                  <table>
                    <tbody>
                      {(invoices || []).map((i, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Link to={`/invoices/${i.invoiceNo}`}>
                                {i.name}
                              </Link>
                            </td>
                            <td className="text-right">{formatTime(i.date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <a
                    onClick={() => handleTabChange('invoices')}
                    className="summary-link"
                  >
                    View All Invoices
                  </a>
                </div>
              </Col>}
            {(documents || []).length > 0 &&
              <Col sm={4}>
                <div className="summary">
                  <div className="summary-header">
                    <h3>Documents</h3>
                  </div>
                  <table>
                    <tbody>
                      {(documents || []).map((d, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <a href={authenticatedHref(`documents/${d._id}`)} download>
                                {d.filename}
                              </a>
                            </td>
                            <td className="text-right">
                              <a href={authenticatedHref(`documents/${d._id}`)} download>
                                <Icon name="download" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <a
                    onClick={() => handleTabChange('documents')}
                    className="summary-link"
                  >
                    View All Documents
                  </a>
                </div>
              </Col>}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LocationOverview;
