import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Carousel, ProgressBar } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Notification from '../../ui/Notification';
import { actionCreators } from '../../../state/modals-dux';
import moment from 'moment';
import numeral from 'numeral';

import HomepageMap from './HomepageMap';
import HelpModal from './HelpModal';
import QuoteStatus from '../../ui/QuoteStatus';
import NewQuoteModal from '../quotes/quotes-search/NewQuoteModal';
import SelectLocationsForm from './SelectLocationsForm';
import fetchDux from '../../../state/fetch-dux';
import { getShop, setShop } from '../../../utils/shop-utils';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
// import { downloadDocuments } from './../../../api/api';
import { authenticatedHref } from '../../../api/fetch';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/d/Y');
};

const result = {
  quotesTotal: 0,
  quotes: [
    { proposalNo: 1, name: 'Quote Name 1', status: 0 },
    { proposalNo: 2, name: 'Quote Name 1', status: 1 },
    { proposalNo: 3, name: 'Quote Name 1', status: 2 },
    { proposalNo: 4, name: 'Quote Name 1', status: 3 }
  ],
  ordersTotal: 6,
  orders: [
    { orderNo: 1, dateOrder: '1/1/2021', name: 'Order Name 1' },
    { orderNo: 2, dateOrder: '1/2/2021', name: 'Order Name 2' },
    { orderNo: 3, dateOrder: '1/3/2021', name: 'Order Name 3' },
    { orderNo: 4, dateOrder: '1/4/2021', name: 'Order Name 4' }
  ],
  documentsTotal: 8,
  documents: [
    { documentNo: 1, name: 'Document Name 1', download: '#' },
    { documentNo: 2, name: 'Document Name 2', download: '#' },
    { documentNo: 3, name: 'Document Name 3', download: '#' },
    { documentNo: 4, name: 'Document Name 4', download: '#' }
  ]
};

const checkWebAccess = (websiteOptions, webAccessId) => {
  const result = !!(websiteOptions || [])
    .find(({ websiteAccessTypeID }) => websiteAccessTypeID === webAccessId);
  return result;
};

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = { currentLocation: null };
  }
  componentWillMount() {
    const {
      getLocationById,
      getShopHome,
      getCart,
      user,
      getAllOrders,
      getDocuments
    } = this.props;

    const {
      userLocations
    } = user || {};

    const shop = getShop(user || {});

    const companyLocations = (userLocations || [])
      .filter(({ companyID: mapCompanyId }) => mapCompanyId === shop);

    this.setState({ currentLocation: (companyLocations[0] || {}).locationId });

    //get company details
    getShopHome(shop, null, false, null);

    //get default location details
    if ((companyLocations[0] || {}).locationId) {
      getLocationById((companyLocations[0] || {}).locationId);
    }

    //getCart for specific company
    getCart({ id: null, companyId: shop });

    //get the list of all the orders of this user
    getAllOrders();

    //get list of all the users documents
    getDocuments('?limit=5');
  }
  render() {
    const {
      showModal,
      user: userResult,
      clearGetUserDetails,
      getUserDetailsError,
      getLocationById,
      getLocationByIdResult,
      getLocationByIdError,
      getLocationByIdInProgress,
      clearGetLocationById,
      getShopHome,
      clearGetShopHome,
      getShopHomeResult,
      getShopHomeError,
      getCartResult,
      getCart,
      ordersResult,
      ordersInProgress,
      getOrdersError,
      documentsResult,
      documentsError,
      downloadDocuments
    } = this.props;

    const { currentLocation } = this.state;

    const shop = getShop(userResult || {});

    const {
      user,
      userCompanies,
      userLocations,
      websiteOptions
    } = userResult || {};

    const {
      statuses,
      address1,
      address2,
      city,
      postalCode,
      stateProvince,
      progressDisplay
    } = getLocationByIdResult || {};

    const {
      featuredPackages
    } = getShopHomeResult || {};

    const {
      shoppingCartItems
    } = getCartResult || {};

    const {
      nameFirst
    } = user || {};

    const activeCompanyIndex = (userCompanies || [])
      .findIndex(({ companyID }) => companyID === shop);

    const metadata = {
      title: 'Home | Checkout | Concept Services'
    };

    const handleSelect = index => {
      const {
        companyID
      } = (userCompanies || [])[index];

      setShop(companyID);

      const newCompanyLocations = (userLocations || [])
        .filter(({ companyID: mapCompanyId }) => mapCompanyId === companyID);

      this.setState({
        currentLocation: (newCompanyLocations[0] || {}).locationId
      });

      //get company details
      getShopHome(companyID, null, false, null);
      //getCart for specific company
      getCart({ id: null, companyId: companyID });

      if ((newCompanyLocations || []).length) {
        //get default location details
        getLocationById(
          (newCompanyLocations[0] || {}).locationId,
          null,
          true,
          null
        );
        return;
      }
      clearGetLocationById();
    };

    const handleLocationChange = value => {
      this.setState({ currentLocation: value });
      getLocationById(value, null, true, null);
    };

    const companyLocations = (userLocations || [])
      .filter(({ companyID }) => companyID === shop);
    const completedStatuses = (statuses || [])
      .filter(({ status }) => status === 'Complete');
    const percentComplete = ((statuses || []).length
      ? (completedStatuses || []).length / (statuses || []).length
      : 0) * 100;

    return (
      <Layout route="homepage" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col lg={6}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <span className="active">
                      Take your concept to the next level
                    </span>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                {nameFirst}, welcome to Concept Services
              </h2>
            </Col>
            <Col lg={6}>
              <div className="homepage-actions">
                <a className="btn btn-outline-primary" href="tel:5123433100">
                  Call 512-343-3100
                </a>
                <Button
                  variant="primary"
                  onClick={() => showModal('helpModal')}
                >
                  I Need Help
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className="box-homepage concept">
                {(userCompanies || []).length === 1 &&
                  <div>
                    <div className="box-header">
                      <h3>{(userCompanies[0] || {}).companyName}</h3>
                    </div>
                    <h2>{(userCompanies[0] || {}).companyName}</h2>
                    <div className="box-body">
                      <img
                        src={(userCompanies[0] || {}).logoHref}
                        name={(userCompanies[0] || {}).companyName}
                      />
                    </div>
                  </div>}
                {(userCompanies || []).length > 1 &&
                  <Carousel
                    interval={null}
                    onSelect={handleSelect}
                    activeIndex={activeCompanyIndex}
                  >
                    {(userCompanies || []).map(({
                      companyName,
                      logoHref
                    }, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div>
                            <div className="box-header">
                              <h3>{companyName}</h3>
                            </div>
                            <div className="box-body">
                              <img
                                src={logoHref}
                                name={companyName}
                                alt={companyName}
                                style={{ maxHeight: '150px' }}
                              />
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>}
                {checkWebAccess(websiteOptions, 1) &&
                  <Link to={`/shop`} className="box-footer-link">
                    View Shop To Browse Products
                  </Link>}
              </div>
            </Col>
            {checkWebAccess(websiteOptions, 5) && //locations id
              <Col lg={8}>
                <div className="box-homepage location">
                  <div className="box-header">
                    <h3>
                      {(companyLocations || []).length > 1 &&
                        <span>
                          {(companyLocations || []).length} Locations
                        </span>}
                      {(companyLocations || []).length === 1 &&
                        <span>{(companyLocations || []).length} Location</span>}
                      {(companyLocations || []).length < 1 &&
                        <span>
                          {(companyLocations || []).length} Locations
                        </span>}
                    </h3>
                    {(companyLocations || []).length > 1 &&
                      <Link to="/locations">View All Locations</Link>}
                    {(companyLocations || []).length === 1 &&
                      <span className="address">
                        {(companyLocations[0] || {}).address1 || ''}
                        {' '}
                        {(companyLocations[0] || {}).address2 || ''}
                        ,
                        {' '}
                        {(companyLocations[0] || {}).city || '-'}
                        ,
                        {' '}
                        {(companyLocations[0] || {}).stateProvince || '-'}
                      </span>}
                  </div>
                  <div className="box-body">
                    <HomepageMap geocodes={companyLocations} />
                  </div>
                </div>
              </Col>}
            {/* </Row> */}

            {/* <Row> */}
            {checkWebAccess(websiteOptions, 2) && //quotes id
              <Col lg={4}>
                <div className="box-homepage quotes">
                  <div className="box-header">
                    <h3>
                      {result.quotesTotal > 1 &&
                        <span>{result.quotesTotal} Quotes</span>}
                      {result.quotesTotal === 1 &&
                        <span>{result.quotesTotal} Quote</span>}
                      {result.quotesTotal === 0 && <span>Quotes</span>}
                    </h3>
                    {result.quotesTotal > 0 &&
                      <Button
                        variant="primary"
                        onClick={() => showModal('newQuoteModal')}
                      >
                        Request new
                      </Button>}
                  </div>
                  {result.quotesTotal > 0 &&
                    <div className="box-body">
                      <table>
                        <tbody>
                          {(result.quotes || []).map((q, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Link to={`/quotes/${q.proposalNo}`}>
                                    {q.name}
                                  </Link>
                                </td>
                                <td><QuoteStatus status={q.status} /></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>}
                  {result.quotesTotal === 0 &&
                    <div className="box-body empty">
                      <h5>Let's start the conversation</h5>
                      <p>
                        Request a quote and we will have one of our experts contact you. We would love the opportunity to show you how Concept Services can help your business.
                      </p>
                      <Button
                        variant="primary"
                        onClick={() => showModal('newQuoteModal')}
                      >
                        Request a new quote
                      </Button>
                    </div>}
                  <Link to="/quotes" className="box-footer-link">
                    View All Quotes
                  </Link>
                </div>
              </Col>}
            {checkWebAccess(websiteOptions, 5) && //locations id
              <Col lg={4}>
                <div className="box-homepage tracker">
                  <div className="box-header">
                    <h3>Project Tracker</h3>
                  </div>
                  {!!(companyLocations || []).length &&
                    <div className="box-body">
                      <SelectLocationsForm
                        enableReinitialize
                        companyLocations={companyLocations}
                        initialValues={{ changeLocation: currentLocation }}
                        handleLocationChange={handleLocationChange}
                      />
                      {!!(statuses || []).length &&
                        progressDisplay &&
                        <div>
                          <div className="tracker-info">
                            <b>{(completedStatuses || []).length}</b>
                            {' '}
                            of
                            {' '}
                            <b>{(statuses || []).length}</b>
                            {' '}
                            Steps Completed
                          </div>
                          <ProgressBar now={percentComplete} />
                        </div>}
                      {(!(statuses || []).length || !progressDisplay) &&
                        <div
                          className="tracker-info"
                          style={{ justifyContent: 'center' }}
                        >
                          Progress display unavailable <br /><br />
                        </div>}
                      <Row>
                        <Col sm={4}>
                          <div className="address">Address</div>
                        </Col>
                        <Col>
                          {address1}{' '}{address2}<br />
                          {city}, {stateProvince}{' '}{postalCode}
                        </Col>
                      </Row>
                    </div>}
                  {!(companyLocations || []).length &&
                    <div className="box-body">
                      <div className="empty">
                        No locations available for this concept.
                      </div>
                    </div>}
                  <Link
                    to={
                      (companyLocations || []).length
                        ? `/locations/${currentLocation}?tab=project`
                        : ''
                    }
                    className="box-footer-link"
                    style={{
                      color: !!(companyLocations || []).length
                        ? '#c5a44d'
                        : '#bebebe'
                    }}
                  >
                    View Project Tracker
                  </Link>
                </div>
              </Col>}
            {checkWebAccess(websiteOptions, 1) && //shop id
              <Col lg={4}>
                <div className="box-homepage cart">
                  <div className="box-header">
                    <h3>In Your Cart</h3>
                    {(shoppingCartItems || []).length > 0 &&
                      <Link className="btn btn-primary" to="/checkout">
                        Check out
                      </Link>}
                  </div>
                  {(shoppingCartItems || []).length === 0 &&
                    <div className="box-body">
                      <div className="empty">Your shopping cart is empty</div>
                    </div>}
                  {(shoppingCartItems || []).length > 0 &&
                    <div className="box-body">
                      <p>
                        <strong>{(shoppingCartItems || []).length}</strong>
                        {' '}
                        Items in your shopping cart
                      </p>
                      <Carousel interval={null}>
                        {(shoppingCartItems || []).map(({
                          sellPrice,
                          category,
                          model,
                          imageHref,
                          qty
                        }, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <div className="product">
                                <div
                                  className="image"
                                  style={{
                                    backgroundImage: `url(${imageHref})`
                                  }}
                                >
                                  <span className="qty">{qty}</span>
                                </div>
                                <div className="detail">
                                  <strong>{model}</strong>
                                  {category &&
                                    <div className="category">
                                      {category.categoryName}
                                    </div>}
                                  <div className="price">
                                    ${numeral(sellPrice).format('0,0.00')}
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>}
                  <a
                    // href
                    onClick={() => showModal('ShoppingCartModal')}
                    className="box-footer-link"
                  >
                    View Shopping Cart
                  </a>
                </div>
              </Col>}
            {checkWebAccess(websiteOptions, 1) &&
              (featuredPackages || []).length > 0 &&
              <Col lg={4}>
                <div className="box-homepage packages">
                  <div className="box-header">
                    <h3>Featured Packages</h3>
                  </div>
                  <div className="box-body">
                    {(featuredPackages || []).length === 0 &&
                      <div className="empty">
                        No featured packages available for this concept.
                      </div>}
                    {(featuredPackages || []).length > 0 &&
                      <Carousel interval={null}>
                        {(featuredPackages || []).map(({
                          name,
                          id,
                          description,
                          numProducts,
                          totalSell,
                          hidePrice
                        }, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <div className="package">
                                <Link to={`/shop/packages?packageId=${id}`}>
                                  {name || '-'}
                                </Link>
                                <span className="products">
                                  {numProducts || '-'} Products
                                </span>
                                <p>{description}</p>
                                {!hidePrice &&
                                  <div className="price">
                                    <sup>$</sup>
                                    {numeral(totalSell).format('0,0.00')}
                                  </div>}
                              </div>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>}
                  </div>
                  <Link to={`/shop`} className="box-footer-link">
                    View Packages
                  </Link>
                </div>
              </Col>}
            {checkWebAccess(websiteOptions, 3) &&
              documentsResult &&
              documentsResult.length > 0 &&
              !documentsError &&
              <Col lg={4}>
                <div className="box-homepage documents">
                  <div className="box-header">
                    <h3>
                      <span>
                        {
                          `${documentsResult.length} ${documentsResult.length > 1 ? ' Documents' : ' Documents'}`
                        }
                      </span>
                    </h3>
                  </div>
                  <div className="box-body">
                    <table>
                      <tbody>
                        {(documentsResult || []).map((d, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <a
                                  href={authenticatedHref(`documents/${d._id}`)}
                                  target="_blank"
                                  download
                                >
                                  {d.filename}
                                </a>
                              </td>
                              <td className="text-right">
                                <a
                                  href={authenticatedHref(`documents/${d._id}`)}
                                  download
                                >
                                  <Icon name="download" />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Link to="/documents" className="box-footer-link">
                    View All Documents
                  </Link>
                </div>
              </Col>}
            {checkWebAccess(websiteOptions, 4) && //orders
              ordersResult &&
              ordersResult.length > 0 &&
              !getOrdersError &&
              <Col lg={4}>
                <div className="box-homepage orders">
                  <div className="box-header">
                    <h3>
                      <span>
                        {
                          `${ordersResult.length} ${ordersResult.length > 1 ? ' Orders' : ' Orders'}`
                        }
                      </span>
                    </h3>
                  </div>
                  <div className="box-body">
                    <table>
                      <tbody>
                        {(ordersResult || []).splice(0, 4).map((o, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link to={`/orders/${o.projectId}`}>
                                  {o.JobRef}
                                </Link>
                              </td>
                              <td className="text-right">
                                {formatTime(o.orderDate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Link to="/orders" className="box-footer-link">
                    View All Orders
                  </Link>
                </div>

              </Col>}
          </Row>

          <NewQuoteModal />
          <HelpModal />

        </main>
        {getUserDetailsError &&
          <Notification
            key="fail-load-user-details"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetUserDetails}
          >
            Failed to load User Details. Please refresh the page.
          </Notification>}
        {getLocationByIdError &&
          <Notification
            key="fail-load-concept-location"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetLocationById}
          >
            Failed to load concept location. Please refresh the page.
          </Notification>}
        {/* {getShopHomeError &&
          <Notification
            key="fail-load-packages"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetShopHome}
          >
            Failed to load concept packages. Please refresh the page.
          </Notification>} */}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error: getUserDetailsError
  } = state.validateLogin.toJS();

  const {
    result: getLocationByIdResult,
    error: getLocationByIdError,
    inProgress: getLocationByIdInProgress
  } = state.getLocationById.toJS();

  const {
    result: getShopHomeResult,
    error: getShopHomeError
  } = state.getShopHome.toJS();

  const {
    result: ordersResult,
    inProgress: ordersInProgress,
    error: getOrdersError
  } = state.getAllOrders.toJS();

  const {
    result: getCartResult,
    inProgress: getCartInProgress,
    error: getCartError
  } = state.getCart.toJS();

  const {
    result: documentsResult,
    error: documentsError
  } = state.getDocuments.toJS();

  return {
    user,
    getUserDetailsError,
    getLocationByIdResult,
    getLocationByIdError,
    getLocationByIdInProgress,
    getShopHomeResult,
    getShopHomeError,
    getCartResult,
    ordersResult,
    ordersInProgress,
    getOrdersError,
    documentsResult,
    documentsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
      getLocationById: fetchDux.getLocationById.createAction,
      clearGetLocationById: fetchDux.getLocationById.clearAction,
      getShopHome: fetchDux.getShopHome.createAction,
      clearGetShopHome: fetchDux.getShopHome.clearAction,
      getCart: fetchDux.getCart.createAction,
      getAllOrders: fetchDux.getAllOrders.createAction,
      getDocuments: fetchDux.getDocuments.createAction,
      downloadDocuments: fetchDux.downloadDocuments.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Homepage);
