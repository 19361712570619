import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';

import HelpModalForm from './HelpModalForm';
import fetchDux from '../../../state/fetch-dux';

const HelpModal = ({
  openModal,
  closeModal,
  postHelp,
  clearPostHelp,
  result,
  quoteNumber
}) => {
  const handleSubmit = values => {
    const payload = quoteNumber ? {
      ...values,
      quoteNumber
    } : values
    postHelp({ payload }, 'help-form', null, () => {
      closeModal();
    })
  }
  const handleCloseModal = () => {
    clearPostHelp();
    closeModal();
  }
  return (
    <Modal
      show={openModal === 'helpModal'}
      onHide={handleCloseModal}
      className="modal-address"
    >
      <Modal.Header closeButton>
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <HelpModalForm
        result={result}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
}

const mapState = state => {
  const {
    result
  } = state.postHelp.toJS()
  return {
    result,
    openModal: state.modals
  }
};
const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  postHelp: fetchDux.postHelp.createAction,
  clearPostHelp: fetchDux.postHelp.clearAction

}, dispatch);

export default connect(mapState, mapDispatch)(HelpModal);
