import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import numeral from 'numeral';
import moment from "moment";
import paymentOptionDict from '../../checkout/paymentOptionDict';
import getConvenienceFee from '../../checkout/feeDict';


const CardOrder = (
  {
    JobRef,
    projectId,
    orderDate,
    shippingStatus,
    tracking,
    saleAmount,
    PaymentOption,
    CardNumber,
    PurchaseOrderNumber,
    PurchaseOrderDate,
    PurchaseOrderApprovedBy,
    BusinessNameOnCheck,
    CheckNumber,
    BankName,
    NameOnAccount,
    Status,
    Address1,
    Address2,
    City,
    State,
    Zip
  }
) => {
  const convenienceFee = PaymentOption === 1 ? getConvenienceFee(saleAmount) : 0
  return (
    <div className="box-order-card">
      <div className="header">
        <Row>
          <Col>
            <h5>Order Date</h5>
            {moment(orderDate).format('ddd MMM DD, YYYY')}
          </Col>
          <Col>
            <h5>Total</h5>
            ${numeral(saleAmount + convenienceFee).format('0,0.00')}
          </Col>
          <Col>
            <h5>Shipping To</h5>
            {`${Address1}, ${Address2}`}<br></br>
            {`${City} ${State} ${Zip}`}

          </Col>
          <Col className="text-right">
            <div className="order-status">{Status}</div>
          </Col>
        </Row>
      </div>
      <div className="body">
        <Row>
          <Col>
            <h5>Payment Info</h5>
            {paymentOptionDict[PaymentOption]}<br />
            {
              PaymentOption === 1 &&
              (
                <>
                  **** **** **** {CardNumber}
                </>
              )
            }
            {
              PaymentOption === 2 &&
              (
                <>

                  Po No.: {PurchaseOrderNumber}<br />
                  Po Date: {moment(PurchaseOrderDate).format('ddd MMM DD, YYYY')}<br />
                  Po Approver: {PurchaseOrderApprovedBy}
                </>
              )
            }
            {
              PaymentOption === 3 &&
              (
                <>
                  Name: {BusinessNameOnCheck}<br />
                  Check Number: {CheckNumber}
                </>
              )
            }
            {
              PaymentOption === 4 &&
              (
                <>
                  Bank Name: {BankName}<br />
                  Account Name: {NameOnAccount}
                </>
              )
            }
          </Col>
          {/* <Col>
            <h5>Shipping Status</h5>
            {shippingStatus}
          </Col> */}
          {/* <Col>
            <h5>Tracking</h5>
            {tracking}
          </Col> */}
          <Col className="text-right">
            <div className="actions">
              <Link to={`/orders/${projectId}`} className="btn btn-outline-primary">
                View Order Details
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}


export default CardOrder;
