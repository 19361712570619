import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import SearchOrdersForm from '../orders-search/SearchOrdersForm';
import OrderHelpModal from './OrderHelpModal';
import fetchDux from '../../../../state/fetch-dux';
import Loading from '../../../ui/Loading';
import moment from 'moment';
import paymentOptionDict from '../../checkout/paymentOptionDict';
import Notification from '../../../ui/Notification';
import getConvenienceFee from '../../checkout/feeDict';

class OrdersDetails extends Component {
  componentDidMount() {
    const { match: { params }, getOrderById } = this.props;
    const { id } = params;

    getOrderById({ projectId: id });

  }
  render() {
    const { showModal, result, inProgress, error, postOrderSupport, closeModal, supportRequestInProgress } = this.props;
    const metadata = {
      title: ' | Orders | Concept Services'
    };
    const { message } = error || {};

    const {
      jobRef,
      orderDate,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      Zip,
      Email,
      PaymentOption,
      CardNumber,
      NameOnCard,
      ExpiryMonth,
      ExpiryYear,
      BusinessNameOnCheck,
      CheckNumber,
      BankName,
      NameOnAccount,
      PurchaseOrderNumber,
      PurchaseOrderDate,
      PurchaseOrderApprovedBy,
      ShipViaId,
      shoppingCartItems,
      tax,
      freight,
      subTotal,
      Status,
      projectId,
      emailLogs,
      convenienceFee,
      couponResult
    } = result || {};

    const { Amount = 0, Code } = couponResult || {};

    const total = (subTotal || 0) + (freight || 0) + (tax || 0) - Amount;

    const finalTotal = total + convenienceFee;

    return (
      <Layout route="orders-details" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/orders">Orders</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <span className="active">Order Details</span>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Order Details
              </h2>
            </Col>
            <Col md={5}>
              <SearchOrdersForm />
            </Col>
          </Row>

          {inProgress ?
            <Loading />
            :
            (
              <>
                <div className="box-order-details">
                  <Row>
                    <Col>
                      <h3>Order Details</h3>
                      <div className="details">
                        <Row>
                          <Col>
                            <h5>Order Status</h5>
                          </Col>
                          <Col><div className="order-status">{Status}</div></Col>
                        </Row>
                        <Row>
                          <Col>
                            <h5>Confirmation Sent To</h5>
                          </Col>
                          <Col>
                            {(emailLogs || []).map(({ EmailRecipient }) => (
                              <div>
                                {EmailRecipient}
                              </div>
                            ))}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h5>Order Number</h5>
                          </Col>
                          <Col>
                            {jobRef}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h5>Order Date</h5>
                          </Col>
                          <Col>{moment(orderDate).format('ddd MMM DD, YYYY')}</Col>
                        </Row>
                        <Row>
                          <Col>
                            <h5>Shipping To</h5>
                          </Col>
                          <Col>
                            {FirstName} {LastName}<br />
                            {Address1},{Address2}, {City}, {Zip}
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col>
                            <h5>Shipping Method</h5>
                          </Col>
                          <Col>
                            {ShipViaId}
                          </Col>
                        </Row> */}
                        <Row>
                          <Col>
                            <h5>Payment Method</h5>
                          </Col>
                          <Col>
                            {
                              PaymentOption === 1 &&
                              (
                                <>
                                  {paymentOptionDict[PaymentOption]}<br />
                                  **** **** **** {CardNumber}
                                </>
                              )
                            }
                            {
                              PaymentOption === 3 &&
                              (
                                <>
                                  {paymentOptionDict[PaymentOption]}<br />
                                  Name: {BusinessNameOnCheck}<br />
                                  Check Number: {CheckNumber}
                                </>
                              )

                            }
                            {
                              PaymentOption === 4 &&
                              (
                                <>
                                  {paymentOptionDict[PaymentOption]}<br />
                                  Bank Name: {BankName}<br />
                                  Account Name: {NameOnAccount}
                                </>
                              )
                            }
                            {
                              PaymentOption === 2 &&
                              (
                                <>
                                  {paymentOptionDict[PaymentOption]}<br />
                                  Po No.: {PurchaseOrderNumber}<br />
                                  Po Date: {moment(PurchaseOrderDate).format('ddd MMM DD, YYYY')}<br />
                                  Po Approver: {PurchaseOrderApprovedBy}
                                </>
                              )
                            }
                          </Col>
                        </Row>
                      </div>


                      {PaymentOption === 3 && (
                        <div className="instructions">
                          <h3>Mail Check Instructions</h3>
                          <div>Make checks payable to <b>Concept Services</b>.</div>
                          <div>Please send check via secure and priority mail to:</div>
                          <address>
                            Concept Services<br />
                            12521 Amherst Drive<br />
                            Austin, TX 78727
                          </address>
                          <div>
                            Add this order number: <b>{jobRef}</b> on check memo line
                          </div>
                        </div>
                      )}


                    </Col>
                    <Col>

                      <Button
                        variant="outline-primary"
                        className="btn-help"
                        onClick={() => showModal('OrderHelpModal')}
                      >
                        Request help with this order
                      </Button>
                      <h3>Your Order Summary</h3>

                      <div className="shopping-cart">
                        {(shoppingCartItems || []).map((p, index) => {
                          return (
                            <div key={index} className="product">
                              <div
                                className="image"
                                style={{ backgroundImage: `url(${p.imageHref})` }}
                              >
                                <span className="qty">{p.qty}</span>
                              </div>
                              <div className="detail">
                                <strong>{p.model}</strong>
                                <p>{p.category && p.category.categoryName}</p>
                              </div>
                              <div>
                                ${numeral(p.fullPrice).format('0,0.00')}
                                <span />
                              </div>
                            </div>
                          );
                        })}

                        <table className="table-subtotal">
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>
                                ${numeral(subTotal || 0).format('0,0.00')}
                              </td>
                            </tr>
                            {
                              couponResult &&
                              <tr>
                                <th>{`Discount ( ${Code} )`}</th>
                                <td>
                                  {`-$${numeral(Amount || 0).format('0,0.00')}`}
                                </td>
                              </tr>
                            }
                            <tr>
                              <th>Freight</th>
                              <td>
                                ${numeral(freight || 0).format('0,0.00')}
                              </td>
                            </tr>

                            <tr>
                              <th>Tax</th>
                              <td>${numeral(tax || 0).format('0,0.00')}</td>
                            </tr>

                            {
                              PaymentOption === 1 &&
                              (
                                <tr>
                                  <th>Convenience Fee</th>
                                  <td>
                                    $
                                    {numeral(convenienceFee || 0).format('0,0.00')}
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>Total</th>
                              <td>${numeral(finalTotal < 0 ? 0 : finalTotal).format('0,0.00')}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                    </Col>
                  </Row>
                </div>
                <OrderHelpModal
                  supportRequestInProgress={supportRequestInProgress}
                  handleSubmit={(values) => {
                    postOrderSupport(
                      { projectId: projectId, payload: values },
                      null,
                      null,
                      () => {
                        closeModal('OrderHelpModal')
                      })
                  }} />

                {error && (
                  <Notification
                    key="get-order-fail"
                    duration={3}
                    closable={true}
                    type="danger"
                  >
                    {message ? message : 'Failed to get order details. Please try again'}
                  </Notification>
                )}
              </>
            )
          }
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error
  } = state.getOrderById.toJS();


  const {
    inProgress: supportRequestInProgress
  } = state.postOrderSupport.toJS();


  return {
    result,
    inProgress,
    error,
    supportRequestInProgress
  }
};
const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators,
    getOrderById: fetchDux.getOrderById.createAction,
    postOrderSupport: fetchDux.postOrderSupport.createAction
  }, dispatch);

export default connect(mapState, mapDispatch)(OrdersDetails);
