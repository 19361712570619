import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  //identity
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN',
    successListeners: ['POST_VALIDATE_LOGIN'],
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN']
  }),
  validateToken: duxFactory({
    apiFn: api.validateToken,
    actionPrefix: 'POST_VALIDATE_TOKEN',
  }),
  validateTokenAndSavePassword: duxFactory({
    apiFn: api.validateTokenAndSavePassword,
    actionPrefix: 'VERIFY_SAVE_PASSWORD'
  }),

  //shop
  getShopHome: duxFactory({
    apiFn: api.getShopHome,
    actionPrefix: 'GET_SHOP_Home',
  }),
  getShopCategory: duxFactory({
    apiFn: api.getShopCategory,
    actionPrefix: 'GET_SHOP_CATEGORY',
  }),
  getProductById: duxFactory({
    apiFn: api.getProductById,
    actionPrefix: 'GET_SHOP_PRODUCT_BY_ID',
  }),
  getPackageById: duxFactory({
    apiFn: api.getPackageById,
    actionPrefix: 'GET_SHOP_PACKAGE_BY_ID',
  }),
  getCompanyProducts: duxFactory({
    apiFn: api.getCompanyProducts,
    actionPrefix: 'GET_COMPANY_PRODUCTS',
  }),

  //locations
  getLocations: duxFactory({
    apiFn: api.getLocations,
    actionPrefix: 'GET_LOCATIONS',
  }),
  getLocationById: duxFactory({
    apiFn: api.getLocationById,
    actionPrefix: 'GET_LOCATION_BY_ID',
  }),
  getLocationsByCompany: duxFactory({
    apiFn: api.getLocationsByCompany,
    actionPrefix: 'GET_LOCATIONS_BY_COMPANY',
  }),

  //user
  getUserDetails: duxFactory({
    apiFn: api.getUserDetails,
    actionPrefix: 'GET_USER_DETAILS',
  }),

  //cart
  getCart: duxFactory({
    apiFn: api.getCart,
    actionPrefix: 'GET_CART',
    successListeners: [
      'POST_ADD_TO_CART',
      'POST_ADD_PACKAGE_TO_CART',
      'DELETE_CART_ITEM',
      'APPLY_CART_COUPON',
      'REMOVE_CART_COUPON'
    ]
  }),
  postCart: duxFactory({
    apiFn: api.postCart,
    actionPrefix: 'POST_ADD_TO_CART'
  }),
  postPackage: duxFactory({
    apiFn: api.postPackage,
    actionPrefix: 'POST_ADD_PACKAGE_TO_CART'
  }),
  deleteCartItem: duxFactory({
    apiFn: api.deleteCartItem,
    actionPrefix: 'DELETE_CART_ITEM'
  }),

  saveShippingAddress: duxFactory({
    apiFn: api.saveShippingAddress,
    actionPrefix: "SAVE_SHIPPING_ADDRESS"
  }),

  saveBillingAddress: duxFactory({
    apiFn: api.saveBillingAddress,
    actionPrefix: "SAVE_BILLING_ADDRESS"
  }),
  postCartPayment: duxFactory({
    apiFn: api.postCartPayment,
    actionPrefix: 'POST_CART_PAYMENT'
  }),
  submitCartApproval: duxFactory({
    apiFn: api.submitCartApproval,
    actionPrefix: "SUBMIT_CART_APPROVAL"
  }),
  getApprovalCart: duxFactory({
    apiFn: api.getApprovalCart,
    actionPrefix: 'GET_APPROVAL_CART'
  }),
  getDocuments: duxFactory({
    apiFn: api.getDocuments,
    actionPrefix: 'GET_DOCUMENTS'
  }),
  downloadDocuments: duxFactory({
    apiFn: api.downloadDocuments,
    actionPrefix: 'DOWNLOAD_DOCUMENTS'
  }),
  getQuotes: duxFactory({
    apiFn: api.getQuotes,
    actionPrefix: 'GET_QUOTES'
  }),
  getQuoteById: duxFactory({
    apiFn: api.getQuoteById,
    actionPrefix: "GET_QUOTE_BY_ID",
    successListeners: [
      'REQUEST_QUOTE_REVISION',
      'ACCEPT_QUOTE_BY_ID',
      'UPDATE_QUOTE_LINEITEM'
    ]
  }),
  getOrderById: duxFactory({
    apiFn: api.getOrderById,
    actionPrefix: 'GET_ORDER_BY_ID'
  }),
  downloadQuoteById: duxFactory({
    apiFn: api.downloadQuoteById,
    actionPrefix: 'DOWNLOAD_QUOTE_BY_ID'
  }),
  acceptQuoteById: duxFactory({
    apiFn: api.acceptQuoteById,
    actionPrefix: 'ACCEPT_QUOTE_BY_ID'
  }),
  getAllOrders: duxFactory({
    apiFn: api.getAllOrders,
    actionPrefix: 'GET_ALL_ORDERS'
  }),

  postOrderSupport: duxFactory({
    apiFn: api.postOrderSupport,
    actionPrefix: 'POST_ORDER_SUPPORT'
  }),
  getShareCartToken: duxFactory({
    apiFn: api.getShareCartToken,
    actionPrefix: 'GET_SHARE_CART_TOKEN'
  }),
  postHelp: duxFactory({
    apiFn: api.postHelp,
    actionPrefix: 'POST_HELP'
  }),
  requestQuoteRevision: duxFactory({
    apiFn: api.requestQuoteRevision,
    actionPrefix: 'REQUEST_QUOTE_REVISION'
  }),

  getWarehouseTokenData: duxFactory({
    apiFn: api.getWarehouseTokenData,
    actionPrefix: 'GET_WAREHOUSE_TOKEN_DATA'
  }),

  getWarehouseById: duxFactory({
    apiFn: api.getWarehouseById,
    actionPrefix: 'GET_WAREHOUSE_BY_ID'
  }),

  updateWarehouseById: duxFactory({
    apiFn: api.updateWarehouseById,
    actionPrefix: 'UPDATE_WAREHOUSE_BY_ID'
  }),

  applyCartCoupon: duxFactory({
    apiFn: api.applyCartCoupon,
    actionPrefix: 'APPLY_CART_COUPON'
  }),

  removeCoupon: duxFactory({
    apiFn: api.removeCoupon,
    actionPrefix: 'REMOVE_CART_COUPON'
  }),

  updateQuoteStatus: duxFactory({
    apiFn: api.updateQuoteStatus,
    actionPrefix: 'UPDATE_QUOTE_STATUS'
  }),

  getCompanyUsers: duxFactory({
    apiFn: api.getCompanyUsers,
    actionPrefix: 'GET_COMPANY_USERS'
  }),

  shareCart: duxFactory({
    apiFn: api.shareCart,
    actionPrefix: 'SHARE_CART'
  }),

  updateQuoteLineItem: duxFactory({
    apiFn: api.updateQuoteLineItem,
    actionPrefix: 'UPDATE_QUOTE_LINEITEM'
  })
};