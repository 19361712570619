import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import Layout from '../../ui/Layout';
import Loading from '../../ui/Loading';
import WarehouseForm from './WarehouseForm';
import moment from 'moment';
import ModalUpdateConfirmation from './ModalUpdateConfirmation';
import Notification from '../../ui/Notification';
import queryString from 'query-string';

class WarehouseUpdate extends Component {

    componentDidMount() {
        const { match, getWarehouseTokenData, getWarehouseById } = this.props;
        const { token } = match.params || {};
        getWarehouseTokenData(token, null, null, (result) => {
            const { IsActive, ExpirationDate, RedeemedDate, WarehouseId } = result;
            if (IsActive && !RedeemedDate && !(moment().isAfter(moment(ExpirationDate)))) {
                getWarehouseById({ token, id: WarehouseId })
            }
        });

    }

    render() {
        const metadata = {
            title: 'Warehouses | Concept Services'
        };

        const {
            inProgress,
            warehouseResult,
            warehouseUpdateError,
            warehouseTokenError,
            warehouseError,
            match,
            warehouseTokenResult,
            showModal,
            warehouseUpdateResult,
            location: { search }
        } = this.props;

        const query = queryString.parse(search);
        const { newProject, jobCode, salesId } = query || {};

        const { token } = match.params || {};
        const { id } = warehouseResult || {};
        const { IsActive, ExpirationDate, RedeemedDate } = warehouseTokenResult || {};

        const getInitialValues = () => {
            const { userContacts, ...rest } = warehouseResult || {};
            return {
                userContacts: (userContacts || []).length ? userContacts : [{}],
                ...rest
            }
        }

        if (warehouseUpdateResult) {
            return (
                <Layout
                    route="warehouse-update"
                    metadata={metadata}
                    fluid
                    hideMenuOptions
                >
                    <div className='layout-body'>
                        <h2 style={{ textTransform: 'none' }}>
                            {newProject ? 'Warehouse project and rates are confirmed!' : 'Warehouse details updated successfully'}
                        </h2>
                        {newProject && 
                          <p>
                            You should receive an email momentarily confirming your acknowledgment of the project terms.
                            <br/>
                            <br/>
                            Thank you.
                          </p>
                        }
                    </div>
                </Layout>
            )
        }

        if (!inProgress) {
            if (!IsActive || (moment().isAfter(moment(ExpirationDate)))) {
                return (
                    <Layout
                        route="warehouse-update"
                        metadata={metadata}
                        fluid
                        hideMenuOptions
                    >
                        <div className='layout-body'>
                            <h2 style={{ textTransform: 'none' }}>
                                This link is no longer active, please contact Concept Services.
                            </h2>
                        </div>
                    </Layout>
                )
            }
            if (RedeemedDate) {
                return (
                    <Layout
                        route="warehouse-update"
                        metadata={metadata}
                        fluid
                        hideMenuOptions
                    >
                        <div className='layout-body'>
                            <h2 style={{ textTransform: 'none' }}>
                                A user has already confirmed the information with this link.
                            </h2>
                        </div>
                    </Layout>
                )
            }
        }

        return (
            <Layout
                route="warehouse-update"
                metadata={metadata}
                fluid
                hideMenuOptions
            >
                {
                    warehouseError &&
                    <Notification
                        key="warehouse"
                        duration={3}
                        closable={true}
                        type="danger"
                    >
                        {warehouseError.message ?
                            warehouseError.message :
                            'Failed to get warehouse details. Please try again'
                        }
                    </Notification>
                }

                {
                    warehouseTokenError &&
                    <Notification
                        key="warehouse"
                        duration={3}
                        closable={true}
                        type="danger"
                    >
                        {warehouseTokenError.message ?
                            warehouseTokenError.message :
                            'Failed to get warehouse token details. Please try again'
                        }
                    </Notification>
                }

                {
                    warehouseUpdateError &&
                    <Notification
                        key="warehouse"
                        duration={3}
                        closable={true}
                        type="danger"
                    >
                        {warehouseUpdateError.message ?
                            warehouseUpdateError.message :
                            'Failed to udated warehouse details. Please try again'
                        }
                    </Notification>
                }
                {
                    inProgress &&
                    <Loading fullPage />
                }
                {
                    !inProgress &&
                    <>
                        <div className='layout-body'>
                            <WarehouseForm
                                initialValues={getInitialValues()}
                                newProject={newProject}
                                onSubmit={
                                    (values) => {
                                        const payload = {
                                            token,
                                            id,
                                            modalName: 'modalUpdateConfirmation',
                                            newProject,
                                            ...values
                                        }
                                        showModal(payload);
                                    }
                                }
                            />
                        </div>

                        <ModalUpdateConfirmation
                            name='modalUpdateConfirmation'
                            jobCode={jobCode}
                            salesId={salesId}
                        />
                    </>

                }
            </Layout>
        )
    }
}

const mapState = state => {
    const {
        inProgress: warehouseTokenInProgress,
        result: warehouseTokenResult,
        error: warehouseTokenError
    } = state.getWarehouseTokenData.toJS();

    const {
        inProgress: warehouseInProgress,
        result: warehouseResult,
        error: warehouseError
    } = state.getWarehouseById.toJS();

    const {
        result: warehouseUpdateResult,
        error: warehouseUpdateError
    } = state.updateWarehouseById.toJS();


    return {
        inProgress: warehouseInProgress || warehouseTokenInProgress,
        warehouseTokenResult,
        warehouseError,
        warehouseTokenError,
        warehouseUpdateError,
        warehouseResult,
        warehouseUpdateResult
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        getWarehouseTokenData: fetchDux.getWarehouseTokenData.createAction,
        getWarehouseById: fetchDux.getWarehouseById.createAction
    }, dispatch)

export default connect(mapState, mapDispatch)(WarehouseUpdate);