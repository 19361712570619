import React, { Component } from 'react';

const labelStyle = {
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px'
};
const underlineStyle = {
  fontWeight: 'bold',
  textDecoration: 'underline'
};
const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
  marginTop: '1rem'
};

class WarehouseTOS extends Component {
  render() {
    const { readOnly, jobCode } = this.props;
    return (
      <div>
        <h3 style={headerStyle}>
          Warehouse Scope of Work
          {(!readOnly || !!jobCode) &&
            <span> – <span className="text-danger">{jobCode}</span></span>}
        </h3>
        <p>
          This scope of work document is to establish a clear understanding of the requirements of Concept Services and the responsibilities of the warehouse.
        </p>
        <div style={labelStyle}>Receipt of Equipment</div>
        <ol>
          <li>
            <b style={underlineStyle}>Receiving Freight</b>
            {' '}
            - Please Review
            {' '}
            <b>Warehouse Receiving Procedures</b>
            {' '}
            on the last page of this document.
          </li>
          <li>
            <b style={underlineStyle}>Receiving Report</b>
            {' '}
            - It is the responsibility of the warehouse to provide Concept Services with accurate, legible receiving reports notating all applicable information including, but not limited to, any damage, shorts, and over-ship issues. The warehouse must email the Concept Services receiving report provided by the project manager or an agreed upon receiving report by COB the following day.
          </li>
        </ol>
        <div style={labelStyle}>Storage Requirements</div>
        <ol>
          <li>
            The warehouse will provide a well-maintained secure facility for the storage of received shipments.
          </li>
          <li>
            Shipments will be consolidated and stored in a general area, free from water or chemical leaks and excess heat.
          </li>
        </ol>
        <div style={labelStyle}>
          Pre-Assembly <span className="text-danger">(If applicable)</span>
        </div>
        <ol>
          <li>
            <p>
              Prior to delivery, a Concept Services representative will arrive at your warehouse (with labor) to begin pre-assembly. Our representative will un-crate, inspect, and assemble some of the stored items. This can take place any time from a few days prior to delivery to the day of delivery.
            </p>
            <ul>
              <li>
                A. It is the responsibility of Concept Services to inform the warehouse of the pre-assembly date.
              </li>
              <li>
                B. The warehouse is responsible for providing our representative with a covered area with ample room to unpack and assemble. The warehouse is responsible for moving the shipments to the designated area.
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              Our representative will have all necessary labor with him. It will not be necessary for the warehouse to supply him with any additional labor to complete his scope.
            </p>
            <ul>
              <li>
                A. The warehouse must advise if an access fee applies for labor necessary to move freight to staging area.
              </li>
            </ul>
          </li>
        </ol>
        <div style={labelStyle}>Delivery to Site</div>
        <ol>
          <li>
            <p>
              It is the responsibility of the warehouse to load and properly protect all equipment due for delivery. The loaded equipment must be blanket wrapped and secured firmly inside the trailers. The warehouse is responsible for the repair or replacement of equipment damaged in the warehouse or during transport to site.
            </p>
            <ul>
              <li>
                A. The warehouse will be responsible for supplying a liftgate truck if possible or adequate walk-boards for unloading.
              </li>
              <li>
                B. It is the responsibility of the warehouse to immediately contact Concept Services if delay becomes apparent. The warehouse will be responsible for all charges incurred because of delayed delivery, provided the delay is caused by the warehouse.  Cause is considered to be any event that is in control of the warehouse, as well as, adherence to this scope. Delivery vehicle breakdown is in the control of the warehouse
              </li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              At the time of delivery it is the responsibility of the warehouse to provide the onsite representative with an accurate Delivery Ticket (provided by Concept Services). All units not delivered (left in warehouse or returned from site), must be noted on (or removed from) the Delivery Ticket prior to sign off.
            </p>
          </li>
          <li>
            <p>
              Unless requested by Concept Services, all deliveries are to be tailgate deliveries. Using only
              {' '}
              <b>1 driver per truck with no extra labor</b>
              {' '}
              provided by the warehouse. If additional labor is deemed necessary it must be approved prior to delivery
              {' '}
              <b><i>in writing</i></b>
              . This includes additional labor requested by the installers.
            </p>
            <p className="text-danger">
              NOTE: It is very important that no extra services be approved by anyone other than a Concept Services employee. ANY additional services or change in services will be requested/approved by Concept Services IN WRITING, no exceptions; this includes changes in truck sizes, delivery times, method of billing, and additional labor. Our installers are required to provide the labor that they will need for the pre-install & the delivery.
            </p>
          </li>
        </ol>
        <div style={labelStyle}>Billing</div>
        <ol>
          <li>
            <p>
              It is the responsibility of the warehouse to provide Concept Services with an accurate and legible invoice in a reasonable amount of time (30 days).
            </p>
            <ul className="text-danger">
              <li>
                A. All invoices should be emailed to whsinvoices@conceptserv.com
              </li>
              <li>
                B. All invoice emails should have the restaurant name and job # in the subject field
              </li>
              <li>
                C. All invoices are REQUIRED to have the address of the delivery with zip code
              </li>
              <li>D. All invoices are REQUIRED to have a PO#</li>
            </ul>
            <br />
          </li>
          <li>
            <p>
              Please be advised that invoices sent through USPS cannot be guaranteed to be paid on time nor will Concept Services accept late fees on any invoices sent USPS. Please include any and all backup paperwork to assist in expediting your payment.
            </p>
          </li>
        </ol>
        <br />
        <h3 style={headerStyle}>Warehouse Receiving Procedures</h3>
        <div style={labelStyle} />
        <ul>
          <li>
            Warehouse must carefully compare the number of pieces received to the number of pieces on the freight delivery receipt
          </li>
          <li>
            Warehouse must carefully examine all shipments received for any evidence of damage, to include damaged pallets, tears in plastic wrap, creases in cardboard, or any other visible damage that could indicate internal damage.
          </li>
          <li>
            <p>
              If damage to the freight is visible in any way the following steps must be taken
              {' '}
              <b>prior to the driver leaving and before signing</b>
              .
            </p>
            <ul>
              <li>
                Warehouse must note damage on the freight company’s delivery receipt and a copy should be made if one is not provided for our records.
              </li>
              <li>
                Warehouse must write specific details on the freight receipt such as, 3 pcs received, 1 box smashed, 1 box torn, 1 box with visible water damage.
              </li>
              <li>
                <p>
                  If damage is visible prior to the freight being off loaded the warehouse must take pictures of the freight while on the truck.
                </p>
                <p className="text-danger text-center">
                  <b>
                    **Do NOT be intimidated by the driver. The driver cannot leave until the freight receipt is signed for by the warehouse.**
                  </b>
                </p>
              </li>
            </ul>
          </li>
          <li>
            After the damage has been signed for the warehouse must take photos from all sides and forward the photos along with a copy of the freight bill, and any other relevant paperwork to Concept Services.  Do NOT open without approval from Concept Services.
          </li>
          <li>
            Please call immediately to discuss damage with the project manager before you sign, 512-343-3100.
          </li>
        </ul>
        <p>
          As the receiving agent for Concept Services, your signature on a freight receipt constitutes acceptance of the merchandise “as-is” and in good order.  If the warehouse does not inspect the freight before signing the warehouse, for all practical purposes, is waiving “our” right to collect on a damage claim and will now be held financially responsible for any damage up to full replacement value.
        </p>
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Remember once the warehouse signs the freight receipt the responsibility for the merchandise belongs to the warehouse!
        </p>
        {!readOnly &&
          <p style={{ fontWeight: 'bold' }}>
            I have read and understand the above scope of work, and accept rates posted in the attached PO.
          </p>}
      </div>
    );
  }
}

export default WarehouseTOS;
