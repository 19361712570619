import React, { useState } from 'react';
import { Button, Col, Row, Modal } from 'react-bootstrap';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import Checkbox from '../../ui/Toggle'

import WarehouseTOS from '../warehouse-agreement/WarehouseTOS';

const ModalUpdateConfirmation = (
    {
        name,
        openModal,
        updateWarehouseById,
        closeModal,
        inProgress,
        jobCode,
        salesId
    }
) => {
    const {
        modalName,
        newProject,
        ...rest
    } = openModal || {};
    const [disabled, setDisabled] = useState(true);
    const [ackedTerms, setAckedTerms] = useState(false);

    const scrollHandler = (e) => {
        const target = e.target;
        if (Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1) {
            setDisabled(false);
        }
    }

    const closeHandler = () => {
        if (!inProgress) closeModal();
    }
    return (
        <Modal
            show={modalName === name}
            className={newProject && newProject === 'true' ? "modal-address largeWide" : "modal-address medium"}
            onHide={closeHandler}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        newProject && newProject === 'true' && 'Read and accept project terms'
                    }
                    {
                        !newProject && 'Rates Confirmation'
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    newProject && newProject === 'true' &&
                    <div
                        style={{
                            overflow: 'scroll',
                            overflowX: 'hidden',
                            height: '400px',
                            padding: '2rem'
                        }}
                        onScroll={scrollHandler}
                    >
                        <WarehouseTOS readOnly jobCode={jobCode}></WarehouseTOS>
                    </div>
                }
                {
                    !newProject &&
                    <p>
                        Are you sure you want to Submit rate information?
                    </p>
                }

            </Modal.Body>

            <Modal.Footer>
                <form style={{ width: '100%' }}>
                    <div className='footerContainer'>
                        <Col sm={12}>
                            {newProject &&
                                <Row style={{ justifyContent: 'end' }}>
                                    <Col sm={8} style={{ textAlign: 'end' }}>
                                        <Field
                                            disabled={(disabled && !!newProject) || inProgress}
                                            name="ackedTerms"
                                            type="checkbox"
                                            component={FormField.InlineCheckbox}
                                            label="I have read and accept project terms"
                                            onClick={() => {
                                                setAckedTerms(!ackedTerms)
                                            }
                                            }
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row style={{ justifyContent: 'space-between' }}>
                                <Col sm={3}>
                                    <Button
                                        variant="secondary"
                                        onClick={
                                            () => closeHandler()
                                        }
                                        disabled={inProgress}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col sm={5}>
                                    <Button
                                        disabled={(!ackedTerms && !!newProject) || inProgress}
                                        onClick={
                                            () => {
                                                updateWarehouseById({ ...rest, new: true, jobCode, salesId }, null, null, () => {
                                                    closeHandler();
                                                })
                                            }
                                        }
                                    >
                                        {newProject ? 'Accept Project Terms' : 'Confirm Rates'}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </form>
            </Modal.Footer>
        </Modal >
    )
}

const form = reduxForm({
    form: 'update-warehouse-rates'
})(ModalUpdateConfirmation);

const mapState = state => {

    const {
        inProgress
    } = state.updateWarehouseById.toJS();
    return {
        openModal: state.modals,
        inProgress
    }
}

const mapDispatch = dispatch => bindActionCreators({
    ...actionCreators,
    updateWarehouseById: fetchDux.updateWarehouseById.createAction
}, dispatch)



export default connect(mapState, mapDispatch)(form);