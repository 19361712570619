import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { Row, Col, Alert, Container } from 'react-bootstrap';

import Layout from '../../ui/Layout';
import Notification from '../../ui/Notification';
import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends Component {
  componentWillMount() {
    //for Token Validation while rendering page
    var token = this.props.location.pathname.split('/')[2]
    this.props.validateToken(token);
  }
  render() {
    const {
      user,
      error,
      validateTokenAndSavePassword,
      clearValidateTokenAndSavePassword,
      savePasswordError,
      history
    } = this.props;

    const {
      id,
      tokenId
    } = user || {};

    const postResetPassword = values => {
      values.id = id
      values.tokenid = tokenId
      // Api Call for Validating token and saving password after clicking on submit
      validateTokenAndSavePassword({payload:values, userId:id}, 'create-password-form', null, (data)=>{
        const{
          id
        } = data || {};

        if (id !== null && id !== "") {
          history.push('/login');
        }
      });
    }
    const{
      message
    } = savePasswordError || {};

    return (
      <Layout route="reset-password" hideNav>
        <Container>
          <Row className="justify-content-sm-center align-items-center">
            <Col sm md={4}>
              <div className="brand" />
              {error && <Alert variant="danger">{error.message}</Alert>}
              <ResetPasswordForm onSubmit={postResetPassword} />
            </Col>
          </Row>
          {savePasswordError &&
            <Notification
              key="password-reset-fail"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearValidateTokenAndSavePassword}
            >
              {message ? message : 'Failed to create password. Please try again'}
            </Notification>
          }
        </Container>
      </Layout>
    );
  };
}
const mapState = state => {
  const {
    result: user,
    error
  } = state.validateToken.toJS();
  const {
    error:savePasswordError
  } = state.validateTokenAndSavePassword.toJS();
  return {
    user,
    error,
    savePasswordError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateToken: fetchDux.validateToken.createAction,
      validateTokenAndSavePassword: fetchDux.validateTokenAndSavePassword.createAction,
      clearValidateTokenAndSavePassword: fetchDux.validateTokenAndSavePassword.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ResetPassword);
