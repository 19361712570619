import React from 'react';
import Table from '../../../ui/Table';
import TablePagination from '../../../ui/TablePagination';
import Icon from '../../../ui/Icon';
import { downloadDocuments } from '../../../../api/api';
import { authenticatedHref } from '../../../../api/fetch';

const getColumns = (getDownloadFileDetails) => {
  return [
    {
      Header: 'Name',
      accessor: 'filename',
      Cell: ({ value }) => <span>{value}</span>,
      className: 'text-center',
    },
    {
      Header: 'Size',
      accessor: 'length',
      className: 'text-center',
      Cell: ({ value }) => <span>{humanFileSize(value, true)}</span>
    },
    {
      Header: 'Type',
      className: 'text-center',
      accessor: 'metadata.kind',
      Cell: ({ value }) => <span>{value || ''}</span>
    },
    {
      Header: 'Location',
      className: 'text-center',
      accessor: 'location.locationName',
      Cell: ({ value }) => <span>{value || ''}</span>
    },
    {
      Header: 'Concept',
      className: 'text-center',
      accessor: 'company.companyName',
      Cell: ({ value }) => <span>{value || ''}</span>
    },
    {
      Header: '',
      className: 'text-right',
      accessor: '_id',
      Cell: ({ value }) => (
        <a
          href={authenticatedHref(`documents/${value}`)}
          download
        >
          <Icon name="download" /> Download
        </a>
      )
    }
  ]
};

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

const DocumentsSearchResults = (
  {
    error,
    result,
    search
  }
) => {
  const getDownloadFileDetails = (original, e) => {
    e.preventDefault();
    const payload = {
      ...original,
      ...original.metadata
    }
    downloadDocuments({ payload });
  }
  const columns = getColumns(getDownloadFileDetails);
  return (
    <Table
      data={result}
      columns={columns}
      noDataText="No documents found"
      PaginationComponent={TablePagination}
      search={search}
      onSearchChange
    />
  );
};

export default DocumentsSearchResults;
