import 'whatwg-fetch';
import localForage from 'localforage';

import store from '../state/store';

import {
  createFetch,
  createStack,
  accept,
  auth,
  init,
  method,
  body,
  parseJSON,
  params
} from 'http-client';

const apiBase = process.env.REACT_APP_API_BASE;

const getToken = async () => {
  const identity = await localForage.getItem('identity');
  const { userStorage } = identity || {};
  const { token } = userStorage || {};
  return token ? `Bearer ${token}` : null;
};

export const authenticatedHref = href => {
  try {
    const token = localStorage.getItem('token');
    return `${apiBase}/${href}?token=${token || ''}`;
  } catch (err) {
    return `${apiBase}/${href}`;
  }
};

const commonStack = (token) =>
  createStack(
    accept('application/json'),
    auth(token),
    init('credentials', 'include'),
    parseJSON()
  );

const commonStackFile = (token) =>
  createStack(
    auth(token),
    init('credentials', 'same-origin')
  );
const handleJsonFetch = async call => {
  const res = await call;
  if (res.ok === false) {
    const error = new Error(res.jsonData.message || 'An error occurred');
    error.status = res.status;
    error.jsonData = res.jsonData;
    throw error;
  }
  return res.jsonData;
};
const handleFileFetch = async call => {
  const res = await call;
  if (res.ok === false) {
    const error = new Error(res.jsonData.message || 'An error occurred');
    error.status = res.status;
    error.jsonData = res.jsonData;
    throw error;
  }
  return res;
};
export const getJson = async ({ path, payload, authToken }) => {
  const token = authToken || await getToken();
  const fetch = createFetch(commonStack(token), params(payload));
  return handleJsonFetch(fetch(path));
};
export const getFile = async ({ path, payload }) => {
  const token = await getToken();
  const fetch = createFetch(commonStackFile(token), params(payload));
  return handleFileFetch(fetch(path));
};
export const postJson = async ({ path, payload }) => {
  const token = await getToken();
  const fetch = createFetch(
    commonStack(token),
    method('POST'),
    body(JSON.stringify(payload || {}), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const patchJson = async ({ path, payload }) => {
  const token = await getToken();
  const fetch = createFetch(
    commonStack(token),
    method('PATCH'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const del = async ({ path }) => {
  const token = await getToken();
  const fetch = createFetch(commonStack(token), method('DELETE'));
  return handleJsonFetch(fetch(path));
};

export const delJson = async ({ path, payload }) => {
  const token = await getToken();
  const fetch = createFetch(
    commonStack(token),
    method('DELETE'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const putJson = async ({ path, payload, authToken }) => {
  const token = authToken || await getToken();
  const fetch = createFetch(
    commonStack(token),
    method('PUT'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const postFile = async ({ path, payload }) => {
  const token = await getToken();
  const data = new FormData();
  const keys = Object.keys(payload);
  keys.forEach(key => {
    if (Array.isArray(payload[key])) {
      payload[key].map(p => data.append(key, p));
      return;
    }
    data.append(key, payload[key]);
  });

  const fetch = createFetch(commonStack(token), method('POST'), body(data));
  return handleJsonFetch(fetch(path));
};
