export const months = [
    {
        month: "January",
        label: "JAN",
        value: 0
    },
    {
        month: "February",
        label: "FEB",
        value: 1
    },
    {
        month: "March",
        label: "MAR",
        value: 2
    },
    {
        month: "April",
        label: "APR",
        value: 3
    },
    {
        month: "May",
        label: "MAY",
        value: 4
    },
    {
        month: "June",
        label: "JUN",
        value: 5
    },
    {
        month: "July",
        label: "JUL",
        value: 6
    },
    {
        month: "August",
        label: "AUG",
        value: 7
    },
    {
        month: "September",
        label: "SEP",
        value: 8
    },
    {
        month: "October",
        label: "OCT",
        value: 9
    },
    {
        month: "November",
        label: "NOV",
        value: 10
    },
    {
        month: "December",
        label: "DEC",
        value: 11
    }
]

export const getYears = () => {
    const d = new Date();
    let year = d.getFullYear();
    const years = [];
    while (years.length !== 20) {
        years.push({
            value: year,
            label: year
        });
        year += 1;
    }
    return years;
}