import React, { Component, useState } from 'react';
import numeral from 'numeral';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import { Row, Col, Button, Accordion, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';
import Loading from '../../../ui/Loading';

import SearchShopForm from '../shop-homepage/SearchShopForm';
import AddToCartForm from './AddToCartForm';

const ProductDescription = props => {
  const { description } = props || {};
  const [isOpen, toggle] = useState(false);
  return (
    <div
      className={isOpen ? 'product-description open' : 'product-description'}
    >
      <div className="description">{description}</div>
      {!isOpen &&
        <div className="truncate">
          <a href onClick={() => toggle(!isOpen)}>See More</a>
        </div>}
    </div>
  );
};

const formatQuery = (
  {
    shop,
    productId
  }
) => ({
  shop: shop || '',
  productId: productId ? productId : ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class ShopProduct extends Component {
  componentWillMount() {
    const {
      getUserDetails,
      history,
      location: { pathname, search },
      getProductById,
      getCart
    } = this.props;

    const query = getQueryFromSearch(search);

    const {
      shop,
      productId
    } = query || {};

    getUserDetails(
      { getWebsiteOptions: 1, getConcepts: 1 },
      null,
      true,
      data => {
        const {
          userCompanies
        } = data || {};

        const choiceExists = !!(userCompanies || [])
          .find(({ companyID }) => companyID === parseInt(shop));

        if (!choiceExists) {
          history.push('/shop');
          return;
        }

        const {
          companyID
        } = (userCompanies || [])[0] || {};

        const companySearch = choiceExists ? shop : companyID;
        getProductById(
          { companyId: companySearch, productId },
          null,
          false,
          null
        );
        //getCart for specific company
        getCart({ id: null, companyId: companySearch });

        if (shop === companySearch) return;

        const qs = queryString.stringify({ shop: companySearch, productId });
        history.push({ pathname, search: qs });
      }
    );
  }
  componentWillUnmount() {
    const {
      clearGetProductById
    } = this.props;

    clearGetProductById();
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const {
      location: { pathname, search: search2 },
      getProductById,
      getUserDetailsResult
    } = this.props;

    if (search === search2) return;

    const {
      userCompanies
    } = getUserDetailsResult || {};

    const query = getQueryFromSearch(search);

    const {
      shop,
      productId
    } = query || {};

    const choiceExists = !!(userCompanies || [])
      .find(({ companyID }) => companyID === parseInt(shop));

    const {
      companyID
    } = (userCompanies || [])[0] || {};

    const companySearch = choiceExists ? shop : companyID;

    getProductById({ companyId: companySearch, productId }, null, false, null);
  }
  render() {
    const {
      inProgress,
      showModal,
      history,
      location: { pathname, search },
      getUserDetailsResult,
      getUserDetailsError,
      clearGetUserDetails,
      getProductByIdResult,
      getProductByIdError,
      clearGetProductById,
      postCart,
      clearPostCart,
      postCartInProgress,
      postCartError,
      getCartResult
    } = this.props;

    const query = getQueryFromSearch(search);

    const {
      shop,
      productId
    } = query || {};

    const {
      userCompanies
    } = getUserDetailsResult || {};

    const {
      status
    } = getProductByIdError || {};

    if (status === 401) history.push('/');

    const {
      category,
      depth,
      height,
      imageHref,
      model,
      sellPrice,
      spec,
      specSheetHref,
      weight,
      width
    } = getProductByIdResult || {};

    const { 
      categoryId,
      categoryName
    } = category || {};

    const metadata = {
      title: `${model || ''} | Products | Concept Services`
    };

    const handleShopChange = value => {
      const choiceExists = !!(userCompanies || [])
        .find(({ companyID }) => companyID === parseInt(value));

      const {
        companyID
      } = (userCompanies || [])[0] || {};

      const companySearch = choiceExists ? value : companyID;

      const qs = queryString.stringify({ shop: companySearch, category: '' });
      history.push({ pathname: '/shop/categories', search: qs });
    };

    const {
      shoppingCartId
    } = getCartResult || {};

    const handleAddToCart = ({ qty }) => {
      const payload = {
        productId,
        qty,
        companyId: shop
      };

      postCart(
        {
          payload,
          id: shoppingCartId
        },
        'add-to-cart-form',
        false,
        () => {
          showModal('ShoppingCartModal');
        }
      );
    };

    return (
      <Layout route="shop-product-detail" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={4}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/shop?shop=${shop}`}>Shop</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <span className="active">{model || ''}</span>
                  </li>
                </ol>
              </nav>
            </Col>
            <Col md={8}>
              <SearchShopForm
                enableReinitialize
                userCompanies={userCompanies}
                handleShopChange={handleShopChange}
                initialValues={{ changeShop: parseInt(shop) }}
                query={query}
                history={history}
              />
            </Col>
          </Row>

          {inProgress && <Loading fullPage message="Loading, please wait" />}
          {!inProgress &&
            <div className="box-product-details">
              <Row>
                <Col md={6}>

                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imageHref}
                        alt="Loading"
                      />
                    </Carousel.Item>
                  </Carousel>

                </Col>
                <Col md={6}>
                  <h2>{model || ''}</h2>
                  <div className="back">
                    See More in
                    {' '}
                    <Link
                      to={
                        `/shop/categories?category=${categoryId}&shop=${shop}`
                      }
                    >
                      {categoryName || ''}
                    </Link>
                  </div>
                  <div className="product-price">
                    ${numeral(sellPrice).format('0,0.00')}
                  </div>

                  <AddToCartForm
                    // handleSubmit={() => showModal('ShoppingCartModal')}
                    initialValues={{ qty: 1 }}
                    onSubmit={handleAddToCart}
                    postCartInProgress={postCartInProgress}
                  />

                  <div className="contacts">
                    <h5>Have Questions about this product?</h5>
                    <p>Our experts are here to help.</p>
                    <Row>
                      <Col>
                        <a
                          href="tel:"
                          className="btn btn-sm btn-outline-primary"
                        >
                          <Icon name="phone" /> Call 866-474-2537
                        </a>
                      </Col>
                      <Col>
                        <a
                          href="mailto:"
                          className="btn btn-sm btn-outline-primary"
                        >
                          <Icon name="envelope" /> Send us a message
                        </a>
                      </Col>
                    </Row>
                  </div>

                  <Accordion defaultActiveKey="0">
                    <div>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Product Description
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <ProductDescription description={spec} />
                      </Accordion.Collapse>
                    </div>
                    <div>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Specifications
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <div>
                          <table className="table table-bordered">
                            <tbody>
                              {width !== null && (
                                <tr key={1}>
                                  <th>
                                    Width
                                  </th>
                                  <td>
                                    {numeral(width).format('0,0.0')}{' '}in
                                  </td>
                                </tr>
                              )}
                              {height !== null && (
                                <tr key={2}>
                                  <th>
                                    Height
                                  </th>
                                  <td>
                                    {numeral(height).format('0,0.0')}{' '}in
                                  </td>
                                </tr>
                              )}
                              {depth !== null && (
                                <tr key={3}>
                                  <th>
                                    Depth
                                  </th>
                                  <td>
                                    {numeral(depth).format('0,0.0')}{' '}in
                                  </td>
                                </tr>
                              )}
                              {weight !== null && (
                                <tr key={4}>
                                  <th>
                                    Weight
                                  </th>
                                  <td>
                                    {numeral(weight).format('0,0.0')}{' '}lbs
                                  </td>
                                </tr>
                              )}                 
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    {specSheetHref && (
                      <div>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                          Product Downloads
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <div>
                            <ul>
                              <li key={1}>
                                <a href={specSheetHref} target="_blank">
                                  <Icon name="file-alt" /> Spec Sheet
                                </a>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    )}
                  </Accordion>

                </Col>
              </Row>
            </div>}
            <div style={{ textAlign: 'center', width: '100%', fontStyle: 'italic' }}>
              All items are subject to manufacture lead time and availability.
            </div>
        </main>
        {getUserDetailsError &&
          <Notification
            key="user-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetUserDetails}
          >
            Failed to get shop details. Please refresh the page.
          </Notification>}
        {getProductByIdError &&
          !(status === 401) &&
          <Notification
            key="category-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetProductById}
          >
            Failed to load product details. Please refresh the page.
          </Notification>}
        {postCartError &&
          <Notification
            key="post-cart-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostCart}
          >
            Failed to add product to cart. Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: getUserDetailsResult,
    error: getUserDetailsError,
    inProgress: getUserDetailsInProgress
  } = state.getUserDetails.toJS();

  const {
    result: getProductByIdResult,
    error: getProductByIdError,
    inProgress: getProductByIdInProgress
  } = state.getProductById.toJS();

  const {
    result: postCartResult,
    error: postCartError,
    inProgress: postCartInProgress
  } = state.postCart.toJS();

  const {
    result: getCartResult,
    inProgress: getCartInProgress,
    error: getCartError
  } = state.getCart.toJS();

  return {
    getUserDetailsResult,
    getUserDetailsError,
    getProductByIdResult,
    getProductByIdError,
    inProgress: getProductByIdInProgress || getUserDetailsInProgress,
    postCartInProgress,
    postCartError,
    getCartResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProductById: fetchDux.getProductById.createAction,
      clearGetProductById: fetchDux.getProductById.clearAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
      postCart: fetchDux.postCart.createAction,
      clearPostCart: fetchDux.postCart.clearAction,
      getCart: fetchDux.getCart.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShopProduct);
