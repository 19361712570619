import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { Row, Col, Button } from 'react-bootstrap';
import FormField from "../../forms/FormField";
import Icon from "../../ui/Icon";
import {normalizeMoney} from './helpers/helpers';

const required = value => value ? undefined : 'Required';


const renderUserContacts = ({ fields }) => {

    return (
        <>
            <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Col md={8}>
                    <h5>
                        Warehouse Contacts
                    </h5>
                </Col>

                <Col sm={4}>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => fields.push({})}
                        style={{ float: 'right' }}
                    >
                        <Icon name="plus" /> Add User Contact
                    </Button>
                </Col>
            </Row>
            {
                fields.map((contact, index) => {
                    return (
                        <Row key={index}>
                            <Col sm={3}>
                                <Field
                                    name={`${contact}.UserFirstName`}
                                    type="text"
                                    label="First Name"
                                    component={FormField.Input}
                                    validate={[required]}
                                    required
                                />
                            </Col>

                            <Col sm={3}>
                                <Field
                                    name={`${contact}.UserLastName`}
                                    type="text"
                                    label="Last Name"
                                    component={FormField.Input}
                                    validate={[required]}
                                    required
                                />
                            </Col>

                            <Col sm={3}>
                                <Field
                                    name={`${contact}.Email`}
                                    type="text"
                                    label="Email"
                                    component={FormField.Input}
                                    validate={[required]}
                                    required
                                />
                            </Col>

                            <Col sm={2}>
                                <Field
                                    name={`${contact}.Phone`}
                                    type="text"
                                    label="Phone"
                                    component={FormField.Input}
                                />
                            </Col>
                            {
                                index !== 0 &&
                                <Col sm={1} style={{ paddingTop: '2.2rem' }}>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => fields.remove(index)}
                                    >
                                        <Icon name="trash" className="danger" />
                                    </Button>
                                </Col>
                            }
                        </Row>
                    )
                })
            }
        </>
    )
}

const getContactFields = () => {
    return (
        <>
            <Row>
                <Col sm={12}>
                    <h2>Contact</h2>
                </Col>
                <Col sm={6}>
                    <Field
                        name="name"
                        type="text"
                        label="Name"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="address1"
                        type="text"
                        label="Address 1"
                        component={FormField.Input}
                    />
                </Col>


                <Col sm={6}>
                    <Field
                        name="address2"
                        type="text"
                        label="Address 2"
                        component={FormField.Input}
                    />
                </Col>


                <Col sm={6}>
                    <Field
                        name="city"
                        type="text"
                        label="City"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="postalCode"
                        type="text"
                        label="Zip"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="stateProvince"
                        type="text"
                        label="State"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="tollFree"
                        type="text"
                        label="TollFree"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="phone"
                        type="text"
                        label="Phone"
                        component={FormField.Input}
                        validate={[required]}
                        required
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="fax"
                        type="text"
                        label="Fax"
                        component={FormField.Input}
                    />
                </Col>

                <Col sm={6}>
                    <Field
                        name="emergencyPhone"
                        type="text"
                        label="Emergency Phone"
                        component={FormField.Input}
                    />
                </Col>
            </Row>
            <FieldArray name='userContacts' component={renderUserContacts} />
        </>
    )
}

const getHandlingFields = () => {
    return (
        <Row>
            <Col sm={12} style={{ marginTop: '5vh' }}>
                <h2>Handling/Delivery</h2>
            </Col>

            <Col sm={6}>
                <Field
                    name="minHandling"
                    type="text"
                    label="Min Handling Charge"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="minDelivery"
                    type="text"
                    label="Min Delivery Charge"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="handlingIn"
                    type="text"
                    label="Handling - In (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="handlingOut"
                    type="text"
                    label="Handling - Out (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="storage"
                    type="text"
                    label="Storage (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="delivery999"
                    type="text"
                    label="Delivery 0 - 999 (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="delivery1999"
                    type="text"
                    label="Delivery 1000 - 1999 (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="delivery3999"
                    type="text"
                    label="Delivery 2000 - 3999 (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="delivery7999"
                    type="text"
                    label="Delivery 4000 - 7999 (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="deliveryPlus"
                    type="text"
                    label="Delivery  8000 and up (cwt)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="driverHelper"
                    type="text"
                    label="Driver helper (per hour)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="manTruck"
                    type="text"
                    label="1 man and a truck (per hour)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="twoMenTruck"
                    type="text"
                    label="2 men and a truck (per hour)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="warehouseHelper"
                    type="text"
                    label="Warehouse helper (per hour)"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

        </Row>
    )
}

const getAdditionalInfoFields = () => {
    return (
        <Row>
            <Col sm={12} style={{ marginTop: '5vh' }}>
                <h2>Additional Info</h2>
            </Col>
            <Col sm={6}>
                <Field
                    name="debrisRemoval"
                    type="text"
                    label="Debris Removal"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="protection"
                    type="text"
                    label="Full Value Protection"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="accessFee"
                    type="text"
                    label="Access Fee"
                    component={FormField.Input}
                    normalize={(value)=>normalizeMoney(value)}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="notes"
                    type="text"
                    label="Notes"
                    component={FormField.Input}
                />
            </Col>

            <Col sm={6}>
                <Field
                    name="receivingHours"
                    type="text"
                    label="Receiving Hours"
                    component={FormField.Input}
                />
            </Col>
            <Col sm={6}>
              <Field
                name="fuelSurcharge"
                type="text"
                label="Fuel Surcharge"
                component={FormField.Input}
                normalize={(value)=>normalizeMoney(value)}
              />
            </Col>
        </Row>
    )
}

const WarehouseForm = (
    {
        handleSubmit,
        submitting,
        newProject
    }
) => {
    return (
        <form onSubmit={handleSubmit}>
            <h1 style={{ textTransform: 'uppercase' }}>Warehouse Confirmation</h1>
            <p>
                Please use the form below to confirm or update the information below.
                This information allows us to better use your services and ensures we have the latest
                information available.
            </p>

            {!submitting && getContactFields()}
            {!submitting && getHandlingFields()}
            {!submitting && getAdditionalInfoFields()}

            <p style={{ color: 'rgba(181, 182, 179, 1)' }}>
                NOTE: This is a one time use link. Once you have sumbitted your changes, you will no longer
                be able to access this page.
            </p>

            <Row style={{ justifyContent: 'flex-end', marginBottom: '2rem' }}>

                <Button
                    variant="secondary"
                >
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    type="submit"
                >
                    {newProject ? 'Accept Rates and Project' : 'Submit Rates' }
                </Button>

            </Row>

        </form>
    )
}

const formName = 'warehouse-update';
export default reduxForm({
    form: formName,
    enableReinitialize: true
})(WarehouseForm);