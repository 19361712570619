import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paymentOptionDict from '../paymentOptionDict';
import moment from 'moment';

const required = value => !value ? 'Required' : undefined;

const SHOP_LINK = process.env.REACT_APP_SHOP_LINK;

const Address = (
  {
    nameFirst,
    nameLast,
    Address1,
    Address2,
    City,
    StateProvince,
    PostalCode,
    businessName,
    specialInstructions
  }
) => {
  return (
    <address>
      {nameFirst}
      {' '}
      {nameLast}
      <br />
      {Address1}<br />
      {Address2 && <span>{Address2}<br /></span>}
      {City}
      ,
      {' '}
      {PostalCode}
      ,
      {' '}
      {StateProvince}
      <br />
      {/* {result.shippingAddress.storeName} */}
      {/* {' '} */}
      {/* - Store # */}
      {/* {result.shippingAddress.storeId} */}
      {/* <br /> */}
    </address>
  );
};

const getPaymentDetailText = (
  {
    paymentOption,
    // card
    cardNumber,
    checkName,
    checkNumber,
    cardSecurityCode,
    month,
    nameOnCard,
    year,
    //bank
    bankName,
    nameOnAccount,
    //po
    poNumber,
    poDate,
    poName
  }
) => {
  switch (paymentOption) {
    case 1:
      return (
        <span>
          **** **** **** {(cardNumber || '').substring(cardNumber.length - 4)}
        </span>
      );
    case 2:
      return (
        <span>
          {`Po No.: ${poNumber || ''}`}
          <br />
          {`Po Date: ${moment(poDate).format('ddd MMM DD, YYYY')}`}
          <br />
          {`Po Approver: ${poName}`}
        </span>
      );
    case 3:
      return (
        <span>
          {`Name: ${checkName}`}
          <br />
          {`CheckNumer: ${checkNumber || ''}`}
        </span>
      );
    case 4:
      return (
        <span>
          {`Bank Name: ${bankName}`}
          <br />
          {`Account Name: ${nameOnAccount}`}
        </span>
      );
    default:
      return '';
  }
};

const PaymentDetails = ({ billing }) => {
  const {
    paymentOption
  } = billing || {};

  const paymentOptionText = paymentOptionDict[paymentOption];
  const paymentDetailText = getPaymentDetailText(billing || {});
  return (
    <div>
      <Link to="/checkout/payment" className="float-right">Change</Link>
      <h4>Payment Details</h4>
      <h5>{paymentOptionText}</h5>
      <div>{paymentDetailText}</div>
    </div>
  );
};

const CheckoutReviewForm = props => {
  const {
    handleSubmit,
    showModal,
    cart,
    billing,
    postCartPaymentLoading
  } = props;
  const {
    shippingAddress,
    billingAddress,
    ShipViaId
  } = cart || {};

  const {
    paymentOption
  } = billing || {};

  const handleViewAgreement = () => {
    window.open(
      `${SHOP_LINK}/quote-agreement`,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">

      <h5>Review Your Order</h5>

      <div className="box">
        <Link to="/checkout/shipping" className="float-right">Change</Link>
        <h4>Shipping Summary</h4>
        {/* <h5>Shipping Method</h5>
        <div>{ShipViaId}</div> */}
        <h5>Shipping Address</h5>
        <Address {...shippingAddress || {}} />
      </div>

      <div className="box">
        <PaymentDetails billing={billing || {}} />
        <h5>Billing Address</h5>
        <Address {...billingAddress || {}} />
        {paymentOption === 3 &&
          <div className="billing-alert">
            <p>
              Items will be released upon receipt of check. Details of where to send check will be provided on order confirmation.
            </p>
          </div>}

        {paymentOption === 1 &&
          <div className="billing-alert">
            <p>
              Convenience fee will be applied to all credit card orders.
            </p>
          </div>}

        {paymentOption === 4 &&
          <div className="billing-alert">
            <p>
              Items will be released upon receipt of Bank Transfer. Details of how to process the ACH will be provided on order confirmation.
            </p>
          </div>}
      </div>

      <div className="box">
        <h4>Document Upload</h4>
        <h5>Upload Associated Documents</h5>
        <div>
          If your process requires it, please upload any documents for this order.
        </div>
        <Field name="upload" component={FormField.InputFileDropzone} multiple />
      </div>

      <div className="box">
        <h4>Purchase Agreement</h4>
        <h5 onClick={handleViewAgreement}>Purchase Agreement</h5>
        <Field
          name="tos"
          type="checkbox"
          component={FormField.InlineCheckbox}
          label="I have read and agree to the Concept Services Purchase Agreement"
          validate={[required]}
        />
      </div>

      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit}
        disabled={postCartPaymentLoading}
      >
        Submit Order
      </Button>

    </form>
  );
};

export default reduxForm({
  form: 'checkout-review-form'
})(CheckoutReviewForm);
