import React, { useEffect, useState } from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import EditShippingAddressModal from './EditShippingAddressModal';
import { bindActionCreators } from 'redux';
import AddShippingAddressModal from './AddShippingAddressModal';


const getAddress = ({ Address1, Address2 } = {}) => {
  if (!Address2) return Address1;
  return `${Address1} ${Address2}`;
}

const isValidAddress = ({ Address1, Address2 } = {}) => {
  if (!Address1 && !Address2) return false;
  return true
}

const CheckoutShippingForm = props => {
  const {
    handleSubmit,
    showModal,
    locations,
    address,
    orderLocation,
    handleAddressChange,
    saveShippingAddressLoading
  } = props;

  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);
  const [activeAddress, setActiveAddress] = useState(false);
  useEffect(() => {
    setActiveAddress(orderLocation);
  }, [orderLocation])

  const toggleAddressBook = value => {
    setIsAddressBookOpen(value);
    if (value === false) return setActiveAddress(null);
    const { ID } = address || {};
    setActiveAddress(ID || -1)
  }



  const locationOptions = (locations || [])
    .reduce((prev, { ID, Name }) => {
      return [
        ...prev,
        {
          value: ID,
          label: Name
        }
      ]
    }, [{
      value: -1,
      label: 'Not Shown'
    }])

  const {
    nameFirst,
    nameLast,
    Address1,
    Address2,
    City,
    PostalCode,
    StateProvince,
    specialInstructions
  } = address || {};

  const isAddressValid = isValidAddress(address);


  const handleSetActiveAddress = ({ ID }) => () => {
    setActiveAddress(ID);
  }

  const updateSelectedAddress = (ID) => {
    let selectedAddress = {};
    const location = locations.find(a => a.ID === ID);
    if (!location) {
      if (address.ID) {
        handleAddressChange("checkout-shipping-form", "address", { nameFirst, nameLast });
      }
      else {
        handleAddressChange("checkout-shipping-form", "address", address);
        handleAddressChange("checkout-shipping-form", "orderLocation", null);
      }
      return;
    }
    const keys = ["Address1", "Address2", "City", "PostalCode", "StateProvince"];
    keys.map(key => selectedAddress[key] = location[key]);
    selectedAddress.nameFirst = nameFirst;
    selectedAddress.nameLast = nameLast;
    selectedAddress.ID = location.ID;
    handleAddressChange("checkout-shipping-form", "address", selectedAddress);
    handleAddressChange("checkout-shipping-form", "orderLocation", selectedAddress.ID);
  }

  const hasAddressBook = (locations || []).length > 0;
  const isCustomAddress = !!address && !address.ID;

  return (
    <form onSubmit={handleSubmit} className="checkout-form">

      <h5>Choose Order Location</h5>
      <div className="box">
        <Field
          name="orderLocation"
          type="text"
          component={FormField.SelectReact}
          options={locationOptions}
          onChange={(ID) => updateSelectedAddress(ID)}
        />
      </div>

      {/* <h5>Shipping Method</h5>
      <div className="box">
        <Field
          name="shippingMethod"
          type="text"
          component={FormField.SelectReact}
        />
        <div className="shipping-alert">
          <strong>Shipping Alert</strong>
          <p>All shipments are subject to manufacturer lead times.</p>
        </div>
      </div> */}

      <h5>Ship To</h5>
      <div className="box box-ship-to">
        {isAddressBookOpen &&
          <div>
            <a className="float-right" onClick={() => toggleAddressBook(false)}>
              Close
            </a>
            <h5>Change Shipping Address</h5>
            {(locations || []).map((location, index) => {
              return (
                <div
                  className={activeAddress === location.ID ? 'address active' : 'address'}
                  key={index}
                  onClick={handleSetActiveAddress(location)}
                >
                  {activeAddress === location.ID && <Icon name="dot-circle" />}
                  {activeAddress !== location.ID && <Icon name="circle" />}
                  <div style={{ pointerEvents: "none" }}>
                    <div>
                      {getAddress(location)}
                      ,
                      {' '}
                      {location.City}
                      ,
                      {' '}
                      {location.PostalCode}
                      {' '}
                      {location.StateProvince}
                    </div>
                    <div>
                      {location.Name}
                    </div>
                  </div>
                </div>
              );
            })}
            {isCustomAddress && (
              <div
                className={activeAddress === -1 ? 'address active' : 'address'}
                key={-1}
                onClick={handleSetActiveAddress({ ID: -1 })}
              >
                {activeAddress === -1 && <Icon name="dot-circle" />}
                {activeAddress !== -1 && <Icon name="circle" />}
                <div style={{ pointerEvents: "none" }}>
                  <div>
                    {getAddress(address)}
                    ,
                    {' '}
                    {address.City}
                    ,
                    {' '}
                    {address.PostalCode}
                    {' '}
                    {address.StateProvince}
                  </div>
                  {/* <div>
                    {address.Name}
                  </div> */}
                </div>
              </div>
            )}
            <Row className="actions">
              {locations &&
                locations.length === 0 &&
                <h5 style={{ paddingLeft: '1em', paddingBottom: '1em' }}>
                  No address book found. Please enter a shipping address to continue.
                </h5>
              }
              <Col sm={6}>
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => showModal('AddShippingAddressModal')}
                >
                  {locations && locations.length > 0 ? 'Ship To Different Address' : 'Ship to Address'}
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    updateSelectedAddress(activeAddress);
                    toggleAddressBook(false);
                  }}
                  disabled={!activeAddress}
                >
                  Use This Address
                </Button>
              </Col>
            </Row>
          </div>}
        {!isAddressBookOpen &&
          <div>
            {hasAddressBook && (
              <a className="float-right" onClick={() => toggleAddressBook(true)}>
                Change
              </a>
            )}
            {!hasAddressBook && (
              <a
                className="float-right"
                onClick={() => showModal('AddShippingAddressModal')}
              >
                {address ? 'Edit' : 'Add'}
              </a>
            )}
            {isAddressValid ? (
              <address>
                {nameFirst} {nameLast}<br />
                {getAddress(address)}<br />
                {City}, {PostalCode}, {StateProvince}<br />
                {specialInstructions}
                {/* Store Name - Store Id<br /> */}
              </address>
            ) :
              (
                <span>Please enter/select a shipping address to continue.</span>
              )
            }

          </div>}
      </div>

      <Button
        type="submit"
        variant="primary"
        disabled={saveShippingAddressLoading || !isAddressValid}>
        Continue
      </Button>
      <EditShippingAddressModal
        address={address}
        showModal={showModal}
      />
      <AddShippingAddressModal
        showModal={showModal}
        address={address}
        handleSubmit={(address) => {
          handleAddressChange("checkout-shipping-form", "address", address);
          toggleAddressBook(false);
        }}
      />

    </form>
  );
};


const formName = 'checkout-shipping-form';

const form = reduxForm({
  form: formName
})(CheckoutShippingForm);

const selector = formValueSelector(formName)
const mapState = state => {
  const { address, orderLocation } = selector(state, "address", "orderLocation");
  return {
    address,
    orderLocation
  }
}

const mapDispatch = dispatch => bindActionCreators({
  handleAddressChange: change,
}, dispatch)

export default connect(mapState, mapDispatch)(form);
