import React, { Component } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import QuoteStatus from '../../../ui/QuoteStatus';
import QuoteEditForm from './QuoteEditForm';
import fetchDux from '../../../../state/fetch-dux';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';
import HelpModal from '../../homepage/HelpModal';

const result = {
  status: 2,
  name: 'Quote name 1',
  proposalNo: 12345678,
  purchaser: 'Purchaser',
  expires: '10/04/2021',
  plansDated: '12/01/2021',
  price: 56779.2,
  proposalSummary: {
    services: [
      {
        name: 'Essential Services',
        sections: [
          {
            name: 'Project Management Services',
            items: [
              {
                name: 'Project Management, Coordination & Administration',
                price: 650000,
                isOptional: true,
                isIncluded: true
              },
              {
                name: 'Project Management, Coordination & Administration',
                price: 650000,
                isOptional: true,
                isIncluded: false
              },
              {
                name: 'Project Management, Coordination & Administration',
                price: 650000
              }
            ]
          }
        ]
      },
      {
        name: 'Essential Services',
        sections: [
          {
            name: 'Project Management Services',
            items: [
              {
                name: 'Project Management, Coordination & Administration',
                price: 650000
              }
            ]
          },
          {
            name: 'Project Management Services',
            items: [
              {
                name: 'Project Management, Coordination & Administration',
                price: 650000
              }
            ]
          }
        ]
      }
    ],
    summary: {
      deposit: {
        invoice: 1892.33,
        percentage: 30
      },
      total: {
        services: 0,
        material: 5054,
        subtotal: 900,
        salesTax: 353.78,
        grandTotal: 6307.78
      }
    }
  },
  items: [
    {
      itemNo: 'C21',
      qty: 7,
      manufacturer: 'Manufacturer',
      modelNo: 'VCT-2-34567876543',
      category: 'Category',
      spec: 'Mini Vertical Contact',
      unitPrice: 2134.12,
      sellPrice: 87763,
      items: [
        {
          qty: 3,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Included',
          show: false
        },
        {
          qty: 2,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Optional',
          show: true
        },
        {
          qty: 2,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Included',
          show: true
        }
      ]
    },
    {
      itemNo: 'C21',
      qty: 7,
      manufacturer: 'Manufacturer',
      modelNo: 'VCT-2-34567876543',
      category: 'Category',
      spec: 'Mini Vertical Contact',
      unitPrice: 2134.12,
      sellPrice: 87763,
      items: [
        {
          qty: 3,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Included',
          show: false
        },
        {
          qty: 2,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Optional',
          show: true
        },
        {
          qty: 2,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Included',
          show: true
        }
      ]
    },
    {
      itemNo: 'C21',
      qty: 7,
      manufacturer: 'Manufacturer',
      modelNo: 'VCT-2-34567876543',
      category: 'Category',
      spec: 'Mini Vertical Contact',
      unitPrice: 2134.12,
      sellPrice: 87763,
      items: [
        {
          qty: 3,
          unitPrice: 1234,
          description: 'Lorem impsum...',
          status: 'Included',
          show: false
        }
      ]
    },
    {
      itemNo: 'C21',
      qty: 7,
      manufacturer: 'Manufacturer',
      modelNo: 'VCT-2-34567876543',
      category: 'Category',
      spec: 'Mini Vertical Contact',
      unitPrice: 2134.12,
      sellPrice: 87763
    }
  ]
};

const formatMoney = value => numeral(value).format('0,0.00');
const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM d, Y');
};

class QuotesEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mountComplete: false
    }
  }

  componentDidMount() {
    if (this.state.mountComplete) return;
    const { getQuoteById, match } = this.props;
    const { id } = match.params;
    getQuoteById({ id });
  }

  render() {
    const {
      result,
      quoteLoading,
      showModal
    } = this.props;

    const metadata = {
      title: `${(result || {}).Name} | Quotes | Concept Services`
    };
    return (
      <Layout route="quotes-details" metadata={metadata} sidebar fluid>
        <main>
          {quoteLoading && <Loading fullPage />}
          {
            !quoteLoading && result &&
            <>
              <Row>
                <Col md={7}>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/quotes">Quotes</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <span className="active">Quote Details</span>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="layout-subheader">
                    {(result || {}).Name} <QuoteStatus status={(result || {}).Status} />
                  </h2>
                </Col>
                <Col md={5}>
                  <div className="layout-header-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" size="sm">
                        <Icon fixedWidth name="ellipsis-h" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Icon fixedWidth name="download" /> Download PDF
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Icon fixedWidth name="file-alt" /> View Terms
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Icon fixedWidth name="sync" /> Request Revisions
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Icon fixedWidth name="thumbs-up" /> Accept Quote
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>

              <div className="box-quote-detail">
                <div className="box-header">
                  <div>
                    <strong>Proposal Number</strong>
                    {(result || {}).QuoteNumber}
                  </div>
                  <div>
                    <strong>Purchaser</strong>
                    {(result || {}).Purchaser}
                  </div>
                  <div>
                    <strong>Expires</strong>
                    {formatTime((result || {}).ExpirationDate)}
                  </div>
                  <div>
                    <strong>Plans Dated</strong>
                    {formatTime((result || {}).PlanDate)}
                  </div>
                  <div>
                    <strong>Total</strong>
                    ${formatMoney((result || {}).Total)}
                  </div>
                </div>
                <div className="box-body">
                  <QuoteEditForm
                    result={result}
                    items={(result || {}).items}
                  />
                  <div className="contact-us">
                    <h3>Contact Us</h3>
                    <p>
                      Feel free to
                      {' '}
                      <Link
                        to={'#'}
                        onClick={() => {
                          showModal('helpModal')
                        }}>contact us</Link>
                      {' '}
                      with any questions regarding this quote.
                    </p>
                    <p>
                      Read the Concept Services
                      {' '}
                      <Link to="/quote-agreement">Terms and Conditions</Link>
                      {' '}
                      for this quote.
                    </p>
                  </div>
                </div>
              </div>
            </>
          }
          <HelpModal />
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress: quoteLoading,
    result
  } = state.getQuoteById.toJS();
  return {
    quoteLoading,
    result
  }
};
const mapDispatch = dispatch => bindActionCreators({
  getQuoteById: fetchDux.getQuoteById.createAction,
  ...actionCreators
}, dispatch);

export default connect(mapState, mapDispatch)(QuotesEdit);
