import React, { Component } from 'react';
import { Row, Col, Alert, Container } from 'react-bootstrap';

import Layout from '../../ui/Layout';

const labelStyle = {
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px'
};
const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
  marginTop: '1rem'
};

class QuoteAgreement extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <Row className="justify-content-sm-center align-items-center">
            <Col sm md={12}>
              <div style={{ padding: '2rem 0' }}>
                <h3 style={headerStyle}>QUOTE TERMS AND CONDITIONS</h3>
                <div style={labelStyle}>I. Plan Review and Quotation</div>
                <p>
                  It is the responsibility of the Purchaser to supply the accurate documents to Concept Services for quoting purposes. This should include the Architectural Drawings, Elevations, Mechanical, Electrical, and Plumbing Drawings, Rough-in Drawings, Roof Plans, and all corresponding schedules. It is the responsibility of the Purchaser to supply Concept Services all furniture, fixture, and equipment specifications. Any drawings supplied by Concept Services are for reference only and cannot be used as final working drawings. All drawings supplied by Concept Services must be reviewed and approved by the Purchaser. It is the responsibility of the Purchaser to ensure the accuracy of the documents provided to Concept Services. Concept Services assumes no responsibility for the design, layout, and dimensioning, and/or local code requirements.
                </p>
                <div style={labelStyle}>II. Ordering</div>
                <p>
                  Ordering of the Material Goods will commence after the receipt of the following: (a) signed proposal, (b) signed and approved shop drawings, deposit equal to 50% of the contract's total dollar amount. Cancelled or delayed orders are subject to additional fees including relocation, storage, and restocking fees.  Custom items are non returnable.
                </p>
                <div style={labelStyle}>III. Warehousing</div>
                <p>
                  Concept Services will inspect, consolidate, and store the furniture, fixtures, and equipment with a bonded storage facility that is located proximal to the project site.
                </p>
                <div style={labelStyle}>IV. Delivery</div>
                <p>
                  It is the responsibility of the Purchaser to schedule the furniture, fixture, and equipment delivery two (2) weeks prior to the required delivery date. Any hood and ventilation systems will deliver directly to the project site and must be unloaded, inspected, accepted, and installed by others, unless stated in writing and separately listed. Any walk-in panels and associated refrigeration systems will deliver directly to the project site and must be unloaded, inspected, accepted, and installed by others, unless stated in writing and separately listed. Any casework packages will deliver directly to the project site and must be unloaded, inspected, accepted, and installed by others, unless stated in writing and separately listed.
                </p>
                <div style={labelStyle}>V. Assembly and Set in Place</div>
                <p>
                  Only applicable If accepted and separately listed on the Proposal for Services and Materials. An agent of Concept Services will unload, uncrate, and set in place all furniture, fixtures, and equipment. All final mechanical, electrical, and plumbing connections will be performed by others. Wall shelves will be attached to the walls (wall backing must be present). It is the Purchaser’s responsibility to provide adequate dumpster space for debris. Start-up and commissioning of the furniture, fixtures, and equipment is not included, unless stated in writing and separately listed. Final set and sealing will only be done once any additional labor will be added as an extra to the contract at prevailing rates.
                </p>
                <div style={labelStyle}>VI. Assignment</div>
                <p>
                  No Assignment of this contract, in whole or in part, or any of Vendor's rights, claims, remedies, performance or obligations hereunder may be made by Vendor without the prior written consent of Concept Services which consent may be withheld for any reason. Any Assignment made by Vendor in violation of this provision is void and unenforceable.
                </p>
                <div style={labelStyle}>VII. Performance</div>
                <p>
                  All permitting is the sole responsibility of the Purchaser, unless stated in writing and separately listed. The scope of work does not include: any plumbing or electrical connections that may be required; roof penetrations, flashing or reflashing for exhaust duct, exhaust duct enclosures or chasing if required by building codes; trash removal from job site; bar top penetrations; or any refrigeration hook-ups crane or special unloading equipment charges unless stated in writing and separately listed. Installation of equipment must be performed during the normal business hours of Concept Services and is to be performed by non-union labor. Concept Services is not responsible for consequential damages by any reason. In the event Purchaser cannot take delivery of the equipment at the agreed upon time, Purchaser is responsible for all costs incurred for the redelivery and storage. All risks of loss or damages to the equipment shall pass to the Purchaser upon delivery to the site, except incurred as a result of work performed by Concept Services. Purchaser must provide ample room and access for moving equipment into the building, a paved lot to access doors, safe storage, and insurance coverage for equipment. No structural changes or removal or partitions, doors, windows, etc. for access is included in this contract. It is the Purchaser’s responsibility to verify that the equipment purchased complies with all local code requirements.
                </p>
                <div style={labelStyle}>VIII. Governing and Applicable Law</div>
                <p>
                  This Agreement shall be governed by and interpreted in accordance with the laws of the State of Texas. The exclusive forum for any litigation, dispute or other proceeding involving Purchaser and Concept Services shall be the courts of Travis County, Texas.  The Purchaser agrees to purchase in accordance with those and only those terms stated herein.  The title and right to possession of said property remains Concept Services until the total purchase price has been fully paid. Purchaser agrees to provide Concept Service with legal access to the Purchaser’s property and/or physical plant, to remove any and all equipment provided by the Concept Services, to be resold to retire any unpaid balance. Terms are net cash or certified funds prior to receipt of goods unless otherwise agreed upon and showing in writing on the face of this document. Prices may not reflect any applicable taxes. All prices are FOB manufacturer unless stated in writing on the face of this document. Prices do not include any unloading, unpackaging, uncrating, assembly or installation unless stated in writing on the face of this document. If installation, uncrating and assembly is included and separately listed, then installation of equipment is limited to the unloading, uncrating, assembly and setting in place of the equipment only listed in this contract.
                </p>
                <div style={labelStyle}>IX. Warranty</div>
                <p>
                  There are no warranties given by the Concept Services, either express or implied and included without limitation, no warranty of merchantability is given. There are no warranties, which extend beyond the description of the face hereof, unless given by the manufacturer.
                </p>
                <div style={labelStyle}>X. Indemnity</div>
                <p>
                  The Purchaser agrees to and shall indemnify, defend and hold harmless Concept Services, its employees and agents, for and against any and all liability, losses, or damages, including legal fees, Concept Services may suffer as employees and agents. This indemnification provision shall apply to all claims arising out of, pertaining to or related to the property sold to Purchaser that is the subject of this order, regardless of cause, including Concept Services’ performance or failure to perform, and including defects in the design, installation, maintenance, operation or non-operation of such products, whether based upon negligence, active or passive, (INCLUDING THE NEGLIGENCE OR GROSS NEGLIGENCE OF CONCEPT SERVICES, IT’S EMPLOYEES AND AGENTS), warranty, or strict product liability on the part of Concept Services, its employees or agents.
                </p>
                <div style={labelStyle}>XI. Acceptance of Agreement</div>
                <p>
                  This agreement is contingent upon strikes, delays of carriers, other delays and manufacturer price changes, all of which are beyond the control of Concept Services. This order is subject to the approval of Concept Services.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default QuoteAgreement;
