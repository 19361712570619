import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import FormField from '../../forms/FormField';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

const CouponForm = (
    {
        handleSubmit,
        Code,
        submitting,
        appliedCode
    }
) => {
    return (
        <form>
            <Row>
                <Col sm={8}>
                    <Field
                        component={FormField.Input}
                        name='Code'
                        placeholder={appliedCode ? `${appliedCode}` : 'Coupon Code...'}
                        disabled={!!appliedCode}
                    />
                </Col>
                <Col sm={4}>
                    <div
                        style={{
                            color: '#c5a44d',
                            cursor: 'pointer',
                            marginTop: '0.5rem',
                            opacity: (!appliedCode) && !submitting ? 1 : 0.4,
                            pointerEvents: (!appliedCode) && !submitting ? 'auto' : 'none',
                            textAlign:'end'
                        }}
                        onClick={handleSubmit}
                    >
                        Apply
                    </div>
                </Col>
            </Row>
        </form>
    )
}

const selector = formValueSelector('coupon-form');

const mapState = state => {
    const Code = selector(state, 'Code');
    return {
        Code
    }
}

const form = reduxForm({
    form: 'coupon-form'
})(CouponForm);

export default connect(mapState)(form);