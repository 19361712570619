import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import EditShippingAddressForm from './EditShippingAddressForm';

const EditShippingAddressModal = ({ handleSubmit, openModal, closeModal, address }) => (
  <Modal
    show={openModal === 'EditShippingAddressModal'}
    onHide={closeModal}
    className="modal-address"
  >
    <Modal.Header closeButton>
      <Modal.Title>Edit shipping address</Modal.Title>
    </Modal.Header>
    <EditShippingAddressForm initialValues={address} />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(EditShippingAddressModal);
