import React, { Component } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import QuoteStatus from '../../../ui/QuoteStatus';
import ProposalSummary from './ProposalSummary';
import QuoteItems from './QuoteItems';
import fetchDux from '../../../../state/fetch-dux';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';
import SigningCompleteModal from './SigningCompleteModal';
import qureyString from 'query-string';
import RequestRevisionModal from './RequestRevisionModal';
import Notification from '../../../ui/Notification';
import HelpModal from '../../homepage/HelpModal';
import SignQuoteModal from './SignQuoteModal';

const formatMoney = value => numeral(value).format('0,0.00');
const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM Do, Y');
};

class QuotesDetails extends Component {
  componentDidMount() {
    const {
      match,
      getQuoteById,
      location: { search },
      showModal,
      history,
      userDetails,
      updateQuoteStatus
    } = this.props;
    const { id } = match.params;
    getQuoteById({ id }, null, null, result => {
      const { Status } = result;
      if (Status !== 2 && Status !== 3) {
        const { user } = userDetails || {};
        const { isAdmin } = user || {};
        if (!isAdmin) {
          updateQuoteStatus({ id, status: 2 });
        }
      }
    });
    const query = qureyString.parse(search);
    if (query.event === 'signing_complete') {
      showModal('SigningCompleteModal');
      history.push(`/quotes/${id}`);
    }
  }
  componentWillUnmount() {
    const { clearGetQuoteById } = this.props;
    clearGetQuoteById();
  }
  render() {
    const {
      quoteResult,
      quoteLoading,
      downloadQuoteById,
      acceptQuoteById,
      acceptQuoteInProgress,
      history,
      showModal,
      openModal,
      closeModal,
      requestQuoteRevision,
      quoteRevisionError,
      getQuoteError,
      clearGetQuoteById,
      acceptQuoteError,
      clearAcceptQuoteById,
      updateQuoteLineItem,
      clearUpdateQuoteLineItem,
      updateQuoteLineItemError,
      updateQuoteLineItemInProgress,
      userDetails
    } = this.props;
    const result = quoteResult || {};
    const {
      items,
      ModelNoAddToWeb,
      ManufacturerAddToWeb,
      CategoryAddToWeb,
      SpecAddToWeb,
      Status,
      SubTotal,
      SalesTax,
      taxableSubTotal,
      Id,
      CustomerNote
    } = result || {};
    const tax = taxableSubTotal * (SalesTax / 100);
    const grandTotal = SubTotal + tax;

    const metadata = {
      title: `${result.Name || ''} | Quotes | Concept Services`
    };

    if (quoteLoading || acceptQuoteInProgress) {
      return (
        <Layout route="quotes-details" metadata={metadata} sidebar fluid>
          <Loading />
        </Layout>
      );
    }

    const hasExpired = Status === 5 ||
      moment(result.ExpirationDate).isBefore(moment());

    return (
      <Layout route="quotes-details" metadata={metadata} sidebar fluid>
        <main>
          <SigningCompleteModal />
          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/quotes">Quotes</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <span className="active">Quote Details</span>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                {result.Name}
                {' '}
                <QuoteStatus status={hasExpired ? 5 : result.Status} />
              </h2>
            </Col>
            {!hasExpired &&
              <Col md={5}>
                <div className="layout-header-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      size="sm"
                      disabled={updateQuoteLineItemInProgress}
                    >
                      <Icon fixedWidth name="ellipsis-h" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          downloadQuoteById({ id: result.Id });
                        }}
                      >
                        <Icon fixedWidth name="download" /> Download PDF
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          history.push('/quote-agreement');
                        }}
                      >
                        <Icon fixedWidth name="file-alt" /> View Terms
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          showModal('RequestRevisionModal');
                        }}
                        disabled={Status === 6}
                      >
                        <Icon fixedWidth name="sync" /> Request Revisions
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          showModal('SignQuoteModal');
                        }}
                        disabled={result.Status === 3}
                      >
                        <Icon fixedWidth name="thumbs-up" /> Accept Quote
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>}

          </Row>

          <div className="box-quote-detail">
            <div className="box-header">
              <div>
                <strong>Proposal Number</strong>
                {result.QuoteNumber}
              </div>
              <div>
                <strong>Purchaser</strong>
                {result.Purchaser}
              </div>
              <div>
                <strong>Expires</strong>
                {formatTime(result.ExpirationDate)}
              </div>
              <div>
                <strong>Plans Dated</strong>
                {formatTime(result.PlanDate)}
              </div>
              <div>
                <strong>Total</strong>
                ${formatMoney(grandTotal)}
              </div>
            </div>

            <div className="box-body">
              {!hasExpired &&
                <span>
                  <h3>Proposal Summary</h3>
                  <ProposalSummary
                    data={result}
                    updateQuoteLineItem={updateQuoteLineItem}
                    updateQuoteLineItemInProgress={
                      updateQuoteLineItemInProgress || result.Status === 3
                    }
                  />
                  <h3>List of Materials</h3>
                  <QuoteItems
                    items={items}
                    {...{
                      ModelNoAddToWeb,
                      ManufacturerAddToWeb,
                      SpecAddToWeb,
                      CategoryAddToWeb
                    }}
                  />

                  <div style={{ marginBottom: '2rem' }}>
                    <h3 style={{ marginBottom: '0.5rem' }}>Customer Note</h3>
                    <div>
                      {CustomerNote}
                    </div>
                  </div>
                </span>}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="contact-us">
                  <h3>Contact Us</h3>
                  <p>
                    Feel free to
                    {' '}
                    <Link
                      to={'#'}
                      onClick={() => {
                        showModal('helpModal');
                      }}
                    >
                      contact us
                    </Link>
                    {' '}
                    with any questions regarding this quote.
                  </p>
                  <p>
                    Read the Concept Services
                    {' '}
                    <Link to="/quote-agreement" target={'_blank'}>
                      Terms and Conditions
                    </Link>
                    {' '}
                    for this quote.
                  </p>
                </div>
                {!hasExpired &&
                  <div style={{ marginTop: '5rem' }}>
                    <Button
                      onClick={() => {
                        showModal('SignQuoteModal');
                      }}
                      disabled={
                        result.Status === 3 || updateQuoteLineItemInProgress
                      }
                    >
                      <Icon fixedWidth name="thumbs-up" /> Accept Quote
                    </Button>
                  </div>}
              </div>

            </div>
          </div>
          {quoteRevisionError &&
            <Notification
              key="fail-request-revision"
              duration={3}
              closable={true}
              type="danger"
            >
              Failed to request quote revision. Please try again.
            </Notification>}
          {getQuoteError &&
            <Notification
              key="fail-request-revision"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearGetQuoteById}
            >
              Failed to load quote. Please try again.
            </Notification>}

          <RequestRevisionModal
            openModal={openModal}
            closeModal={closeModal}
            quoteResult={quoteResult}
            userDetails={userDetails}
            requestQuoteRevision={values => {
              requestQuoteRevision(
                { ...values, id: result.Id },
                'request-revision-form',
                null,
                () => {
                  closeModal();
                }
              );
            }}
          />
          <HelpModal quoteNumber={result.QuoteNumber} />
          <SignQuoteModal
            openModal={openModal}
            closeModal={closeModal}
            onSignatureSave={acceptQuoteById}
            quoteNumber={result.QuoteNumber}
            quoteId={Id}
          />
          {acceptQuoteError &&
            <Notification
              key="fail-accept-quote"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearAcceptQuoteById}
            >
              Failed to accept quote. Please try again.
            </Notification>}
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress: quoteLoading,
    result: quoteResult,
    error: getQuoteError
  } = state.getQuoteById.toJS();

  const {
    inProgress: acceptQuoteInProgress
  } = state.acceptQuoteById.toJS();

  const {
    result: userDetails
  } = state.validateLogin.toJS();

  const {
    error: quoteRevisionError
  } = state.requestQuoteRevision.toJS();

  const {
    error: acceptQuoteError
  } = state.acceptQuoteById.toJS();

  const {
    error: updateQuoteLineItemError,
    inProgress: updateQuoteLineItemInProgress
  } = state.updateQuoteLineItem.toJS();

  return {
    quoteLoading,
    quoteResult,
    acceptQuoteInProgress,
    openModal: state.modals,
    quoteRevisionError,
    getQuoteError,
    userDetails,
    acceptQuoteError,
    updateQuoteLineItemError,
    updateQuoteLineItemInProgress
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getQuoteById: fetchDux.getQuoteById.createAction,
      clearGetQuoteById: fetchDux.getQuoteById.clearAction,
      downloadQuoteById: fetchDux.downloadQuoteById.createAction,
      acceptQuoteById: fetchDux.acceptQuoteById.createAction,
      requestQuoteRevision: fetchDux.requestQuoteRevision.createAction,
      updateQuoteStatus: fetchDux.updateQuoteStatus.createAction,
      clearAcceptQuoteById: fetchDux.acceptQuoteById.clearAction,
      updateQuoteLineItem: fetchDux.updateQuoteLineItem.createAction,
      clearUpdateQuoteLineItem: fetchDux.updateQuoteLineItem.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QuotesDetails);
