import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import InvoicesSearchResults from './InvoicesSearchResults';
import SearchInvoicesForm from './SearchInvoicesForm';

const result = [
  {
    name: 'Invoice Name 1',
    location: 'Location Name',
    dateCreated: '10/04/2021',
    type: 'Deposit Invoice',
    download: 'https://google.com'
  }
];

class InvoicesSearch extends Component {
  componentDidMount() {}
  render() {
    const {} = this.props;
    const metadata = {
      title: 'Invoices | Concept Services'
    };
    return (
      <Layout route="invoices-search" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/invoices">Invoices</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Invoices
              </h2>
            </Col>
            <Col md={5} className="text-right">
              <SearchInvoicesForm />
            </Col>
          </Row>

          <div className="box box-table">
            <InvoicesSearchResults result={result} />
          </div>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  {
  }
};
const mapDispatch = dispatch => bindActionCreators({}, dispatch);

export default connect(mapState, mapDispatch)(InvoicesSearch);
