import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const AddToCartForm = props => {
  const { handleSubmit, postCartInProgress } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={4} lg={3}>
          <Field
            name="qty"
            type="number"
            component={FormField.Input}
            label="Qty"
            min="1"
          />
        </Col>
        <Col md={8} lg={9}>
          <Button type='submit' disabled={postCartInProgress}><Icon name="shopping-cart" /> Add To Cart</Button>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'add-to-cart-form'
})(AddToCartForm);
