import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import React from 'react';
import animationData from "./../../../../assets/lottie/thumbs-up.json";
import Lottie from 'react-lottie';

const SigningCompleteModal = ({ openModal, closeModal }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Modal
            show={openModal === 'SigningCompleteModal'}
            onHide={closeModal}
            className="modal-address"
        >
            {/* <Modal.Header closeButton>
                <Modal.Title>Congrats</Modal.Title>
            </Modal.Header> */}
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <Lottie
                    options={defaultOptions}
                    height={250}
                    width={250}
                />
                <p>Signed Successfully</p>
            </div>
        </Modal>
    )
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(SigningCompleteModal);
