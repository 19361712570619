import React from 'react';
import Table from '../../../ui/Table';
import TablePagination from '../../../ui/TablePagination';
import Icon from '../../../ui/Icon';
// import numeral from 'numeral';
import moment from 'moment';

// const formatMoney = value => numeral(value).format('0,0.00');
const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM d, Y');
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: ({ value }) => <span>{formatTime(value)}</span>
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Invoice Type',
    accessor: 'type'
  },
  {
    Header: '',
    accessor: 'download',
    className: 'text-right',
    Cell: ({ value }) => (
      <a href={value} target="_blank"><Icon name="download" /> Download</a>
    )
  }
];

const InvoicesSearchResults = (
  {
    error,
    result
  }
) => {
  return (
    <Table
      data={result}
      columns={columns}
      noDataText="No invoices found"
      PaginationComponent={TablePagination}
    />
  );
};

export default InvoicesSearchResults;
