import React from 'react';
import { Modal } from 'react-bootstrap';
import RequestRevisionForm from './RequestRevisionForm';



const RequestRevisionModal = (props) => {
    const {
        openModal,
        closeModal,
        requestQuoteRevision,
        quoteResult,
        userDetails
    } = props;
    const { user } = userDetails || {};
    const { nameFirst, nameLast, email } = user;

    const initialValues = {
        contactName: `${nameFirst} ${nameLast}`,
        contactEmail: email
    }

    return (
        <Modal
            show={openModal === 'RequestRevisionModal'}
            className="modal-address"
        >

            <Modal.Header>
                <Modal.Title>Request Revision</Modal.Title>
            </Modal.Header>

            <RequestRevisionForm
                onSubmit={requestQuoteRevision}
                onCancel={closeModal}
                initialValues={initialValues}
                quoteResult={quoteResult}
            />

        </Modal>
    )
}

export default RequestRevisionModal;