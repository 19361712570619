import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const SearchDocumentsForm = props => {
  const { handleSubmit,handleSearchChange } = props;
  return (
    <form className="box-search-shop" onSubmit={handleSubmit}>
      <Field
        name="search"
        type="text"
        component={FormField.Input}
        placeholder="Search Documents"
        onChange={handleSearchChange}
      />
    </form>
  );
};

export default reduxForm({
  form: 'search-documents-form'
})(SearchDocumentsForm);
