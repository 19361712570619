import React, { Component } from 'react';
import { Row, Col, Alert, Container } from 'react-bootstrap';

import Layout from '../../ui/Layout';

const labelStyle = {
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px'
};
const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
  marginTop: '1rem'
};

class PurchaseAgreement extends Component {
  render() {
    return (
      <Layout>
        <Container>
          <Row className="justify-content-sm-center align-items-center">
            <Col sm md={12}>
              <div style={{ padding: '2rem 0' }}>
                <h3 style={headerStyle}>TERMS AND CONDITIONS</h3>
                <div style={labelStyle}>Shipping</div>
                <p>You are responsible for:</p>
                <ul>
                  <li>
                    Removing the shipment from the truck unless otherwise stated on receipt.
                  </li>
                  <li>
                    Bringing the shipment into your location and noting damaged or missing items on the carrier's delivery receipt.
                  </li>
                  <li>
                    If you do not make note of these issues in any way on the delivery receipt, we cannot guarantee any compensation for damaged or missing items.
                  </li>
                  <li>
                    Your signature on the delivery receipt is a legal statement confirming that your order was received undamaged, unless otherwise noted. All shipping insurance ends after you have signed for the shipment
                  </li>
                </ul>
                <p>If any piece of the shipment is damaged or missing:</p>
                <ul>
                  <li>
                    Note it clearly and thoroughly on the delivery receipt before signing (ie - "missing 3 pieces", "holes in packaging", "multiple dents on table", etc).
                  </li>
                  <li>Keep your copy of the delivery receipt</li>
                  <li>
                    Contact Concept Services in writing within 1 business day
                  </li>
                  <li>Keep the damaged packing materials for inspection</li>
                  <li>
                    Take pictures of the damaged items and packaging (preferably with delivery truck and/or driver in background)
                  </li>
                  <li>
                    In the event of damage or loss, a claim cannot be processed if the delivery receipt does not indicate damage and or loss and all of the above steps have been followed.
                  </li>
                </ul>
                <div style={labelStyle}>Warranty</div>
                <p>
                  There are no warranties given by the Concept Services, either express or implied and included without limitation, no warranty of merchantability is given. There are no warranties, which extend beyond the description of the face hereof, unless given by the manufacturer.
                </p>
                <div style={labelStyle}>Typographical and Image Errors</div>
                <p>
                  We work closely with the manufacturers to provide you with the most up-to-date and accurate information on the products we offer.  When purchasing an item, please make sure to read the description as well before committing to the purchase. We reserve the right to refuse or cancel any order for any reason.
                </p>
                <div style={labelStyle}>Pricing</div>
                <p>
                  Prices are subject to change without notification. If a price differs from the website total you will be contacted to confirm approval.
                </p>
                <div style={labelStyle}>Availability</div>
                <p>
                  Products and services may on occasion have limited or no availability due to your geographical location, an item being discontinued by the manufacturer, or an item being on backorder due to increased demand.  Please note that nothing on this site constitutes a guarantee to supply goods or services.
                </p>
                <div style={labelStyle}>Returns</div>
                <p>
                  No merchandise can be returned to Concept Services or the manufacturer without written consent.   Applicable freight, manufacturer re-stocking fees and associated handling and service fees will be deducted from any allowable credit for merchandise once it is returned and inspected.  Custom and special order items are not subject to return.
                </p>
                <div style={labelStyle}>Sales Tax</div>
                <p>
                  Laws regarding the collection of sales tax vary by location and undergo frequent change in content as well as interpretation by governmental agencies. Concept Services, Inc., will always make every effort to fully comply with the law and governmental regulations. It is our understanding that customers, whether business or individual, bear the responsibility of reporting purchases that are shipped across state lines and to pay the state and any applicable local sales taxes as per their local laws and regulations.
                </p>
                <div style={labelStyle}>Disputes</div>
                <p>
                  This Agreement shall be governed by and interpreted in accordance with the laws of the State of Texas. The exclusive forum for any litigation, dispute or other proceeding involving Purchaser and Concept Services shall be the courts of Travis County, Texas.  The Purchaser agrees to purchase in accordance with those and only those terms stated herein.  The title and right to possession of said property remains Concept Services until the total purchase price has been fully paid. Purchaser agrees to provide Concept Service with legal access to the Purchaser’s property and/or physical plant, to remove any and all equipment provided by the Concept Services, to be resold to retire any unpaid balance. Terms are net cash or certified funds prior to receipt of goods unless otherwise agreed upon and showing in writing on the face of this document. Prices may not reflect any applicable taxes. All prices are FOB manufacturer unless stated in writing on the face of this document. Prices do not include any unloading, unpackaging, uncrating, assembly or installation unless stated in writing on the face of this document. If installation, uncrating and assembly is included and separately listed, then installation of equipment is limited to the unloading, uncrating, assembly and setting in place of the equipment only listed in this contract.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default PurchaseAgreement;
