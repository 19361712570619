export const setShop = shop => {
  localStorage.setItem('shop', shop);
}

export const getShop = ({ userCompanies } = {}) => {
  const shop = localStorage.getItem('shop');
  const canAccessShop = (userCompanies || [])
    .some(({ companyID }) => companyID === parseInt(shop));

  if (!!shop && canAccessShop) return parseInt(shop);
  
  const company = (userCompanies || [])[0] || {};
  const { companyID } = company || {};
  if (!companyID) return null;
  // setShop(companyID)
  return parseInt(companyID);
}