import React from 'react';
import numeral from 'numeral';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import classNames from 'classnames';

const convertToRoman = num => {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let str = '';
  for (let i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const ProposalSummary = ({ data, updateQuoteLineItem, updateQuoteLineItemInProgress }) => {
  const {
    quoteSections = [],
    SubTotal,
    Deposit,
    SalesTax,
    taxableSubTotal,
    Id: quoteId
  } = data || {};
  const tax = taxableSubTotal * (SalesTax / 100);
  const grandTotal = SubTotal + tax;
  return (
    <div className="quote-proposal-summary">
      <div className="services">
        {(quoteSections || []).map((section, index) => {
          return (
            <div className="service" key={index}>
              <strong>
                {convertToRoman(index + 1)}. {section.SectionName}
              </strong>
              <div className="sections">
                {(section.quoteCategories || []).map((category, index) => {
                  return (
                    <div className="section" key={index}>
                      <strong>{category.CategoryName}</strong>
                      <div className="items">
                        {(category.quoteItems || []).map((item, index) => {
                          return (
                            <div
                              className={classNames('item', {
                                optional: item.IsOptional,
                                included: item.IsOptional && item.Selected
                              })}
                              key={index}
                            >
                              <strong
                                onClick={() => {
                                  const { Selected = false, Id } = item;
                                  updateQuoteLineItem({ Id, quoteId, Selected: !Selected })
                                }}
                                style={{
                                  marginLeft: item.IsOptional ? 0 : '1.2rem',
                                  cursor: 'default',
                                  userSelect: 'none',
                                  opacity: updateQuoteLineItemInProgress ? 0.5 : 1,
                                  pointerEvents: updateQuoteLineItemInProgress ? 'none' : 'auto'
                                }}
                              >
                                {
                                  item.IsOptional ?
                                    item.Selected ?
                                      <Icon name="check-square" />
                                      :
                                      <Icon name="square" />
                                    :
                                    null
                                }
                                {item.LineItemName}
                              </strong>
                              {
                                item.IsOptional ? item.Selected ?
                                  item.PriceNonNumeric ?
                                    <div className='price'>
                                      <span style={{ opacity: 0.4 }}>(selected)</span>{item.PriceNonNumeric}
                                      <br />
                                      <span style={{ fontSize: '0.75rem', color: 'black', fontWeight: '600' }}>
                                        Use the check box to add/remove optional item
                                      </span>
                                    </div>
                                    :
                                    <div className='price'>
                                      <span style={{ opacity: 0.4 }}>(selected)</span> {numeral(item.Price).format('0,0.00')}
                                      <br />
                                      <span style={{ fontSize: '0.75rem', color: 'black', fontWeight: '600' }}>
                                        Use the check box to add/remove optional item
                                      </span>
                                    </div>
                                  :
                                  item.PriceNonNumeric ?
                                    <div className='price'>
                                      <span style={{ opacity: 0.4 }}>{item.PriceNonNumeric}</span> optional
                                      <br />
                                      <span style={{ fontSize: '0.75rem', color: 'black', fontWeight: '600' }}>
                                        Use the check box to add/remove optional item
                                      </span>
                                    </div>
                                    :
                                    <div className='price'>
                                      <span style={{ opacity: 0.4 }}>${numeral(item.Price).format('0,0.00')}</span> optional
                                      <br />
                                      <span style={{ fontSize: '0.75rem', color: 'black', fontWeight: '600' }}>
                                        Use the check box to add/remove optional item
                                      </span>
                                    </div>

                                  :
                                  <div className="price">
                                    {item.PriceNonNumeric
                                      ? `item.PriceNonNumeric`
                                      : numeral(item.Price).format('0,0.00')}
                                  </div>
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Row className="row-totals">
        <Col />
        <Col md={5}>
          <table className="table-total">
            <tbody>
              <tr>
                <th>Services</th>
                <td>
                  $
                  {numeral(data.Services || 0).format('0,0.00')}
                </td>
              </tr>
              <tr>
                <th>Material Goods</th>
                <td>
                  $
                  {numeral(data.MaterialGoods || 0).format('0,0.00')}
                </td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>
                  $
                  {numeral(SubTotal || 0).format('0,0.00')}
                </td>
              </tr>
              <tr>
                <th>Sales Tax</th>
                <td>
                  $
                  {numeral(tax || 0).format('0,0.00')}
                </td>
              </tr>
              <tr>
                <th>Grand Total</th>
                <td>
                  $
                  {numeral(grandTotal || 0).format('0,0.00')}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div >
  );
};

export default ProposalSummary;
