import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import AddBillingAddressForm from './AddBillingAddressForm';

const AddBillingAddressModal = ({ handleSubmit, openModal, closeModal }) => (
  <Modal
    show={openModal === 'AddBillingAddressModal'}
    onHide={closeModal}
    className="modal-address"
  >
    <Modal.Header closeButton>
      <Modal.Title>Bill to different address</Modal.Title>
    </Modal.Header>
    <AddBillingAddressForm onSubmit={handleSubmit} />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(AddBillingAddressModal);
