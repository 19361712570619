import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WarehouseUpdate from './WarehouseUpdate.js';

export default ({ match }) => (
    <Switch>
        <Route
            path={`${match.url}/:token`}
            component={WarehouseUpdate}
        />
        <Redirect
            to={`${match.url}`}
        />
    </Switch>
);
