import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const LoginForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="username"
        type="text"
        component={FormField.Input}
        placeholder="Username"
        validate={[required]}
      />
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        placeholder="Password"
        validate={[required]}
      />
      <div>
        <Button variant="primary" type="submit" disabled={submitting}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'login-form'
})(LoginForm);
