import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import OrdersSearch from './orders-search/OrdersSearch.js';
import OrdersDetails from './orders-details/OrdersDetails.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={OrdersSearch} />
    <Route exact path={`${match.url}/:id`} component={OrdersDetails} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
