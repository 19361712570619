import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const EditShippingAddressForm = props => {
  const { handleSubmit, closeModal } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Modal.Body>
        <Row>
          <Col sm={6}>
            <Field
              name="nameFirst"
              type="text"
              component={FormField.Input}
              placeholder="First Name"
              validate={[required]}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="nameLast"
              type="text"
              component={FormField.Input}
              placeholder="Last Name"
              validate={[required]}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="businessName"
              type="text"
              component={FormField.Input}
              placeholder="Business Name"
            />
          </Col>
          <Col sm={12}>
            <Field
              name="Address1"
              type="text"
              component={FormField.Input}
              placeholder="Address Line 2"
              validate={[required]}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="Address2"
              type="text"
              component={FormField.Input}
              placeholder="Address Line 2"
            />
          </Col>
          <Col sm={5}>
            <Field
              name="City"
              type="text"
              component={FormField.Input}
              placeholder="City"
              validate={[required]}
            />
          </Col>
          <Col sm={3}>
            <Field
              name="StateProvince"
              type="text"
              component={FormField.SelectReact}
              placeholder="State"
              validate={[required]}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="PostalCode"
              type="text"
              component={FormField.Input}
              placeholder="Zip Code"
              validate={[required]}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="specialInstructions"
              type="text"
              component={FormField.Textarea}
              placeholder="Special Instructions"
              rows={5}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Use This Address
        </Button>
      </Modal.Footer>

    </form>
  );
};

export default reduxForm({
  form: 'edit-shipping-address-form'
})(EditShippingAddressForm);
