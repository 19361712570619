import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import React from 'react';
import SignaturePad from './SignaturePad';

const SignQuoteModal = (
  { openModal, closeModal, onSignatureSave, quoteNumber, signQuoteError, quoteId }
) => {
  return (
    <Modal
      show={openModal === 'SignQuoteModal'}
      onHide={closeModal}
      className="modal-address"
    >
      <Modal.Header closeButton>
        <Modal.Title>Accept Quote <b>{quoteNumber}</b></Modal.Title>
      </Modal.Header>
      <SignaturePad
        handleCancel={closeModal}
        quoteId={quoteId}
        //    submitting
        onSignatureSave={(payload) => {
          onSignatureSave(payload, null, null, () => {
            closeModal()
          })
        }}
      //    signQuoteError
      />
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(SignQuoteModal);
