import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import QuotesSearch from './quotes-search/QuotesSearch.js';
import QuotesDetails from './quotes-details/QuotesDetails.js';
import QuotesEdit from './quotes-edit/QuotesEdit.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={QuotesSearch} />
    <Route exact path={`${match.url}/:id/edit`} component={QuotesEdit} />
    <Route exact path={`${match.url}/:id`} component={QuotesDetails} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
