import React, { Component } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';

import SearchOrdersForm from './SearchOrdersForm';
import CardOrder from './CardOrder';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { connect } from 'react-redux';
import Loading from '../../../ui/Loading';

class OrdersSearch extends Component {
  componentDidMount() {
    const { getAllOrders } = this.props;
    getAllOrders();
  }
  render() {
    const { result, inProgress, error } = this.props;
    const metadata = {
      title: 'Orders | Concept Services'
    };
    return (
      <Layout route="orders-search" metadata={metadata} sidebar fluid>
        <main>
          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/orders">Orders</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Your Orders
              </h2>
            </Col>
            <Col md={5}>
              <SearchOrdersForm />
            </Col>
          </Row>

          {inProgress ?
            <Loading />
            :
            (
              <div className="box-tabs">
                <Tabs defaultActiveKey="open">
                  <Tab eventKey="open" title="Open Orders">
                    <div>
                      {(result || []).filter((order) => order.Status === 'Pending').map((order, index) => {
                        return <CardOrder key={index} {...order} />;
                      })}
                    </div>
                  </Tab>
                  <Tab eventKey="past" title="Past Orders">
                    <div>
                      {(result || []).filter((order) => order.Status === 'Processed').map((order, index) => {
                        return <CardOrder key={index} {...order} />;
                      })}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            )
          }


        </main>
      </Layout>
    );
  }
}
const mapState = state => {
  const {
    result,
    inProgress,
    error
  } = state.getAllOrders.toJS();
  return {
    result,
    inProgress,
    error
  }
}

const mapDispatch = dispatch => bindActionCreators({
  getAllOrders: fetchDux.getAllOrders.createAction
}, dispatch)
export default connect(mapState, mapDispatch)(OrdersSearch)
