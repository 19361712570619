import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const NewQuoteForm = props => {
  const { handleSubmit, closeModal } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Modal.Body>

        <p>Please provide an outline of your project request</p>

        <Row>
          <Col>
            <Field
              name="city"
              type="text"
              component={FormField.Input}
              placeholder="City"
              validate={[required]}
            />
          </Col>
          <Col>
            <Field
              name="state"
              type="text"
              component={FormField.SelectReact}
              placeholder="State"
              validate={[required]}
            />
          </Col>
          <Col>
            <Field
              name="targetOpening"
              type="text"
              component={FormField.Input}
              placeholder="Target Opening"
              validate={[required]}
            />
          </Col>

        </Row>

        <Field
          name="comments"
          type="text"
          component={FormField.Textarea}
          placeholder="Additional Comments"
          rows={5}
        />

        <div className="file-info">
          <Field
            name="upload"
            component={FormField.InputFileDropzone}
            instructions="Please upload all pertinent information to ensure an accurate quotation. Including any Equipment Lists, Material Good Specifications, Plans, Revit files etc."
          />
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit Request
        </Button>
      </Modal.Footer>

    </form>
  );
};

export default reduxForm({
  form: 'new-quote-form'
})(NewQuoteForm);
