import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from 'react-google-maps';

const gMapKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.updateBounds = this.updateBounds.bind(this);
  }
  componentDidMount() {
    this.updateBounds(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateBounds(nextProps);
  }
  updateBounds(props) {

    const { geocodes } = props || {};

    var bounds = new window.google.maps.LatLngBounds();
    const deduped = (geocodes || [])
      .filter(({lat,lng})=>lat && lng)
      .reduce((prev, next) => {
        const existing = prev.find(
          ({ lat, lng }) => lat === next.lat && lng === next.lng
        );
        if (existing) return prev;
        return [...prev, { lat: next.lat, lng: next.lng }];
      }, []);

    if((deduped || []).length === 1){
      const{
        lat,
        lng
      } = (deduped || [])[0];
      const circle = new window.google.maps.Circle({
        center: { lat: Number(lat), lng: Number(lng) },
        radius: 8000
      });
      const b = circle.getBounds();

      const neBound = new window.google.maps.LatLng(
        b.getNorthEast().lat(),
        b.getNorthEast().lng()
      );

      const swBound = new window.google.maps.LatLng(
        b.getSouthWest().lat(),
        b.getSouthWest().lng()
      );
      bounds.extend(neBound);
      bounds.extend(swBound);
    }else{
      (deduped || []).forEach(g => bounds.extend(g));
    }

    this.refs.map.fitBounds(bounds);
  }
  render() {

    const { geocodes } = this.props || {};
    const filteredGeocodes = (geocodes || []).filter(({lat,lng})=>lat && lng);
    const defaultCenter = (filteredGeocodes || []).length > 0;
    return (
      <GoogleMap
        defaultZoom={80}
        center={defaultCenter ? {} : { lat: 30.42304674618652, lng: -97.71364425431868 }}
        ref="map"
      >
        {(filteredGeocodes || [])
        .map(({ lat, lng, locationName }, idx) =>{
          return(
            <Marker
              position={{ lat: Number(lat), lng: Number(lng) }}
              key={idx}
              title={`Concept: ${locationName || '-'}`}
            />
          )
        })}
      </GoogleMap>
    );
  }
}

const ConnectedMap = withScriptjs(withGoogleMap(MapComponent));

const MapWrapper = props => (
  <ConnectedMap
    {...props}
    googleMapURL={`//maps.googleapis.com/maps/api/js?key=${gMapKey}&amp;v=3.exp&amp;libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div
        style={{
          height: `${props.containerHeight ? props.containerHeight : 295}px`
        }}
      />
    }
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export default MapWrapper;
