import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import QuotesSearchResults from './QuotesSearchResults';
import NewQuoteModal from './NewQuoteModal';
import fetchDux from '../../../../state/fetch-dux';

class QuotesSearch extends Component {
  componentDidMount() {
    const { getQuotes } = this.props;
    getQuotes();
  }
  render() {
    const { showModal, quotesResult } = this.props;
    const metadata = {
      title: 'Quotes | Concept Services'
    };
    return (
      <Layout route="quotes-search" metadata={metadata} sidebar fluid>
        <main>
          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/quotes">Quotes</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Quotes
              </h2>
            </Col>
            <Col md={5} className="text-right">
              <Button
                variant="primary"
                onClick={() => showModal('newQuoteModal')}
              >
                Request a new quote
              </Button>
            </Col>
          </Row>

          <div className="box box-table">
            <QuotesSearchResults result={quotesResult} />
          </div>

          <NewQuoteModal name="newQuoteModal" />

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    inProgress: quotesLoading,
    result: quotesResult
  } = state.getQuotes.toJS();

  return {
    quotesLoading,
    quotesResult
  }
};
const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators,
    getQuotes: fetchDux.getQuotes.createAction
  },
  dispatch);

export default connect(mapState, mapDispatch)(QuotesSearch);
