import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const getRevisionText = ({Status})=>{
  if(Status === 3){
    return <p>
      Your request will be sent to our team for review.
      As it has been previously accepted certain items may incur additional charges, return freight or re-stocking fees.
      Once they have completed their review they will revise your quote or reach out to you for approval of changes that will cause additional charges.
      <br/>
      <br/>
      If you have any questions you can always reach out to us directly @ 512-343-3100.
    </p>
  }
  
  return <p>
    You can request a revision at no extra cost.
    Your request should clearly explain what you would like to revise.
    <br/>
    <br/>
    If you have any questions you can always reach out to us directly.
  </p>
  
}

const required = value => value ? undefined : 'Required';
const RequestRevisionForm = (props) => {
    const {
        handleSubmit,
        onCancel,
        submitting,
        quoteResult
    } = props;

    const { Status } = quoteResult || {};

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                {getRevisionText({Status})}
                {
                    Status === 3 &&
                    <>
                        <Row>
                            <Col>
                                <Field
                                    name='contactName'
                                    label='Contact Name *'
                                    required
                                    validate={[required]}
                                    component={FormField.Input}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name='contactNumber'
                                    label='Contact Number'
                                    required
                                    // validate={[required]}
                                    component={FormField.Input}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    name='contactEmail'
                                    label='Contact Email *'
                                    required
                                    validate={[required]}
                                    component={FormField.Input}
                                />
                            </Col>
                        </Row>
                    </>

                }
                <Field
                    required
                    name="description"
                    type="text"
                    component={FormField.Textarea}
                    placeholder="Please clearly explain what you would like to revise on the quote..."
                    rows={5}
                    validate={[required]}
                />
                <div className="file-info">
                    <Field
                        name="upload"
                        multiple
                        maximum={3}
                        component={FormField.InputFileDropzone}
                        instructions="Please upload any attachments you would like use to review as part of your question or request"
                    />
                </div>


            </Modal.Body>

            <Modal.Footer>
                <Row style={{ width: '100%' }}>
                    <Col sm='6'>
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                onCancel('RequestRevisionModal')
                            }}
                            disabled={submitting}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col sm='6'>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={submitting}
                        >
                            Submit Request
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>

        </form >
    )
}

export default reduxForm({
    form: 'request-revision-form'
})(RequestRevisionForm);