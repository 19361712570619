import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const SearchInvoicesForm = props => {
  const { handleSubmit } = props;
  return (
    <form className="box-search-shop" onSubmit={handleSubmit}>
      <Field
        name="search"
        type="text"
        component={FormField.Input}
        placeholder="Search Invoices"
      />
    </form>
  );
};

export default reduxForm({
  form: 'search-invoices-form'
})(SearchInvoicesForm);
