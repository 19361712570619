import React, { Component } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Layout from '../../../ui/Layout';
import fetchDux from '../../../../state/fetch-dux';
import Loading from '../../../ui/Loading';

import QuotesSearchResults
  from '../../quotes/quotes-search/QuotesSearchResults';
import DocumentsSearchResults
  from '../../documents/documents-search/DocumentsSearchResults';
import CardOrder from '../../orders/orders-search/CardOrder';
import LocationOverview from './LocationOverview';
import LocationProjectTracker from './LocationProjectTracker';

import SelectLocationsForm from './SelectLocationsForm';

class LocationsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 'overview' };
  }
  componentDidMount() {
    const {
      getLocationById,
      getLocations,
      getUserDetails,
      match
    } = this.props;

    const {
      params
    } = match || {};

    const {
      id
    } = params || {};

    getLocationById(id);
    getUserDetails({ getWebsiteOptions: 1, getConcepts: 1 });
    getLocations();

    const { tab } = queryString.parse(this.props.location.search);
    if (tab && tab.match(/^(overview|project|orders|invoices|quotes|documents)$/)) {
      this.setState({ activeTab: tab });
      this.props.history.replace(`/locations/${id}`)
    }
  }
  componentWillReceiveProps(nextProps) {
    const { match: { params: { id: idNew } } } = nextProps;

    const { match: { params: { id } }, getLocationById } = this.props;

    if (idNew === id) return;

    getLocationById(idNew);

    const { getLocationByIdResult: { progressDisplay } } = this.props;
    if (this.state.activeTab === 'project' && progressDisplay) {
      this.setState({ activeTab: 'overview' });
    }
  }
  render() {
    const {
      getLocationByIdResult,
      getLocationsResult,
      match,
      history,
      inProgress
    } = this.props;

    const {
      params
    } = match || {};

    const {
      id
    } = params || {};

    const {
      locationName,
      quotes
    } = getLocationByIdResult || {};

    const {
      statuses,
      progressDisplay
    } = getLocationByIdResult || {};

    const {
      documents
    } = getLocationByIdResult || {};

    const {
      userLocations
    } = getLocationsResult || {};

    const { activeTab } = this.state;
    const metadata = {
      title: `${locationName || ''} | Locations | Concept Services`
    };
    const setTab = tab => this.setState({ activeTab: tab });

    const handleLocationChange = value => {
      history.push(`/locations/${value}`);
    };

    return (
      <Layout route="locations-details" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={9}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/locations">Locations</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                {locationName || ''}
              </h2>
            </Col>
            <Col md={3} className="text-right">
              <SelectLocationsForm
                userLocations={userLocations}
                initialValues={{ changeLocation: parseInt(id) }}
                handleLocationChange={handleLocationChange}
              />
            </Col>
          </Row>

          {inProgress && <Loading fullPage message="Loading, please wait" />}
          {!inProgress &&
            <div className="box-tabs">
              <Tabs activeKey={activeTab} onSelect={t => setTab(t)}>
                <Tab eventKey="overview" title="Overview">
                  <LocationOverview
                    handleTabChange={setTab}
                    result={getLocationByIdResult}
                  />
                </Tab>
                {!!(statuses || []).length && progressDisplay && <Tab eventKey="project" title="Project Tracker">
                  <LocationProjectTracker
                    result={getLocationByIdResult}
                  />
                </Tab>}
                <Tab eventKey="orders" title="Orders">
                  <div className="tabs-pills">
                    <Tabs defaultActiveKey="open">
                      <Tab eventKey="open" title="Open Orders">
                        <div>
                          {[].map((order, index) => {
                            return <CardOrder key={index} {...order} />;
                          })}
                        </div>
                      </Tab>
                      <Tab eventKey="past" title="Past Orders">
                        <div>
                          {[].map((order, index) => {
                            return <CardOrder key={index} {...order} />;
                          })}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Tab>
                <Tab eventKey="quotes" title="Quotes">
                  <QuotesSearchResults
                    result={quotes}
                  />
                </Tab>
                <Tab eventKey="documents" title="Documents">
                  <DocumentsSearchResults result={documents} />
                </Tab>
              </Tabs>
            </div>}

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: getLocationByIdResult,
    error: getLocationByIdError,
    inProgress: getLocationByIdInProgress
  } = state.getLocationById.toJS();

  const {
    result: getLocationsResult,
    error: getLocationsError,
    inProgress: getLocationsProgress
  } = state.getLocations.toJS();

  return {
    getLocationByIdResult,
    getLocationByIdError,
    getLocationsResult,
    getLocationsError,
    inProgress: getLocationByIdInProgress || getLocationsProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLocationById: fetchDux.getLocationById.createAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      getLocations: fetchDux.getLocations.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(LocationsDetails);
