import React from 'react';
import { Route } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';
import { Link } from 'react-router-dom';
import Icon from './Icon';

const SideNavLink = (
  {
    to,
    exact,
    strict,
    isActive,
    ...rest
  }
) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    children={({ location, match, history }) => {
      const active = !!(isActive
        ? isActive(match, location)
        : match && match.isExact);
      return (
        <li className={active ? 'active' : ''}>
          <Link
            {...rest}
            to={to}
            onClick={e => {
              e.preventDefault();
              history.push(to);
            }}
          />
        </li>
      );
    }}
  />
);

const SideNav = ({ result, showModal }) => {

  const { user, websiteOptions } = result || {};

  const userLinkAccess = webSiteOption =>
    !!(websiteOptions || [])
      .find(({ websiteAccessTypeID }) => websiteAccessTypeID === webSiteOption);

  const {
    firstName,
    lastName,
    email
  } = user || {};

  return (
    <div className="side-nav">
      <nav>
        <ul>
          <li className="mobile user">
            <div>
              <Icon name="user-circle" />
              <span>
                {firstName && lastName
                  ? `${firstName} ${(lastName || '').charAt(0)}.`
                  : email}
              </span>
            </div>
            <div className="cart">
              <a onClick={() => showModal('ShoppingCartModal')}>
                <Icon name="shopping-cart" />
              </a>
            </div>
          </li>
          <li className="mobile">
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => showModal('LogoutModal')}
            >
              Logout
            </Button>
            <hr />
          </li>
          <SideNavLink to="/">
            <Icon fixedWidth name="home" /> Home
          </SideNavLink>
          {userLinkAccess(1) &&
            <SideNavLink to={`/shop`}>
              <Icon fixedWidth name="store-alt" /> Shop
            </SideNavLink>}
          {userLinkAccess(4) &&
            <SideNavLink to="/orders">
              <Icon fixedWidth name="shopping-cart" /> Orders
            </SideNavLink>}
          {userLinkAccess(5) &&
            <SideNavLink to="/locations">
              <Icon fixedWidth name="map-marker-alt" /> Locations
            </SideNavLink>}
          {/* <SideNavLink to="/equipment">
            <Icon fixedWidth name="wrench" /> Equipment
          </SideNavLink> */}
          {/* {userLinkAccess(6) &&
            <SideNavLink to="/invoices">
              <Icon fixedWidth name="file-invoice" /> Invoices
            </SideNavLink>} */}
          {userLinkAccess(2) &&
            <SideNavLink to="/quotes">
              <Icon fixedWidth name="file-invoice-dollar" /> Quotes
            </SideNavLink>}
          {userLinkAccess(3) &&
            <SideNavLink to="/documents">
              <Icon fixedWidth name="file-alt" /> Documents
            </SideNavLink>}
        </ul>
      </nav>
    </div>
  );
};

const mapState = state => {
  const {
    result
  } = state.validateLogin.toJS();
  return { result };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(SideNav);
