import React, { useState } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ProposalSummaryForm from './ProposalSummaryForm';
import QuoteItems from '../quotes-details/QuoteItems';
import QuoteSubmitForm from './QuoteSubmitForm';

const QuoteEditForm = props => {
  const { handleSubmit, result } = props;
  const {
    items,
    TaxToBeDetermined: taxToBeDetermined,
    Deposit: deposit,
    SalesTax: salesTax,
    MaterialGoods: materialGoods,
    quoteSections
  } = result;

  const services = (quoteSections || [])
    .reduce((t, p) => t.concat((p || {}).quoteCategories), [])
    .reduce((t, p) => t.concat((p || {}).quoteItems), [])
    .reduce((t, p) => t + Number((p || {}).Price), 0);

  const subTotal = services + materialGoods;
  const total = { services, materialGoods, subTotal };
  total.deposit = deposit ? subTotal * (deposit / 100) : '';
  total.salesTax = salesTax;
  total.grandTotal = salesTax && !taxToBeDetermined ? subTotal * (1 + salesTax / 100) : subTotal;

  return (
    <form onSubmit={handleSubmit}>
      <h3>Proposal Summary</h3>
      <ProposalSummaryForm
        proposal={result}
        total={total}
        taxToBeDetermined={taxToBeDetermined}
      />
      <h3>Material Goods</h3>
      <QuoteItems items={items} />
      <QuoteSubmitForm />
    </form>
  );
};

const formName = 'quote-details-form';
const selector = formValueSelector(formName);

export default reduxForm({
  form: formName
})(QuoteEditForm);


