import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';

import SecureRoute from './ui/SecureRoute';
import Homepage from './routes/homepage/Homepage';
import Shop from './routes/shop/Shop';
import Checkout from './routes/checkout/Checkout';
import Orders from './routes/orders/Orders';
import Quotes from './routes/quotes/Quotes';
import QuoteAgreement from './routes/quote-agreement/QuoteAgreement';
import Invoices from './routes/invoices/Invoices';
import Documents from './routes/documents/Documents';
import Locations from './routes/locations/Locations';
import Login from './routes/login/Login';
import CreatePassword from './routes/create-password/CreatePassword';
import ResetPassword from './routes/reset-password/ResetPassword';
import PurchaseAgreement from './routes/purchase-agreement/PurchaseAgreement';
import WarehouseAgreement
  from './routes/warehouse-agreement/WarehouseAgreement';
import { LastLocationProvider } from 'react-router-last-location';
import Warehouses from './routes/warehouses/Warehouses';

class App extends Component {
  componentDidMount() {
    this.props.validateLogin();
  }
  render() {
    return (
      <Router>
        <LastLocationProvider>
          <Switch>
            <SecureRoute path="/" component={Homepage} exact />
            <SecureRoute path="/shop" component={Shop} />
            <SecureRoute path="/checkout" component={Checkout} />
            <SecureRoute path="/orders" component={Orders} />
            <SecureRoute path="/quotes" component={Quotes} />
            {/* <SecureRoute path="/invoices" component={Invoices} /> */}
            <SecureRoute path="/documents" component={Documents} />
            <SecureRoute path="/locations" component={Locations} />
            <SecureRoute
              path="/purchase-agreement"
              component={PurchaseAgreement}
            />
            <Route path="/warehouse-agreement" component={WarehouseAgreement} />
            <Route path="/quote-agreement" component={QuoteAgreement} />
            <Route path="/create-password" component={CreatePassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/warehouse-info" component={Warehouses} />
            <Route path="/login" component={Login} />
          </Switch>
        </LastLocationProvider>
      </Router>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateLogin: fetchDux.validateLogin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
