import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import FormField from '../forms/FormField';
import Icon from './Icon';
import Loading from './Loading';


const ShareCartForm = (
    {
        companyUsers,
        selectedUsers,
        handleFormFieldChange,
        submitting,
        shareToSelectedUsers,
        shareCartResult
    }
) => {
    const companyUserOptions = (companyUsers || [])
        .filter(
            ({ ID }) => {
                const selectedUser = (selectedUsers || []).find(({ ID: userId }) => userId === ID);
                if (selectedUser) return false;
                return true;
            }
        )
        .map((user) => {
            const { ID, NameFirst, NameLast } = user;
            return {
                label: `${NameFirst} ${NameLast}`,
                value: ID
            }
        })
    return (
        <form>
            <Row style={{ justifyContent: 'center' }}>
                <Col sm={12}>
                    <Field
                        component={FormField.SelectReact}
                        name='selectedUser'
                        placeholder='Share to specific users from this concept...'
                        selectStyles={{
                            placeholder: (provided) => {
                                return {
                                    ...provided,
                                    fontSize: '0.75rem'
                                }
                            }
                        }}
                        options={companyUserOptions}
                        onChange={
                            (value) => {
                                const user = companyUsers.find(({ ID }) => ID === value)
                                const selected = [
                                    ...selectedUsers,
                                    user
                                ]
                                handleFormFieldChange('share-cart-form', 'selectedUsers', selected);
                            }
                        }
                    />
                </Col>
                <Col sm={12}>
                    <div className='pill-group'>
                        {
                            selectedUsers.map((user, id) => {
                                const { NameFirst, NameLast, ID: userId } = user;
                                return (
                                    <div key={id} className="pill">
                                        {NameFirst} {NameLast}
                                        <Icon
                                            name="times"
                                            onClick={() => {
                                                handleFormFieldChange(
                                                    'share-cart-form',
                                                    'selectedUsers',
                                                    selectedUsers.filter(({ ID }) => ID !== userId)
                                                );
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>

                <Col sm={6}>
                    <Button
                        size='sm'
                        disabled={selectedUsers.length === 0}
                        onClick={
                            () => {
                                if (!submitting) shareToSelectedUsers(selectedUsers)
                            }
                        }
                    >
                        {submitting &&
                            <Loading
                                color='#FFFFFF'
                                opacity={1}
                                small
                            />
                        }
                        {!submitting && !shareCartResult && 'Share'}
                        {
                            shareCartResult &&
                            <>
                                <Icon
                                    name='check'
                                    style={{ marginRight: '0.2rem' }}
                                />
                                Shared
                            </>
                        }
                    </Button>
                </Col>
            </Row>
        </form>
    )
}


const selector = formValueSelector('share-cart-form');

const mapState = state => {
    const selectedUsers = selector(state, 'selectedUsers');
    return {
        selectedUsers: selectedUsers || []
    }
}

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            handleFormFieldChange: change
        }
        , dispatch)

const form = reduxForm({
    form: 'share-cart-form'
})(ShareCartForm);

export default connect(mapState, mapDispatch)(form);