import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const QuoteSubmitForm = props => {
  const { description } = props || {};
  const [isRevision, toggleRevision] = useState(false);
  const [isAccept, toggleAccept] = useState(false);
  return (
    <div className="form-sticky-actions">
      {!isRevision &&
        !isAccept &&
        <Row className="actions">
          <Col>
            <Button
              variant="outline-primary"
              onClick={() => toggleRevision(true)}
            >
              <Icon name="sync" /> Request Revision
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={() => toggleAccept(true)}>
              <Icon name="thumbs-up" /> Accept Quote
            </Button>
          </Col>
        </Row>}
      {isRevision &&
        <Row className="revision">
          <Col>
            <Field
              name="description"
              type="text"
              component={FormField.Textarea}
              placeholder="Please clearly explain what you would like to revise on the quote..."
              rows={5}
            />
          </Col>
          <Col>
            <h5>Request Revision</h5>
            <p>
              You can request a revision at no extra cost. Your request should clearly explain what you would like to revise. If you have any questions you can always reach out to us directly.
            </p>
            <div className="text-right">
              <Button variant="link" onClick={() => toggleRevision(false)}>
                Cancel
              </Button>
              <Button variant="primary">
                Submit Request
              </Button>
            </div>
          </Col>
        </Row>}
      {isAccept &&
        <Row className="accept">
          <Col>
            <Field
              name="fullName"
              type="text"
              component={FormField.Input}
              placeholder="Full Name"
              validate={[required]}
            />
            <Field
              name="email"
              type="email"
              component={FormField.Input}
              placeholder="Email Address"
              validate={[required]}
            />
          </Col>
          <Col>
            <h5>Accept Quote</h5>
            <Field
              name="acceptTerms"
              type="checkbox"
              component={FormField.InlineCheckbox}
              label="I have read and accept the Concept Services Terms and Conditions for this quote"
            />
            <div className="text-right">
              <Button variant="link" onClick={() => toggleAccept(false)}>
                Cancel
              </Button>
              <Button variant="primary">
                Sign With Docusign
              </Button>
            </div>
          </Col>
        </Row>}
    </div>
  );
};

export default QuoteSubmitForm;
