import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';

import CheckoutReviewForm from './CheckoutReviewForm';
import CheckoutCart from '../CheckoutCart';
import { getShop } from '../../../../utils/shop-utils';
import { connect } from 'react-redux';

import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';
import { actionCreators as billingActions } from '../../../../state/billing-dux';


class CheckoutReview extends Component {
  componentDidMount() {
    const {
      getCart,
      user,
      billing,
      history
    } = this.props;

    if (!billing) return history.push('/checkout/payment')

    const shop = getShop(user || {})
    getCart({ companyId: shop })
  }
  render() {
    const {
      cart,
      billing,
      postCartPayment,
      history,
      cartItemsLoading,
      postCartPaymentLoading,
      postCartPaymentFailed,
      clearBilling
    } = this.props;
    const metadata = {
      title: 'Review | Checkout | Concept Services'
    };

    const { paymentOption } = billing || {};

    const { message } = postCartPaymentFailed || {};

    const {
      shoppingCartId
    } = cart || {};

    const handleSubmit = values => {
      const { upload } = values;
      postCartPayment(
        {
          payload: { ...billing, upload },
          shoppingCartId
        },
        'checkout-review-form',
        null,
        (result) => {
          if (result) {
            clearBilling();
            history.push('/checkout/complete');
          }
        }
      )

    }

    const isLoading = cartItemsLoading || postCartPaymentLoading;

    return (
      <Layout route="checkout-review" metadata={metadata} fluid>
        <main>

          <Row noGutters>
            <Col md={7} lg={8}>

              <div className="checkout-body">

                <div className="steps">
                  <ol>
                    <li>
                      <span>Shipping</span>
                    </li>
                    <li>
                      <span>Payment</span>
                    </li>
                    <li className="active">
                      <span>Review</span>
                    </li>
                  </ol>
                </div>

                {isLoading && (
                  <Loading />
                )}
                <span style={isLoading ? { display: 'none' } : {}}>
                  <CheckoutReviewForm
                    cart={cart}
                    billing={billing}
                    onSubmit={handleSubmit}
                    postCartPaymentLoading={postCartPaymentLoading}
                  />
                </span>
              </div>

            </Col>
            <Col md={5} lg={4}>
              {!cartItemsLoading && !postCartPaymentLoading && <CheckoutCart selectedPaymentOption={paymentOption} />}
            </Col>
          </Row>

          {
            postCartPaymentFailed &&
            (
              <Notification
                key="process-payment-fail"
                duration={3}
                closable={true}
                type="danger"
              >
                {message ? message : 'Failed to process payment. Please try again'}
              </Notification>
            )
          }

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const { billing } = state || {}
  const {
    result: user
  } = state.validateLogin.toJS();

  const {
    inProgress: cartItemsLoading,
    result: cart
  } = state.getCart.toJS();

  const {
    inProgress: postCartPaymentLoading,
    error: postCartPaymentFailed
  } = state.postCartPayment.toJS();

  return {
    cartItemsLoading,
    cart,
    user,
    billing,
    postCartPaymentLoading,
    postCartPaymentFailed
  }
};

const mapDispatch = dispatch => bindActionCreators({
  ...billingActions,
  getCart: fetchDux.getCart.createAction,
  postCartPayment: fetchDux.postCartPayment.createAction
}, dispatch);

export default connect(mapState, mapDispatch)(CheckoutReview);
