import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import numeral from 'numeral';

const CardProduct = ({ model, category, imageHref, sellPrice, id, shop, handleAddToCart, postCartInProgress }) => (
  <div className="box-product">
    <Link to={`/shop/products?productId=${id}&shop=${shop}`}>
      <div className="image" style={{ backgroundImage: `url(${imageHref})` }} />
      <h4>{model}</h4>
      <div className="category">{category.categoryName}</div>
      <div className="price">
        <sup>$</sup>{numeral(sellPrice).format('0,0.00')}
      </div>
    </Link>
    <Button onClick={()=>handleAddToCart({qty:1, productId:id})} disabled={postCartInProgress}><Icon name="shopping-cart"/> Add To Cart</Button>
  </div>
);

export default CardProduct;
