import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import WarehouseTOS from './WarehouseTOS';
import Layout from '../../ui/Layout';
import queryString from 'query-string';

const labelStyle = {
  fontWeight: 'bold',
  paddingTop: '15px',
  paddingBottom: '5px'
};

class WarehouseAgreement extends Component {
  render() {
    const{
      location: { search }
    } = this.props;        
    
    const query = queryString.parse(search);
    const { jobCode } = query || {};
    return (
      <Layout>
        <Container>
          <Row className="justify-content-sm-center align-items-center">
            <Col sm md={12}>
              <div style={{ padding: '2rem 0' }}>
                <WarehouseTOS readOnly jobCode={jobCode} />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default WarehouseAgreement;
