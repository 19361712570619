import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';

const SelectLocationsForm = props => {
  const { handleSubmit, companyLocations, handleLocationChange } = props;
  const options = (companyLocations || []).filter(location => location.progressDisplay).map(({ locationName, locationId }) => {
    return {
      value: locationId,
      label: locationName
    };
  })
  return (
    <form className="box-search-shop" onSubmit={handleSubmit}>
      <div className="change-shop">
        <Field
          name="changeLocation"
          type="text"
          component={FormField.SelectReact}
          label="Change Location"
          options={options}
          onChange={handleLocationChange}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'select-locations-form'
})(SelectLocationsForm);
