import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';


const required = value => value ? undefined : 'Required';
const OrderHelpForm = props => {
  const { handleSubmit, closeModal, supportRequestInProgress } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Modal.Body>
        <Field
          name="description"
          type="text"
          component={FormField.Textarea}
          label="Please provide a description of your request"
          rows={5}
          required
          validate={[required]}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={supportRequestInProgress}
        >
          Submit Request
        </Button>
      </Modal.Footer>

    </form>
  );
};

export default reduxForm({
  form: 'request-order-help-form'
})(OrderHelpForm);
