import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import classNames from 'classnames';
import moment from 'moment';

const LocationProjectTracker = ({ result }) => {
  const {
    statuses,
    progressDisplay,
  } = result || {};

  const completedStatuses = (statuses || [])
    .filter(({ status }) => status === 'Complete');
  const percentComplete = ((statuses || []).length
    ? (completedStatuses || []).length / (statuses || []).length
    : 0) * 100;

    return (
      <div className="box-location-overview">
      <Row>
      {!!(statuses || []).length &&
        progressDisplay &&
        <Col md={12}>
          <div>
            <div className="tracker-info">
              {(completedStatuses || []).length}
              {' '}
              of
              {' '}
              {(statuses || []).length}
              {' '}
              Steps Completed
            </div>

            <ProgressBar now={percentComplete} />
            <hr />
            <div className="steps">
              {(statuses || []).map(({
                status,
                title,
                phase,
                dateComplete,
                dateEst,
                comment
              }, index) => {
                return (
                  <div
                    key={index}
                    className={classNames('step', {
                      completed: status === 'Complete',
                      'in-progress': status === 'In Progress',
                      'in-jeopardy': status === 'In Jeopardy'
                    })}
                  >
                    <div className="icon">
                      {status === 'Complete' && <Icon name="check" />}
                      {status === 'In Progress' && <Icon name="hourglass" />}
                      {status === 'In Jeopardy' && <Icon name="exclamation" />}
                      {status !== 'Complete' &&
                        status !== 'In Progress' &&
                        status !== 'In Jeopardy' &&
                        <span>{index + 1}</span>}
                    </div>
                    <div className="detail">
                      <p>{title}</p>
                      {status === 'Complete' &&
                        <>
                        {dateEst &&
                          <div className="date">
                            Complete - {dateComplete ? moment(dateComplete).format('MMM d, Y') : ''}
                          </div>
                        }
                        <div className="comment">{comment}</div>
                        </>
                      }
                      {status !== 'Complete' &&
                        <>
                          {dateEst &&
                            <div className="date">
                              Estimated - {moment(dateEst).format('MMM d, Y')} 
                            </div>
                          }
                          <div className="comment">{comment}</div>
                        </>
                      }
                      {/* {status !== 'Complete' && !dateEst && <br />} */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>}
      </Row>
      </div>
    );
}

export default LocationProjectTracker;
