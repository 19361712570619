import {
  getJson,
  postJson,
  patchJson,
  del,
  getFile,
  postFile,
  putJson
} from './fetch';

import localForage from 'localforage';
import download from "downloadjs";

const apiBase = process.env.REACT_APP_API_BASE;

//identity
export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    const { userStorage } = result || {};
    const { token } = userStorage || {};
    localForage.setItem('identity', result);
    localStorage.setItem('token', token);
    return result;
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });

export const validateToken = (token) =>
  getJson({
    path: `${apiBase}/identity/validateToken/${token}`,
  });

export const validateTokenAndSavePassword = ({ userId, payload }) =>
  postJson({
    path: `${apiBase}/identity/${userId}/change-password`,
    payload
  });

//shop
export const getShopHome = (companyId) =>
  getJson({
    path: `${apiBase}/shop?companyId=${companyId}`,
  });
export const getShopCategory = ({ companyId, category }) =>
  getJson({
    path: `${apiBase}/shop/category?companyId=${companyId}&category=${category}`,
  });
export const getProductById = ({ companyId, productId }) =>
  getJson({
    path: `${apiBase}/shop/product?companyId=${companyId}&productId=${productId}`,
  });
export const getPackageById = ({ companyId, packageId }) =>
  getJson({
    path: `${apiBase}/shop/package?companyId=${companyId}&packageId=${packageId}`,
  });
export const getCompanyProducts = ({ companyId, search }) =>
  getJson({
    path: `${apiBase}/shop/products?search=${search}&companyId=${companyId}`,
  });

//locations
export const getLocations = (id) =>
  getJson({
    path: `${apiBase}/locations`,
  });
export const getLocationById = (id) =>
  getJson({
    path: `${apiBase}/locations/location-detail?locationId=${id}`,
  });
export const getLocationsByCompany = ({ companyId }) =>
  getJson({
    path: `${apiBase}/locations/${companyId}`,
  });

//users
export const getUserDetails = query => {
  const include = Object.keys(query).join(',');
  return getJson({
    path: `${apiBase}/users?include=${include}`
  });
};

//cart
export const getCart = ({ id, companyId, c }) => {
  return getJson({
    path: `${apiBase}/cart/${id}?companyId=${companyId}&c=${c}`
  });
};

export const getDocuments = (qs) => {
  return getJson({
    path: `${apiBase}/documents/${qs || ''}`
  });
};
export const downloadDocuments = ({ payload }) => {
  getFile({
    path: `${apiBase}/documents/downloadDocument`,
    payload
  }).then(function (response) {
    return response.blob();
  }).then(function (blob) {
    download(blob, payload.Filename);
  })
};
export const postCart = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/cart/${id}`,
    payload
  });

export const postPackage = ({ packageId, payload, id }) =>
  postJson({
    path: `${apiBase}/cart/${id}/package/${packageId}`,
    payload
  });

export const deleteCartItem = ({ shoppingCartItemId, id }) =>
  del({
    path: `${apiBase}/cart/${id}/items/${shoppingCartItemId}`
  });

export const saveShippingAddress = ({ address, shoppingCartId, orderLocation }) =>
  postJson({
    path: `${apiBase}/cart/${shoppingCartId}/${orderLocation}/shipping`,
    payload: address
  });

export const saveBillingAddress = ({ address, shoppingCartId }) =>
  postJson({
    path: `${apiBase}/cart/${shoppingCartId}/billing`,
    payload: address
  })

export const postCartPayment = ({ payload, shoppingCartId }) =>
  postFile({
    path: `${apiBase}/cart/${shoppingCartId}/payment`,
    payload
  })

export const submitCartApproval = ({ shoppingCartId, approverId }) =>
  postJson({
    path: `${apiBase}/cart/${shoppingCartId}/approval`,
    payload: { approverId }
  })

export const getApprovalCart = ({ shoppingCartApprovalId }) =>
  getJson({
    path: `${apiBase}/cart/${shoppingCartApprovalId}/approval`
  })


export const getQuotes = () =>
  getJson({
    path: `${apiBase}/quotes`
  })

export const getQuoteById = ({ id }) =>
  getJson({
    path: `${apiBase}/quotes/${id}`
  })


export const getOrderById = ({ projectId }) =>
  getJson({
    path: `${apiBase}/orders/${projectId}`
  })

export const downloadQuoteById = ({ id }) =>
  getFile({
    path: `${apiBase}/quotes/download/${id}`,
  }).then(function (response) {
    return response.blob();
  }).then(function (blob) {
    download(blob, `quote${id}.pdf`);
  })


export const acceptQuoteById = ({ quoteId, dataUrl, fullName }) =>
  postJson({
    path: `${apiBase}/quotes/accept/${quoteId}`,
    payload: { dataUrl, fullName }
  })


export const getAllOrders = () =>
  getJson({
    path: `${apiBase}/orders`
  })

export const postOrderSupport = ({ projectId, payload }) => {
  return postJson({
    path: `${apiBase}/orders/${projectId}/support`,
    payload
  })
}

export const getShareCartToken = ({ shoppingCartId }) => {
  return getJson({
    path: `${apiBase}/cart/${shoppingCartId}/share`
  })
}

export const postHelp = ({ payload }) => {
  return postFile({
    path: `${apiBase}/general/help`,
    payload
  })
}

export const requestQuoteRevision = ({ id, ...rest }) => {
  return postFile({
    path: `${apiBase}/quotes/revision/${id}`,
    payload: rest
  })
}


export const getWarehouseTokenData = (token) => {
  return getJson({
    path: `${apiBase}/warehouses/token/${token}`,
    authToken: token
  })
}

export const getWarehouseById = ({ id, token }) => {
  return getJson({
    path: `${apiBase}/warehouses/${id}`,
    authToken: token
  })
}

export const updateWarehouseById = ({ id, token, ...rest }) => {
  return putJson({
    path: `${apiBase}/warehouses/${id}`,
    payload: { token, ...rest },
    authToken: token
  })
}

export const applyCartCoupon = ({ shoppingCartId, Code, companyId }) => {
  return getJson({
    path: `${apiBase}/cart/apply-coupon/${shoppingCartId}/${Code}`,
    payload: { companyId }
  })
}

export const removeCoupon = ({ shoppingCartId, Code, companyId }) => {
  return getJson({
    path: `${apiBase}/cart/remove-coupon/${shoppingCartId}/${Code}`,
    payload: { companyId }
  })
}

export const updateQuoteStatus = ({ id, status }) => {
  return putJson({
    path: `${apiBase}/quotes/status/${id}`,
    payload: { status }
  })
}

export const getCompanyUsers = (shopId) => {
  return getJson({
    path: `${apiBase}/shop/users/${shopId}`
  })
}

export const shareCart = ({ shoppingCartId, users, shareToken }) => {
  return postJson({
    path: `${apiBase}/cart/share-to-users/${shoppingCartId}`,
    payload: { users, shareToken }
  })
}

export const updateQuoteLineItem = ({ Id, quoteId, ...rest }) => {
  return putJson({
    path: `${apiBase}/quotes/line-item/${Id}/${quoteId}`,
    payload: rest
  })
}