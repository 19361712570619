import React, { Component } from 'react';
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Notification from '../ui/Notification';
import Loading from '../ui/Loading';
import { actionCreators } from '../../state/modals-dux';
import fetchDux from '../../state/fetch-dux';
import numeral from 'numeral';
import Icon from './Icon';
import { Link, withRouter } from 'react-router-dom';
import ShareCartForm from './ShareCartForm';
import { getShop } from '../../utils/shop-utils';


// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

const REACT_APP_SHOP_LINK = process.env.REACT_APP_SHOP_LINK;

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // noop
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text, next) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    if (next) next();
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    if (next) next();
  }, function (err) {
    if (next) next(err);
  });
}


class ModalShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: null,
      showShareMenu: false,
      copied: false
    };
  }
  componentWillMount() {
    const {
      getCart,
      result: cartResult
    } = this.props;

    if (cartResult) {
      const {
        companyId,
        shoppingCartId
      } = cartResult || {};

      getCart({ id: shoppingCartId, companyId }, null, false, data => {
        const {
          shoppingCartItems
        } = data || {};
        this.setState({ cartItems: shoppingCartItems });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      result: cartResult
    } = nextProps || {};

    const {
      shoppingCartItems
    } = cartResult || {};

    this.setState({ cartItems: shoppingCartItems });
  }

  componentDidMount() {
    const {
      user,
      getCompanyUsers
    } = this.props;
    const shop = getShop(user || {});
    getCompanyUsers(shop);
  }

  render() {
    const {
      openModal,
      closeModal,
      handleSubmit,
      result: cartResult,
      inProgress,
      error,
      deleteCartItem,
      postCart,
      postCartError,
      clearPostCart,
      getCartError,
      clearGetCart,
      deleteCartItemError,
      clearDeleteCartItem,
      history,
      user,
      submitCartApproval,
      getShareCartToken,
      getShareCartTokenResult,
      getShareCartTokenError,
      getShareCartTokenInProgress,
      companyUsers,
      shareCart,
      shareCartResult
    } = this.props;

    const { userPurchaseLimits = [], user: userDetails } = user || {};
    const purchaseLimit = userPurchaseLimits[0] || {};
    const { spendingLimit, approverId } = purchaseLimit;
    const { roleTypeId } = userDetails || {};

    

    const {
      cartItems,
      showShareMenu,
      copied
    } = this.state || {};

    const {
      token: shareToken
    } = getShareCartTokenResult || {};

    const {
      shoppingCartItems,
      subTotal,
      shoppingCartId,
      companyName
    } = cartResult || {};

    const reachedSpendingLimit = subTotal > spendingLimit;

    const handleDeleteCartItem = shoppingCartItemId => {
      deleteCartItem(
        { shoppingCartItemId, id: shoppingCartId },
        null,
        false,
        data => {
          const {
            shoppingCartItems
          } = data || {};
          this.setState({ cartItems: shoppingCartItems });
        }
      );
    };

    const handlePlusMinus = (add, qty, productId, companyId) => {
      const newQty = add ? qty + 1 : qty - 1;
      if (newQty < 1) return;

      const payload = {
        productId,
        qty: newQty,
        companyId
      };

      postCart(
        { payload, id: shoppingCartId },
        'add-to-cart-form',
        false,
        data => {
          const {
            shoppingCartItems
          } = data || {};
          this.setState({ cartItems: shoppingCartItems });
        }
      );
    };

    const handleChange = (value, productId) => {
      const stateProductIndex = (cartItems || [])
        .findIndex(
          ({ productId: arrayProductId }) => arrayProductId === productId
        );

      const newState = cartItems;
      if ((value < 1 || isNaN(value)) && value) return;

      ((newState || [])[stateProductIndex] || {}).qty = value;

      this.setState({ cartItems: newState });
    };

    const handleBlur = (value, productId, companyId) => {
      if (isNaN(value)) return;
      if (value < 1 || !value) return;

      const payload = {
        productId,
        qty: value,
        companyId
      };

      postCart(
        { payload, id: shoppingCartId },
        'add-to-cart-form',
        false,
        data => {
          const {
            shoppingCartItems
          } = data || {};
          this.setState({ cartItems: shoppingCartItems });
        }
      );
    };

    const handleCheckout = () => {
      history.push('/checkout/shipping/');
      closeModal();
    };

    const handleCartApproval = () => {
      submitCartApproval({ shoppingCartId, approverId }, null, null, () => {
        closeModal();
      });
    };

    const handleToggleShare = () => {
      if (showShareMenu === true) {
        this.setState({ showShareMenu: false, copied: false });
        return;
      }
      this.setState({ showShareMenu: true, copied: false })
      getShareCartToken({ shoppingCartId })
    }

    const handleCopyLink = () => {
      copyTextToClipboard(`${REACT_APP_SHOP_LINK}/shop?c=${shareToken}`, () => {
        this.setState({ copied: true });
      })
    }

    return (
      <Modal
        show={openModal === 'ShoppingCartModal'}
        onHide={closeModal}
        className="modal-side modal-shopping-cart"
      >
        <Modal.Header closeButton>
          <Modal.Title>Your Shopping Cart</Modal.Title>
        </Modal.Header>
        {companyName &&
          <div className="cart-concept">
            <strong>Concept:</strong> {companyName}
          </div>}
        <Modal.Body>

          {(cartItems || []).map(({
            productId,
            model,
            category,
            sellPrice,
            imageHref,
            qty,
            shoppingCartItemId,
            companyId
          }, index) => {
            const { categoryName } = category || {};
            return (
              <div key={index} className="product">
                <div
                  className="image"
                  style={{ backgroundImage: `url(${imageHref})` }}
                />
                <div className="detail">
                  <div>
                    <strong>{model || ''}</strong>
                    <p>{categoryName || ''}</p>
                  </div>
                  <div className="qty">
                    <Icon
                      name="minus"
                      onClick={() =>
                        handlePlusMinus(false, qty, productId, companyId)}
                      disabled={inProgress || parseInt(qty) === 1}
                      style={{
                        color: inProgress || parseInt(qty) === 1
                          ? 'lightgray'
                          : '#5c5f62',
                        cursor: inProgress || parseInt(qty) === 1
                          ? 'not-allowed'
                          : 'pointer'
                      }}
                    />
                    <input //TODO Need to format red invalid
                      type="text"
                      value={qty}
                      disabled={inProgress}
                      onChange={e => handleChange(e.target.value, productId)}
                      onBlur={e =>
                        handleBlur(e.target.value, productId, companyId)}
                    />
                    <Icon
                      name="plus"
                      onClick={() =>
                        handlePlusMinus(true, qty, productId, companyId)}
                      disabled={inProgress}
                      style={{
                        color: inProgress ? 'lightgray' : '#5c5f62',
                        cursor: inProgress ? 'not-allowed' : 'pointer'
                      }}
                    />
                  </div>
                </div>
                <div>
                  ${numeral(sellPrice).format('0,0.00')}
                  {!inProgress &&
                    <Button
                      variant="link"
                      onClick={() => handleDeleteCartItem(shoppingCartItemId)}
                    >
                      Remove
                    </Button>}
                  {inProgress &&
                    <div style={{ fontSize: 'x-small' }}>
                      Updating Cart...
                    </div>}
                </div>
              </div>
            );
          })}

          <div className="subtotal">
            <strong>Subtotal</strong>
            <div>${numeral(subTotal).format('0,0.00')}</div>
          </div>

          {reachedSpendingLimit &&
            <div className="spending-lmit-alert">
              <strong>Spending Limit Alert</strong>
              <p>
                Your cart exceeds your approved spending limit and requires approval. Please submit for approval to proceed with this order.
              </p>
            </div>}

          {reachedSpendingLimit &&
            <Button
              variant="primary"
              disabled={inProgress}
              onClick={handleCartApproval}
            >
              Submit Cart For Approval
            </Button>}
          {!reachedSpendingLimit &&
            <Button
              variant="primary"
              disabled={inProgress}
              onClick={handleCheckout}
            >
              <Icon name="shopping-cart" /> Checkout
            </Button>}

          <Dropdown show={showShareMenu}>
            <Dropdown.Toggle
              variant="outline-primary"
              onClick={handleToggleShare}
              disabled={getShareCartTokenInProgress}
            >
              Share Cart

            </Dropdown.Toggle>
            <Dropdown.Menu
              className="share-cart"
              autoClose={false}
            >
              {getShareCartTokenInProgress && (
                <Dropdown.Item>
                  <Loading style={{ width: '30px', height: '30px' }} message="Generating Link" inline />
                </Dropdown.Item>
              )}
              {!getShareCartTokenInProgress && roleTypeId === 2 &&
                (
                  <Dropdown.Item>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                    >
                      <span onClick={handleCopyLink}>
                        <Icon name="link" /> Copy link to share this cart
                      </span>
                      <label style={{ color: '#cccccc', textDecoration: 'underline' }}>
                        (or)
                      </label>
                      <div style={{ width: '100%' }}>
                        <ShareCartForm
                          companyUsers={companyUsers}
                          shareToSelectedUsers={
                            (selectedUsers) => {
                              shareCart({ shoppingCartId, users: selectedUsers, shareToken }, 'share-cart-form');
                            }
                          }
                          shareCartResult={shareCartResult}
                        />
                      </div>

                      {copied && <div style={{ marginTop: '1rem' }}>Link Copied!</div>}
                    </div>
                  </Dropdown.Item>
                )
              }
              {
                !getShareCartTokenInProgress && roleTypeId === 1 &&
                (
                  <Dropdown.Item onClick={handleCopyLink} >
                    <Icon name="link" /> Copy link to share this cart
                    {copied && <div>Link Copied!</div>}
                  </Dropdown.Item>
                )
              }

              {/* <Dropdown.Item>
                <Icon name="envelope" /> Create an email to share this cart
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>

        </Modal.Body>
        {postCartError &&
          <Notification
            key="fail-update-cart"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostCart}
          >
            Failed to update cart. Please try again.
          </Notification>}
        {getCartError &&
          <Notification
            key="fail-get-cart"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCart}
          >
            Failed to load cart. Please refresh the page.
          </Notification>}
        {deleteCartItemError &&
          <Notification
            key="fail-delete-cart-items"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearDeleteCartItem}
          >
            Failed to delete cart item. Please try again.
          </Notification>}
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCartInProgress,
    error: getCartError
  } = state.getCart.toJS();

  const {
    inProgress: deleteCartItemInProgress,
    error: deleteCartItemError
  } = state.deleteCartItem.toJS();

  const {
    result: postCartResult,
    error: postCartError,
    inProgress: postCartInProgress
  } = state.postCart.toJS();

  const {
    result: getShareCartTokenResult,
    error: getShareCartTokenError,
    inProgress: getShareCartTokenInProgress
  } = state.getShareCartToken.toJS();

  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: companyUsers
  } = state.getCompanyUsers.toJS();

  const {
    result: shareCartResult
  } = state.shareCart.toJS();

  return {
    result,
    getShareCartTokenResult,
    getShareCartTokenError,
    getShareCartTokenInProgress,
    inProgress: deleteCartItemInProgress ||
      getCartInProgress ||
      postCartInProgress,
    getCartError,
    postCartError,
    deleteCartItemError,
    user,
    openModal: state.modals,
    companyUsers,
    shareCartResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getCart: fetchDux.getCart.createAction,
      clearGetCart: fetchDux.getCart.clearAction,
      deleteCartItem: fetchDux.deleteCartItem.createAction,
      clearDeleteCartItem: fetchDux.deleteCartItem.clearAction,
      postCart: fetchDux.postCart.createAction,
      clearPostCart: fetchDux.postCart.clearAction,
      submitCartApproval: fetchDux.submitCartApproval.createAction,
      getShareCartToken: fetchDux.getShareCartToken.createAction,
      getCompanyUsers: fetchDux.getCompanyUsers.createAction,
      shareCart: fetchDux.shareCart.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withRouter(ModalShoppingCart));
