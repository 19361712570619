import React from 'react';
import Table from '../../../ui/Table';
import TablePagination from '../../../ui/TablePagination';
import { Link } from 'react-router-dom';

const columns = [
  {
    Header: 'Location',
    accessor: 'locationName',
    Cell: ({ value, original }) => (
      <Link to={`/locations/${original.locationId}`}>{value}</Link>
    )
  },
  {
    Header: 'Address',
    accessor: 'address1',
    Cell: ({ original }) => {
      const{
        address1,
        address2
      } = original || {};
      return(
        <div>{address1 || ''} {address2 || ''}</div>
      )
    }
  },
  {
    Header: 'City',
    accessor: 'city'
  },
  {
    Header: 'State',
    accessor: 'stateProvince'
  },
  {
    Header: 'Zip',
    accessor: 'postalCode'
  },
  {
    Header: 'Concept',
    accessor: 'companyName'
  }
];

const LocationsSearchResults = (
  {
    error,
    result,
    search
  }
) => {
  return (
    <Table
      data={result || []}
      columns={columns}
      noDataText="No locations found"
      PaginationComponent={TablePagination}
      search={search}
      onSearchChange
    />
  );
};

export default LocationsSearchResults;
