import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';
import { Link } from 'react-router-dom';

import Icon from './Icon';
import NavbarBrandLink from './NavbarBrandLink';
import ModalShoppingCart from './ModalShoppingCart';
import LogoutModal from '../routes/login/LogoutModal';

const TopNav = (
  { user, showModal, isNavOpen, toggleMobileNav, cart, hideMenuOptions }
) => {
  const { totalItemsInCart } = cart || {};
  const hasItemsInCart = totalItemsInCart > 0;
  const { user: account, websiteOptions } = user || {};
  const checkWebAccess = (websiteOptions, webAccessId) => {
    const result = !!(websiteOptions || [])
      .find(({ websiteAccessTypeID }) => websiteAccessTypeID === webAccessId);
    return result;
  };
  return (
    <Navbar bg="white" expand="md" fixed="top">
      <LogoutModal />
      <ModalShoppingCart />
      <NavbarBrandLink to="/">Concept Services</NavbarBrandLink>
      <button
        type="button"
        className="navbar-toggler"
        onClick={() => toggleMobileNav(!isNavOpen)}
      >
        {isNavOpen && <span className="fa fa-fw fa-times" />}
        {!isNavOpen && <span className="fa fa-fw fa-bars" />}
      </button>
      <Navbar.Collapse id="navbar-nav">
        {user &&
          !hideMenuOptions &&
          <Nav>
            <Navbar.Text className="user">
              <div>
                <Icon name="user-circle" />
                <span>
                  {account.nameFirst && account.nameLast
                    ? `${account.nameFirst} ${account.nameLast.charAt(0)}.`
                    : account.email}
                </span>
              </div>
              {checkWebAccess(websiteOptions, 1) &&
                <div className={hasItemsInCart ? 'cart with-items' : 'cart'}>
                  {hasItemsInCart &&
                    <span className="counter">{totalItemsInCart}</span>}
                  <a onClick={() => showModal('ShoppingCartModal')}>
                    <Icon name="shopping-cart" />
                  </a>
                </div>}
              <Button
                variant="outline-primary"
                onClick={() => showModal('LogoutModal')}
              >
                Logout
              </Button>
            </Navbar.Text>
          </Nav>}
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapState = state => {
  const user = state.login.toJS().result;
  const { result: cart } = state.getCart.toJS();
  return { user, cart };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(TopNav);
