import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import Layout from '../../../ui/Layout';
import Notification from '../../../ui/Notification';
import Loading from '../../../ui/Loading';

import SearchShopForm from '../shop-homepage/SearchShopForm';
import CardProduct from './CardProduct';
import { setShop, getShop } from '../../../../utils/shop-utils';

const formatQuery = (
  {
    shop,
    category
  }
) => ({
  shop: shop || '',
  category: category ? category : ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class ShopCategories extends Component {
  componentWillMount() {
    const {
      getUserDetails,
      history,
      location: { pathname, search },
      getShopCategory,
      getCart,
      user: result
    } = this.props;

    const { userCompanies } = result || {};

    const query = getQueryFromSearch(search);

    const shop = getShop(result || {});

    const {
      // shop,
      category
    } = query || {};

    getShopCategory({ companyId: shop, category });
    //getCart for specific company
    getCart({ id: null, companyId: shop });
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search }, user: result } = nextProps;
    const { location: { search: search2 }, getShopCategory } = this.props;

    if (search === search2) return;

    const query = getQueryFromSearch(search);

    const shop = getShop(result || {});

    const {
      // shop,
      category
    } = query || {};

    getShopCategory({ companyId: shop, category });
  }
  render() {
    const {
      user,
      history,
      clearGetUserDetails,
      getUserDetailsError,
      getShopCategoryResult,
      getShopCategoryError,
      clearGetShopCategory,
      postCart,
      showModal,
      postCartInProgress,
      postCartError,
      clearPostCart,
      getCart,
      getCartResult,
      location: { search },
      inProgress
    } = this.props;

    const {
      status
    } = getShopCategoryError || {};

    if (status === 401) history.push('/');

    const query = getQueryFromSearch(search);

    const {
      category
    } = query || {};

    const shop = getShop(user || {});

    const {
      categoryProducts,
      categories,
      usedCategory
    } = getShopCategoryResult || {};

    const selectedCategory = (categories || [])
      .find(({ categoryId }) => categoryId === parseInt(usedCategory)) || {};

    const {
      categoryName
    } = selectedCategory || {};

    const metadata = {
      title: `${categoryName} | Category | Concept Services`
    };

    const {
      shoppingCartId
    } = getCartResult || {};

    const handleAddToCart = ({ qty, productId }) => {
      const payload = {
        productId,
        qty,
        companyId: shop
      };

      postCart(
        {
          payload,
          id: shoppingCartId
        },
        'add-to-cart-form',
        false,
        () => {
          showModal('ShoppingCartModal');
        }
      );
    };

    return (
      <Layout route="shop-category-detail" metadata={metadata} sidebar fluid>
        <main>

          {inProgress && <Loading fullPage message="Loading, please wait" />}
          {!inProgress &&
            <Row>
              <Col md={4}>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/shop?shop=${shop}`}>Shop</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <span className="active">{categoryName}</span>
                    </li>
                  </ol>
                </nav>
                <h2 className="layout-subheader">
                  {categoryName} ({(categoryProducts || []).length})
                </h2>
              </Col>
              <Col md={8}>
                <SearchShopForm
                  enableReinitialize
                  // userCompanies={userCompanies}
                  // handleShopChange={handleShopChange}
                  initialValues={{ changeShop: parseInt(shop) }}
                  query={query}
                  history={history}
                  category={category}
                />
              </Col>
            </Row>}
          {!inProgress &&
            <div className="box-shop-categories">

              <div className="categories">
                <h3>Shop Categories</h3>
                <ul>
                  {(categories || []).map(({
                    categoryName,
                    categoryId
                  }, index) => {
                    return (
                      <li key={index}>
                        <Link to={`/shop/categories?category=${categoryId}`}>
                          {categoryName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <Row>
                {(categoryProducts || []).map((p, index) => {
                  return (
                    <Col lg={6} xl={4} key={index}>
                      <CardProduct
                        {...p}
                        shop={shop}
                        handleAddToCart={handleAddToCart}
                        postCartInProgress={postCartInProgress}
                      />
                    </Col>
                  );
                })}
              </Row>

            </div>}
            <div style={{ textAlign: 'center', width: '100%', fontStyle: 'italic' }}>
              All items are subject to manufacture lead time and availability.
            </div>
        </main>
        {getUserDetailsError &&
          <Notification
            key="user-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetUserDetails}
          >
            Failed to get shop details. Please refresh the page.
          </Notification>}
        {getShopCategoryError &&
          !(status === 401) &&
          <Notification
            key="category-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetShopCategory}
          >
            Failed to load category products. Please refresh the page.
          </Notification>}
        {postCartError &&
          <Notification
            key="post-cart-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostCart}
          >
            Failed to add product to cart. Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    error: getUserDetailsError,
    inProgress: getUserDetailsInProgress
  } = state.validateLogin.toJS();

  const {
    result: getShopCategoryResult,
    error: getShopCategoryError,
    inProgress: getShopCategoryInProgress
  } = state.getShopCategory.toJS();

  const {
    result: postCartResult,
    error: postCartError,
    inProgress: postCartInProgress
  } = state.postCart.toJS();

  const {
    result: getCartResult,
    inProgress: getCartInProgress,
    error: getCartError
  } = state.getCart.toJS();

  const { result: user } = state.validateLogin.toJS();

  return {
    getUserDetailsError,
    getShopCategoryResult,
    getShopCategoryError,
    inProgress: getUserDetailsInProgress || getShopCategoryInProgress,
    postCartInProgress,
    postCartError,
    getCartResult,
    user
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getShopCategory: fetchDux.getShopCategory.createAction,
      clearGetShopCategory: fetchDux.getShopCategory.clearAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
      postCart: fetchDux.postCart.createAction,
      clearPostCart: fetchDux.postCart.clearAction,
      getCart: fetchDux.getCart.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShopCategories);
