import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const SelectLocationsForm = props => {
  const { handleSubmit, userLocations, handleLocationChange } = props;
  const options = (userLocations || []).map(({locationName, locationId})=>{
    return {label:locationName, value:locationId}
  })
  return (
    <form className="box-search-shop" onSubmit={handleSubmit}>
      <div className="change-location">
        <Field
          name="changeLocation"
          type="text"
          component={FormField.SelectReact}
          label="Change Location"
          options={options}
          onChange={handleLocationChange}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'select-locations-form'
})(SelectLocationsForm);
