import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Layout from '../../../ui/Layout';
import fetchDux from '../../../../state/fetch-dux';
import LocationsSearchResults from './LocationsSearchResults';
import SearchLocationsForm from './SearchLocationsForm';

const formatQuery = (
  {
    search
  }
) => ({
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class LocationsSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  componentWillMount() {
    const {
      getLocations,
      getUserDetails
    } = this.props;

    getUserDetails({ getWebsiteOptions: 1, getConcepts: 1}, null, true, null);
    getLocations();
    
  }
  render() {
    const {
      getLocationsResult,
      getLocationsError,
      location: { pathname, search },
      history
    } = this.props;

    const{
      userLocations
    } = getLocationsResult || {};

    const metadata = {
      title: 'Locations | Concept Services'
    };

    const handleSearchChange = (e)=>{
      const{
        value
      } = e.target || {};

      const qs = queryString.stringify({search:value});
      history.push({ pathname, search: qs });
    };

    const query = getQueryFromSearch(search);

    const {
      search:urlSearchValue
    } = query || {};

    return (
      <Layout route="locations-search" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/locations">Locations</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Locations
              </h2>
            </Col>
            <Col md={5} className="text-right">
              <SearchLocationsForm
                handleSearchChange={handleSearchChange}
                initialValues={{search:urlSearchValue}}
              />
            </Col>
          </Row>

          <div className="box box-table">
            <LocationsSearchResults 
              result={userLocations}
              search={urlSearchValue}
            />
          </div>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {

  const {
    result: getLocationsResult,
    error: getLocationsError
  } = state.getLocations.toJS();

  return {
    getLocationsResult,
    getLocationsError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLocations: fetchDux.getLocations.createAction,
      clearGetLocations: fetchDux.getLocations.clearAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(LocationsSearch);
