import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import fetchDux from '../../../state/fetch-dux';
import getConvenienceFee from "./feeDict";
import FormField from '../../forms/FormField';
import CouponForm from './CouponForm';
import Notification from '../../ui/Notification';
import { change } from 'redux-form';
import { getShop } from '../../../utils/shop-utils';

const CheckoutCart = ({
  cart,
  deleteCartItem,
  showDelete,
  orderResult,
  onCheckoutPage,
  selectedPaymentOption,
  applyCartCoupon,
  applyCartCouponError,
  applyCartCouponResult,
  handleFormFieldChange,
  removeCoupon,
  clearApplyCoupon,
  removeError,
  removeInProgress,
  user,
  checkoutComplete
}) => {

  const {
    shoppingCartItems,
    subTotal,
    shoppingCartId,
    shippingAddress,
    freight,
    tax,
    convenienceFee,
    total,
    couponResult
  } = orderResult || cart || {};

  const { message, success } = applyCartCouponResult || {};

  const { Code, Amount } = couponResult || {};

  const finalTotal = (subTotal || 0) - (Amount || 0);
  // const total = (subTotal || 0) + (freight || 0) + (tax || 0);
  // const convenienceFee = selectedPaymentOption === 1 ? getConvenienceFee(total) : 0;

  return (
    <div className="shopping-cart">

      <h2>Your Shopping Cart</h2>

      {(shoppingCartItems || []).map((p, index) => {
        const { shoppingCartItemId } = p;
        return (
          <div key={index} className="product">
            <div
              className="image"
              style={{ backgroundImage: `url(${p.imageHref})` }}
            >
              <span className="qty">{p.qty}</span>
            </div>
            <div className="detail">
              <strong>{p.model}</strong>
              <p>{p.category && p.category.categoryName}</p>
            </div>
            <div>
              ${numeral(p.fullPrice).format('0,0.00')}
              {showDelete && (
                <Button variant="link"
                  onClick={() => deleteCartItem({ shoppingCartItemId, id: cart.shoppingCartId })}>
                  Remove
                </Button>
              )}
            </div>
          </div>
        );
      })}

      {
        !checkoutComplete &&
        < div style={{ marginTop: '2rem' }}>
          <CouponForm
            appliedCode={Code}
            onSubmit={(values) => {
              const { Code } = values;
              const shop = getShop(user || {})
              applyCartCoupon({ shoppingCartId, Code, companyId: shop }, 'coupon-form', null, (result) => {
                const { success } = result || {};
                if (success) {
                  handleFormFieldChange('coupon-form', 'Code', '');
                }

              });
            }} />
        </div>
      }



      <table className="table-subtotal" style={{ marginTop: '1rem' }}>
        <tbody>
          <tr>
            <th>Subtotal</th>
            <td>${numeral(subTotal || 0).format('0,0.00')}</td>
          </tr>
          {
            couponResult &&
            <tr>
              <th>{`Discount ( ${Code} )`}</th>
              <td>
                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem' }}>
                  {`-$${Amount}`}
                  {
                    !checkoutComplete &&
                    <label style={{
                      textDecoration: 'underline',
                      color: '#c5a44d',
                      fontSize: '0.7rem',
                      cursor: !removeInProgress ? 'pointer' : 'none',
                      opacity: removeInProgress ? 0.4 : 1,
                      pointerEvents: removeInProgress ? 'none' : 'auto'
                    }}
                      onClick={
                        () => {
                          const shop = getShop(user || {})
                          removeCoupon({ Code, shoppingCartId, companyId: shop }, null, null, () => {
                            clearApplyCoupon();
                          })
                        }
                      }
                    >
                      Remove
                    </label>
                  }
                </div>
              </td>
            </tr>
          }
          <tr>
            <th>Freight</th>
            <td>
              {onCheckoutPage && 'TBD'}
              {freight && !onCheckoutPage && `$${numeral(freight).format('0,0.00')}`}
            </td>
          </tr>
          <tr>
            <th>Tax</th>
            <td>
              {onCheckoutPage && 'TBD'}
              {tax && !onCheckoutPage && `$${numeral(tax).format('0,0.00')}`}
            </td>
          </tr>
          {!onCheckoutPage && selectedPaymentOption === 1 && (
            <tr>
              <th>Convenience Fee</th>
              <td>{convenienceFee ? `$${numeral(convenienceFee).format('0,0.00')}` : '-'}</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            {!onCheckoutPage && (<td>${numeral(total + convenienceFee).format('0,0.00')}</td>)}
            {
              onCheckoutPage &&
              (
                <td>${numeral(finalTotal < 0 ? 0 : finalTotal).format('0,0.00')}</td>
              )
            }
          </tr>
        </tfoot>
      </table>
      {
        (applyCartCouponError || (applyCartCouponResult && !success))
        &&
        <Notification
          key="apply-coupon-error"
          duration={5}
          closable={true}
          type="danger"
        >
          {message || 'Error applying the coupon'}
        </Notification>
      }
      {
        removeError &&
        <Notification
          key="remove-coupon-error"
          duration={5}
          closable={true}
          type="danger"
        >
          {'Error removing the coupon'}
        </Notification>
      }
    </div >
  )

}

const mapState = state => {
  const {
    inProgress: cartItemsLoading,
    result: cart
  } = state.getCart.toJS();

  const {
    inProgress: approvalCartLoading,
    result: approvalCart
  } = state.getApprovalCart.toJS();

  const {
    result: applyCartCouponResult,
    error: applyCartCouponError
  } = state.applyCartCoupon.toJS();

  const {
    inProgress: removeInProgress,
    error: removeError
  } = state.removeCoupon.toJS();

  const {
    result: user
  } = state.validateLogin.toJS();

  return {
    cart: cart || approvalCart,
    applyCartCouponResult,
    applyCartCouponError,
    removeInProgress,
    removeError,
    user
  }
}

const mapDispatch = dispatch => bindActionCreators({
  deleteCartItem: fetchDux.deleteCartItem.createAction,
  applyCartCoupon: fetchDux.applyCartCoupon.createAction,
  handleFormFieldChange: change,
  removeCoupon: fetchDux.removeCoupon.createAction,
  clearApplyCoupon: fetchDux.applyCartCoupon.clearAction
}, dispatch);


export default connect(mapState, mapDispatch)(CheckoutCart);