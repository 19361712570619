import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import OrderHelpForm from './OrderHelpForm';

const OrderHelpModal = ({ handleSubmit, openModal, closeModal,supportRequestInProgress }) => (
  <Modal
    show={openModal === 'OrderHelpModal'}
    onHide={closeModal}
    className="modal-address"
  >
    <Modal.Header closeButton>
      <Modal.Title>Request help with this order</Modal.Title>
    </Modal.Header>
    <OrderHelpForm onSubmit={handleSubmit} supportRequestInProgress={supportRequestInProgress} />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(OrderHelpModal);
