import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import paymentOptionDict from '../paymentOptionDict';
import CheckoutCart from '../CheckoutCart';
import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';
import HelpModal from '../../homepage/HelpModal';
import { actionCreators } from '../../../../state/modals-dux';

class CheckoutComplete extends Component {
  componentDidMount() {
    const {
      paymentResult,
      history,
      getOrderById,
      clearPaymentResult
    } = this.props;
    if (!paymentResult) {
      history.push('/checkout/review');
    }
    getOrderById(paymentResult, null, null, (result) => {
      if (result) {
        clearPaymentResult();
      }
    });
  }
  render() {
    const {
      orderResult,
      orderResultLoading,
      orderError,
      showModal
    } = this.props;
    // const {
    //   result
    // } = orderResult || {};
    const {
      jobRef,
      orderDate,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      Zip,
      Email,
      PaymentOption,
      CardNumber,
      NameOnCard,
      ExpiryMonth,
      ExpiryYear,
      BusinessNameOnCheck,
      CheckNumber,
      BankName,
      NameOnAccount,
      PurchaseOrderNumber,
      PurchaseOrderDate,
      PurchaseOrderApprovedBy,
      ShipViaId,
      projectId,
      emailLogs
    } = orderResult || {};

    const { message } = orderError || {};
    const metadata = {
      title: 'Your order has been received | Checkout | Concept Services'
    };

    const showHelpModal = () => showModal('helpModal');

    return (
      <Layout route="checkout-complete" metadata={metadata} fluid>
        <main>

          <Row noGutters>
            <Col md={7} lg={8}>

              <div className="checkout-body">

                <div className="thank-you">
                  <h2>Thank you for your order</h2>
                </div>

                <div className="checkout-form">

                  <h5>Your Order Has Been Received</h5>

                  <div className="box">
                    <h4>Thank you, we are processing your order.</h4>
                    <div>
                      Please check your email for order confirmation and detailed instructions to ensure your order is on track.
                    </div>
                  </div>

                  {orderResultLoading ?
                    <Loading /> :
                    (
                      <>
                        <div className="box">
                          <h4>Order Details</h4>
                          <Row>
                            <Col>
                              <h5>Confirmation Sent To</h5>
                            </Col>
                            <Col>
                              {(emailLogs || []).map(({ EmailRecipient }) => (
                                <div>
                                  {EmailRecipient}
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5>Order Number</h5>
                            </Col>
                            <Col>
                              {jobRef}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5>Order Date</h5>
                            </Col>
                            <Col>{moment(orderDate).format('ddd MMM DD, YYYY')}</Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5>Shipping To</h5>
                            </Col>
                            <Col>
                              {FirstName} {LastName}<br />
                              {Address1}, {Address2 ? `${Address2},` : ''} {Address2}, {City}, {Zip}
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col>
                              <h5>Shipping Method</h5>
                            </Col>
                            <Col>{ShipViaId}</Col>
                          </Row> */}
                          <Row>
                            <Col>
                              <h5>Payment Method</h5>
                            </Col>
                            <Col>
                              {
                                PaymentOption === 1 &&
                                (
                                  <>
                                    {paymentOptionDict[PaymentOption]}<br />
                                    **** **** **** {CardNumber}
                                  </>
                                )
                              }
                              {
                                PaymentOption === 3 &&
                                (
                                  <>
                                    {paymentOptionDict[PaymentOption]}<br />
                                    Name: {BusinessNameOnCheck}<br />
                                    Check Number: {CheckNumber}
                                  </>
                                )

                              }
                              {
                                PaymentOption === 4 &&
                                (
                                  <>
                                    {paymentOptionDict[PaymentOption]}<br />
                                    Bank Name: {BankName}<br />
                                    Account Name: {NameOnAccount}
                                  </>
                                )
                              }
                              {
                                PaymentOption === 2 &&
                                (
                                  <>
                                    {paymentOptionDict[PaymentOption]}<br />
                                    Po No.: {PurchaseOrderNumber}<br />
                                    Po Date: {moment(PurchaseOrderDate).format('ddd MMM DD, YYYY')}<br />
                                    Po Approver: {PurchaseOrderApprovedBy}
                                  </>
                                )
                              }
                            </Col>
                          </Row>
                        </div>
                        {PaymentOption === 3 && (
                          <div className="box">
                            <h4>Mail Check Instructions</h4>
                            <div>Make checks payable to <b>Concept Services</b>.</div>
                            <div>
                              Please send check via secure and priority mail to:
                            </div>
                            <address>
                              Concept Services<br />
                              12521 Amherst Drive<br />
                              Austin, TX 78727
                            </address>
                            <div>
                              Add this order number: <b>{jobRef}</b> on check memo line
                            </div>
                          </div>
                        )}
                        <div className="box">
                          <h4>Contact Us</h4>
                          <div>
                            Feel free to
                            {' '}
                            <Link onClick={showHelpModal}>contact us</Link>
                            {' '}
                            with any questions regarding your order.
                          </div>
                        </div>
                        <Link to={`/orders/${projectId}`} className="btn btn-primary">
                          View or manage order
                        </Link>
                      </>
                    )
                  }
                </div>
              </div>

            </Col>
            <Col md={5} lg={4}>
              {!orderResultLoading &&
                <CheckoutCart
                  selectedPaymentOption={PaymentOption}
                  orderResult={orderResult}
                  checkoutComplete={true}
                />
              }
            </Col>
          </Row>
          {orderError &&
            (

              <Notification
                key="get-order-fail"
                duration={3}
                closable={true}
                type="danger"
              >
                {message ? message : 'Failed to get order details. Please try again'}
              </Notification>

            )
          }
          <HelpModal />
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user
  } = state.validateLogin.toJS();

  const {
    result: paymentResult
  } = state.postCartPayment.toJS();

  const {
    result: orderResult,
    inProgress: orderResultLoading,
    error: orderError
  } = state.getOrderById.toJS();

  return {
    user,
    paymentResult,
    orderResultLoading,
    orderResult,
    orderError
  }
}

const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  getOrderById: fetchDux.getOrderById.createAction,
  clearPaymentResult: fetchDux.postCartPayment.clearAction
}, dispatch)

export default connect(mapState, mapDispatch)(CheckoutComplete);
