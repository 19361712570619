import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { render } from 'react-dom';
import queryString from 'query-string';

import fetchDux from '../../../../state/fetch-dux';
import FormField from '../../../forms/FormField';

import { getCompanyProducts } from '../../../../api/api';
import { setShop, getShop } from '../../../../utils/shop-utils';

class SearchShopForm extends Component {
  render() {
    const {
      handleSubmit,
      // userCompanies,
      user,
      // handleShopChange,
      // getCompanyProducts,
      // clearGetCompanyProducts,
      // getCompanyProductsResult,
      // getCompanyProductsError,
      category,
      getShopCategory,
      getShopHome,
      getCart,
      query,
      history,
      isHomePage
    } = this.props;


    const { userCompanies } = user || {};

    const shop = getShop(user || {});
    const shopOptions = (userCompanies || []).map(({ companyID, companyName }) => {
      return {
        value: companyID,
        label: companyName
      }
    })

    const loadOptions = (inputValue) => {
      if ((inputValue || '').length < 2) {
        return new Promise(resolve => {
          resolve([]);
        })
      };
      const shop = getShop(user || {});
      return getCompanyProducts({ search: encodeURIComponent(inputValue), companyId: shop })
    };

    const handleChange = (value) => {
      const qs = queryString.stringify({ productId: value, shop });
      history.push({ pathname: '/shop/products', search: qs });
    }

    const handleShopChange = value => {
      const choiceExists = (userCompanies || []).some(({ companyID }) => companyID === parseInt(value))

      const {
        companyID
      } = (userCompanies || [])[0] || {};

      const companySearch = choiceExists ? value : companyID;

      if (isHomePage) {
        getShopHome(companySearch);
      } else {
        getShopCategory({ companyId: companySearch, category }, null, true, null);
      }


      setShop(companySearch)

      getCart({ id: null, companyId: companySearch });
    }

    return (
      <form className="box-search-shop" onSubmit={handleSubmit}>
        <Row>
          <Col md={8}>
            <Field
              name="search"
              type="text"
              component={FormField.SelectReactAsync}
              placeholder="Search Shop"
              loadOptions={loadOptions}
              onChange={handleChange}
            />
          </Col>
          <Col md={4} className="change-shop">
            <Field
              name="changeShop"
              type="text"
              component={FormField.SelectReact}
              label="Change Shop"
              options={shopOptions}
              onChange={handleShopChange}
              cacheOptions
            />
          </Col>
        </Row>
      </form>
    );
  }
}

const mapState = state => {
  const {
    result: user,
  } = state.validateLogin.toJS();

  return {
    user
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getCart: fetchDux.getCart.createAction,
      getShopCategory: fetchDux.getShopCategory.createAction,
      getShopHome: fetchDux.getShopHome.createAction,
    },
    dispatch
  );

const form = reduxForm({
  form: 'search-shop-form'
})(SearchShopForm)

export default connect(mapState, mapDispatch)(form);