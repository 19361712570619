import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';

import Notification from '../../ui/Notification';
import Layout from '../../ui/Layout';
import LoginForm from './LoginForm';
import { withLastLocation } from 'react-router-last-location';

const getLastLocation = (lastLocation) => {
  const {
    pathname
  } = lastLocation || {};

  if((pathname || '').includes('reset-password') || !lastLocation){
    return { pathname: '/' };
  }

  return lastLocation;
}

export const Login = (
  {
    user,
    error,
    location,
    postLogin,
    savePasswordResult,
    clearValidateTokenAndSavePassword,
    getCart,
    loginError,
    lastLocation
  }
) => {
  const isAuthorized = authorized({
    user
  });
  if (isAuthorized) return <Redirect to={getLastLocation(lastLocation)} />;
  const metadata = {
    title: 'Login | Concept Services'
  };

  return (
    <Layout route="login" hideNav metadata={metadata}>
      <Row className="justify-content-sm-center align-items-center">
        <Col sm md={4}>
          <div className="brand" />
          {/* {error && <Alert variant="danger">{error.message}</Alert>} */}
          {loginError && <Alert variant="danger">{loginError.message}</Alert>}
          <LoginForm
            onSubmit={values =>
              postLogin(values, null, null, data => {
                const {
                  lastCart
                } = data || {};

                const {
                  companyId,
                  id
                } = lastCart || {};
                id && companyId && getCart({ id, companyId });
              })}
          />
        </Col>
      </Row>
      {savePasswordResult &&
        <Notification
          key="password-create-success"
          duration={8}
          closable={true}
          type="success"
          onClose={clearValidateTokenAndSavePassword}
        >
          {'Success! Password changed.'}
        </Notification>}
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.validateLogin.toJS();

  const {
    result: savePasswordResult
  } = state.validateTokenAndSavePassword.toJS();

  const {
    error: loginError
  } = state.login.toJS();

  return {
    user,
    error,
    savePasswordResult,
    loginError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction,
      getCart: fetchDux.getCart.createAction,
      clearValidateTokenAndSavePassword: fetchDux.validateTokenAndSavePassword.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withLastLocation(Login));
