import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DocumentsSearch from './documents-search/DocumentsSearch.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={DocumentsSearch} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
