import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import AddShippingAddressForm from './AddShippingAddressForm';



const AddShippingAddressModal = ({
  handleSubmit,
  openModal,
  closeModal,
  address
}) => {

  const handleAddressChange = (selectedAddress) => {
    handleSubmit(selectedAddress);
    closeModal("AddShippingAddressModal");
  }

  const {
    ID,
    Address1,
    Address2,
    City,
    PostalCode,
    StateProvince,
    businessName,
    nameFirst,
    nameLast,
    specialInstructions
  } = address || {};

  const initialValues = ID ? {} : {
    Address1,
    Address2,
    City,
    PostalCode,
    StateProvince,
    businessName,
    nameFirst,
    nameLast,
    specialInstructions
  }

  return (
    <Modal
      show={openModal === 'AddShippingAddressModal'}
      onHide={closeModal}
      className="modal-address"
    >
      <Modal.Header closeButton>
        <Modal.Title>Ship to different address</Modal.Title>
      </Modal.Header>
      <AddShippingAddressForm
        initialValues={initialValues}
        onSubmit={values => handleAddressChange(values)}
      />
    </Modal>
  )
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators
  }
  ,
  dispatch);

export default connect(mapState, mapDispatch)(AddShippingAddressModal);
