import React from 'react';
import numeral from 'numeral';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const convertToRoman = num => {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let str = '';
  for (let i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const ProposalSummaryForm = ({ proposal, total }) => (
  <div className="quote-proposal-summary form">
    <div className="services">
      {(proposal.quoteSections || []).map((service, index) => {
        return (
          <div className="service" key={index}>
            <strong>{convertToRoman(index + 1)}. {service.SectionName}</strong>
            <div className="sections">
              {(service.quoteCategories || []).map((section, index) => {
                return (
                  <div className="section" key={index}>
                    <strong>{section.CategoryName}</strong>
                    <div className="items">
                      {(section.quoteItems || []).map((item, index) => {
                        return (
                          <div
                            className={classNames('item', {
                              optional: item.isOptional,
                              included: item.isOptional && item.isIncluded
                            })}
                            key={index}
                          >
                            <strong>
                              {item.isOptional &&
                                <Field
                                  name="include"
                                  type="checkbox"
                                  component={FormField.InlineCheckbox}
                                />}
                              {item.LineItemName}
                            </strong>
                            <div className="price">
                              ${numeral(item.Price).format('0,0.00')}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
    <Row className="row-totals">
      <Col />
      <Col md={5}>
        <table className="table-total">
          <tbody>
            <tr>
              <th>Services</th>
              <td>
                $
                {numeral(total.services || 0).format('0,0.00')}
              </td>
            </tr>
            <tr>
              <th>Material Goods</th>
              <td>
                $
                {numeral(total.materialGoods || 0).format('0,0.00')}
              </td>
            </tr>
            <tr>
              <th>Subtotal</th>
              <td>
                $
                {numeral(total.subTotal || 0).format('0,0.00')}
              </td>
            </tr>
            <tr>
              <th>Sales Tax</th>
              <td>
                $
                {numeral(total.salesTax || 0).format('0,0.00')}
              </td>
            </tr>
            <tr>
              <th>Grand Total</th>
              <td>
                $
                {numeral(total.grandTotal || 0).format(
                  '0,0.00'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  </div>
);

export default ProposalSummaryForm;
