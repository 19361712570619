import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';

import TopNav from './TopNav';
import SideNav from './SideNav';

const Layout = (
  {
    children,
    fluid,
    route,
    user,
    hideNav,
    metadata,
    sidebar,
    getUserDetailsResult,
    hideMenuOptions
  }
) => {
  const [isNavOpen, toggleMobileNav] = useState(false);
  const { title, description, keywords } = metadata || {};

  const {
    websiteOptions
  } = getUserDetailsResult || {};

  return (
    <div
      className={classNames(`route-${route}`, { 'mobile-nav-open': isNavOpen })}
    >

      <MetaTags>
        <title>{title || 'Concept Services'}</title>
        <meta name="description" content={description || ''} />
        <meta name="keywords" content={keywords || ''} />
      </MetaTags>

      {!hideNav && <TopNav {...{ isNavOpen, toggleMobileNav, hideMenuOptions }} />}

      <Container fluid={fluid} className="layout">
        {!sidebar && <div>{children}</div>}
        {sidebar &&
          <div className="layout-with-sidebar">
            <div className="layout-aside">
              <SideNav websiteOptions={websiteOptions} />
            </div>
            <div className="layout-body">
              {children}
            </div>
          </div>}
      </Container>

    </div>
  );
};

const mapState = state => {
  const {
    result: getUserDetailsResult
  } = state.getUserDetails.toJS();

  const {
    result: user
  } = state.login.toJS();

  return {
    getUserDetailsResult,
    user
  };
};

export default connect(mapState)(Layout);
