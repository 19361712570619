exports.normalizeMoney = (value)=>{
  if(value === "NULL" || !value){
    return '';
  }

  if((value || '').includes('$')){
    return `$${(value || '').replace(/\$/g, '')}`;
  }

  return `$${value}`
}