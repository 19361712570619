import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { change } from 'redux-form'

import fetchDux from '../../../../state/fetch-dux';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Notification from '../../../ui/Notification';
import Loading from '../../../ui/Loading';

import CardShopCategory from './CardShopCategory';
import CardFeaturedPackage from './CardFeaturedPackage';
import SearchShopForm from './SearchShopForm';
import { getShop, setShop } from '../../../../utils/shop-utils';
import { actionCreators } from '../../../../state/modals-dux';

const formatQuery = (
  {
    shop,
    c
  }
) => ({
  shop: shop || '',
  c: c || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class Shop extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    // history.push({ pathname, search: qs });
  }
  componentWillMount() {
    const {
      getShopHome,
      getUserDetails,
      history,
      getCart,
      user,
      location: { pathname, search },
      formChange,
      showModal
    } = this.props;

    const query = getQueryFromSearch(search);

    const {
      c
    } = query || {};

    const {
      userCompanies
    } = user || {};

    const shop = getShop({ userCompanies });

    getShopHome(shop, null, false, null);
    //getCart for specific company
    getCart({ id: null, companyId: shop, c }, null, null, data => {
      const{ __sharedCart, companyId } = data || {};
      if (__sharedCart) {
        formChange('search-shop-form', 'changeShop', companyId)
        getShopHome(companyId, null, false, null);
        setShop(companyId)
        showModal('ShoppingCartModal')
      }
    });

    if (c) history.replace({ search: '' })

  }
  render() {
    const {
      getUserDetailsResult,
      getUserDetailsError,
      clearGetUserDetails,
      getShopHome,
      getShopHomeResult,
      getShopHomeError,
      clearGetShopHome,
      location: { pathname, search },
      history,
      getCart,
      user,
      inProgress
    } = this.props;

    const {
      status
    } = getShopHomeError || {};

    // if (status === 401) history.push('/');

    const query = getQueryFromSearch(search);

    // const {
    //   shop
    // } = query || {};

    const shop = getShop(user || {});

    const {
      categories,
      featuredPackages
    } = getShopHomeResult || {};

    const {
      userCompanies
    } = getUserDetailsResult || {};

    const metadata = {
      title: 'Shop | Concept Services'
    };

    const handleShopChange = value => {
      const choiceExists = !!(userCompanies || [])
        .find(({ companyID }) => companyID === parseInt(value));

      const {
        companyID
      } = (userCompanies || [])[0] || {};

      const companySearch = choiceExists ? value : companyID;
      //get company details
      getShopHome(companySearch);
      //getCart for specific company
      getCart({ id: null, companyId: companySearch });

      const qs = queryString.stringify({ shop: companySearch });
      history.push({ pathname, search: qs });
    };

    return (
      <Layout route="shop-homepage" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col lg={4}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <span className="active">Shop</span>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Shop Categories ({(categories || []).length})
              </h2>
            </Col>
            <Col lg={8}>
              <SearchShopForm
                isHomePage
                enableReinitialize
                // userCompanies={userCompanies}
                // handleShopChange={handleShopChange}
                initialValues={{ changeShop: parseInt(shop) }}
                query={query}
                history={history}
              />
            </Col>
          </Row>

          {inProgress && <Loading fullPage message="Loading, please wait" />}
          {!inProgress &&
            <div>
              {(categories || []).length > 0 &&
                <Row>
                  {(categories || []).map((c, index) => {
                    return (
                      <Col md={6} lg={4} key={index}>
                        <CardShopCategory {...c} shop={parseInt(shop)} />
                      </Col>
                    );
                  })}
                </Row>}
              {(categories || []).length === 0 &&
                <p className="text-muted">
                  There are no shop categories available at the moment.
                </p>}


              {(featuredPackages || []).length > 0 && (
                <h2 className="layout-subheader">Featured Packages</h2>
              )}
              {(featuredPackages || []).length > 0 &&
                <Row>
                  {(featuredPackages || []).map((p, index) => {
                    return (
                      <Col md={6} lg={4} key={index}>
                        <CardFeaturedPackage {...p} shop={parseInt(shop)} />
                      </Col>
                    );
                  })}
                </Row>}
              {/* {(featuredPackages || []).length === 0 &&
                <div className="text-muted">
                  There are no featured packages available at the moment.
                </div>} */}
            </div>}
            <div style={{ textAlign: 'center', width: '100%', fontStyle: 'italic' }}>
              All items are subject to manufacture lead time and availability.
            </div>
        </main>
        {getUserDetailsError &&
          <Notification
            key="user-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetUserDetails}
          >
            Failed to get shop details. Please refresh the page.
          </Notification>}
        {getShopHomeError &&
          !(status === 401) &&
          <Notification
            key="category-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetShopHome}
          >
            Failed to load shop details. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: getShopHomeResult,
    error: getShopHomeError,
    inProgress: getShopHomeInProgress
  } = state.getShopHome.toJS();

  const {
    result: getUserDetailsResult,
    error: getUserDetailsError
  } = state.getUserDetails.toJS();

  const {
    result: user
  } = state.validateLogin.toJS();

  return {
    getShopHomeResult,
    getShopHomeError,
    getUserDetailsResult,
    getUserDetailsError,
    user,
    inProgress: getShopHomeInProgress
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getShopHome: fetchDux.getShopHome.createAction,
      clearGetShopHome: fetchDux.getShopHome.clearAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
      getCart: fetchDux.getCart.createAction,
      formChange: change
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Shop);
