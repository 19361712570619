import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';
import { actionCreators } from '../../../../state/modals-dux';

import SearchShopForm from '../shop-homepage/SearchShopForm';

const PackageDescription = props => {
  const { description } = props || {};
  const [isOpen, toggle] = useState(false);
  return (
    <div
      className={isOpen ? 'package-description open' : 'package-description'}
    >
      <div className="description">{description}</div>
      {!isOpen &&
        <div className="truncate">
          <a onClick={() => toggle(!isOpen)}>See More</a>
        </div>}
    </div>
  );
};

const formatQuery = (
  {
    shop,
    packageId
  }
) => ({
  shop: shop || '',
  packageId: packageId ? packageId : ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class ShopPackage extends Component {
  componentWillMount() {
    const {
      getUserDetails,
      history,
      location: { pathname, search },
      getPackageById,
      getCart
    } = this.props;

    const query = getQueryFromSearch(search);

    const {
      shop,
      packageId
    } = query || {};

    getUserDetails(
      { getWebsiteOptions: 1, getConcepts: 1 },
      null,
      true,
      data => {
        const {
          userCompanies
        } = data || {};

        const choiceExists = !!(userCompanies || [])
          .find(({ companyID }) => companyID === parseInt(shop));

        const {
          companyID
        } = (userCompanies || [])[0] || {};

        const companySearch = choiceExists ? shop : companyID;

        getPackageById(
          { companyId: companySearch, packageId },
          null,
          false,
          null
        );
        //getCart for specific company
        getCart({ id: null, companyId: companySearch });

        const qs = queryString.stringify({ shop: companySearch, packageId });
        history.push({ pathname, search: qs });
      }
    );
  }
  render() {
    const {
      inProgress,
      getUserDetailsResult,
      getUserDetailsError,
      clearGetUserDetails,
      getPackageByIdResult,
      getPackageByIdError,
      getPackageById,
      clearGetPackageById,
      location: { pathname, search },
      history,
      postPackage,
      clearPostPackage,
      postPackageError,
      postPackageInProgress,
      showModal,
      getCartResult
    } = this.props;

    const {
      status
    } = getPackageByIdError || {};

    if (status === 401) history.push('/');

    const query = getQueryFromSearch(search);

    const {
      shop
    } = query || {};

    const {
      packageDetails,
      products
    } = getPackageByIdResult || {};

    const {
      totalSell,
      name,
      description,
      numProducts,
      hidePrice,
      id: packageId
    } = packageDetails || {};

    const metadata = {
      title: `${name} | Package | Concept Services`
    };

    const {
      userCompanies
    } = getUserDetailsResult || {};

    const handleShopChange = value => {
      const choiceExists = !!(userCompanies || [])
        .find(({ companyID }) => companyID === parseInt(value));

      const {
        companyID
      } = (userCompanies || [])[0] || {};

      const companySearch = choiceExists ? value : companyID;

      const qs = queryString.stringify({ shop: companySearch, category: '' });
      history.push({ pathname: '/shop/categories', search: qs });
    };

    const {
      shoppingCartId
    } = getCartResult || {};

    const handleAddPackage = () => {
      postPackage(
        {
          packageId,
          payload: { companyId: shop },
          id: shoppingCartId
        },
        null,
        false,
        () => {
          showModal('ShoppingCartModal');
        }
      );
    };

    return (
      <Layout route="shop-package-detail" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={4}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/shop?shop=${shop}`}>Shop</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <span className="active">{name}</span>
                  </li>
                </ol>
              </nav>
            </Col>
            <Col md={8}>
              <SearchShopForm
                enableReinitialize
                userCompanies={userCompanies}
                handleShopChange={handleShopChange}
                initialValues={{ changeShop: parseInt(shop) }}
                query={query}
                history={history}
              />
            </Col>
          </Row>

          <div className="box-package-detail">
            <Row>
              <Col md={6}>

                <h2>{name}</h2>
                <div className="back">See more in <Link>Packages</Link></div>
                <div className="package-products">
                  {numProducts > 1 && <span>{numProducts} Products</span>}
                  {numProducts < 2 && <span>{numProducts} Product</span>}
                </div>
                {!hidePrice && (
                  <div className="package-price">
                    ${numeral(totalSell).format('0,0.00')}
                  </div>
                )}
                <Button
                  onClick={handleAddPackage}
                  disabled={postPackageInProgress}
                >
                  <Icon name="shopping-cart" /> Add Package To Cart
                </Button>

              </Col>
              <Col md={6}>

                <h3>Package Description</h3>
                <PackageDescription description={description || '-'} />

              </Col>
              <Col md={12}>

                {(products || []).length > 1 &&
                  <h4>{(products || []).length} Items In Package</h4>}
                {(products || []).length < 2 &&
                  <h4>{(products || []).length} Item In Package</h4>}

                <table className="table">
                  <tbody>
                    {(products || []).map((item, index) => {
                      const {
                        productId,
                        qty,
                        model,
                        spec,
                        imageHref,
                        sellPrice
                      } = item || {};

                      return (
                        <tr key={index}>
                          <td className="image">
                            <img src={imageHref} alt={model} />
                          </td>
                          <td className="description">
                            <strong>{model}</strong>
                            <div>{(spec || '').substring(0, 250)}</div>
                          </td>
                          <td className="details">
                            <Link
                              to={
                                `/shop/products?productId=${productId}&shop=${shop}`
                              }
                            >
                              See Details
                            </Link>
                          </td>
                          <td className="qty">
                            <strong>Qty</strong>
                            <div>{qty}</div>
                          </td>
                          <td className="price">
                            ${numeral(sellPrice * qty).format('0,0.00')}
                            {qty > 1 &&
                              <div>
                                $
                                {numeral(sellPrice).format('0,0.00')}
                                {' '}
                                per item
                              </div>}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </Col>
            </Row>
          </div>

        </main>
        {getUserDetailsError &&
          <Notification
            key="user-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetUserDetails}
          >
            Failed to get shop details. Please refresh the page.
          </Notification>}
        {getPackageByIdError &&
          !(status === 401) &&
          <Notification
            key="category-detail-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetPackageById}
          >
            Failed to load package details. Please refresh the page.
          </Notification>}
        {postPackageError &&
          <Notification
            key="post-package-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostPackage}
          >
            Failed to add package to cart. Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: getUserDetailsResult,
    error: getUserDetailsError,
    inProgress: getUserDetailsInProgress
  } = state.getUserDetails.toJS();

  const {
    result: getPackageByIdResult,
    error: getPackageByIdError,
    inProgress: getPackageByIdInProgress
  } = state.getPackageById.toJS();

  const {
    result: postPackageResult,
    error: postPackageError,
    inProgress: postPackageInProgress
  } = state.postPackage.toJS();

  const {
    result: getCartResult,
    inProgress: getCartInProgress,
    error: getCartError
  } = state.getCart.toJS();

  return {
    getUserDetailsResult,
    getUserDetailsError,
    getPackageByIdResult,
    getPackageByIdError,
    postPackageError,
    postPackageInProgress,
    inProgress: getUserDetailsInProgress || getPackageByIdInProgress,
    getCartResult
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getPackageById: fetchDux.getPackageById.createAction,
      clearGetPackageById: fetchDux.getPackageById.clearAction,
      getUserDetails: fetchDux.getUserDetails.createAction,
      clearGetUserDetails: fetchDux.getUserDetails.clearAction,
      postPackage: fetchDux.postPackage.createAction,
      clearPostPackage: fetchDux.postPackage.clearAction,
      getCart: fetchDux.getCart.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShopPackage);
