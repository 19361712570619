import React, { PropTypes, Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button, Alert } from 'react-bootstrap';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import SignaturePad from 'signature_pad';
import { Link } from 'react-router-dom';
const signatureSizeLimit = 300000;

const required = value => value ? undefined : 'Required';

class SignaturePadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signaturePadError: null,
      isEmpty: false
    };
  }
  componentDidMount() {
    const canvas = document.querySelector('canvas');
    this.signaturePad = new SignaturePad(canvas);
    this.setState({ isEmpty: true });
    this.signaturePad.addEventListener('beginStroke', () => {
      this.setState({ isEmpty: false });
    });
  }
  render() {
    const {
      handleCancel,
      submitting,
      onSignatureSave,
      signQuoteError,
      quoteId,
      fullName
    } = this.props;

    const { signaturePadError, isEmpty } = this.state;

    const handleSubmit = () => {
      const dataUrl = this.signaturePad.toDataURL();
      const signatureSize = Math.round(dataUrl.length * (3 / 4));
      if (signatureSize > signatureSizeLimit) {
        this.setState({ signaturePadError: 'Signature exceeds size limit.' });
        return;
      }
      if (this.signaturePad.isEmpty()) {
        this.setState({ signaturePadError: 'Signature is required.' });
        return;
      }
      const payload = { quoteId, dataUrl, fullName };
      onSignatureSave(payload);
    };

    const onClear = () => {
      this.signaturePad.clear();
      this.setState({ isEmpty: true });
    };

    return (
      <form onSubmit={handleSubmit} className="signature-pad">
        <Modal.Body>

          <div className="disclaimer">
            {/* <p> */}
              I agree to the Concept Services
              {' '}
              <Link to="/quote-agreement" target="_blank">
                Terms and Conditions
              </Link>
            {/* </p> */}
          </div>
          <br/>

          <Field
            name="fullName"
            type="text"
            component={FormField.Input}
            label="Full Name"
            validate={[required]}
          />

          <div className="pad">
            {isEmpty && <div className="empty">Please Sign Here</div>}
            <canvas width="466" height="360" />
          </div>

          {signaturePadError &&
            <Alert variant="danger">{signaturePadError}</Alert>}

          {signQuoteError &&
            <Alert variant="danger">
              An error occurred saving signature. Please try again.
            </Alert>}

          <div className="text-right">
            <Button
              variant="outline-secondary"
              onClick={() => onClear()}
              disabled={submitting}
            >
              Clear Signature
            </Button>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm="6">
              <Button
                variant="outline-primary"
                onClick={handleCancel}
                disabled={submitting}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="6">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={submitting}
              >
                Accept
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </form>
    );
  }
}

const formName = 'accept-quote-form';

const form = reduxForm({
  form: formName
})(SignaturePadComponent);

const selector = formValueSelector(formName);

const mapState = state => {
  const fullName = selector(state, "fullName");
  return {
    fullName
  }
}

export default connect(mapState)(form);
