import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { actionCreators as billingActions } from '../../../../state/billing-dux';

import CheckoutPaymentForm from './CheckoutPaymentForm';
import fetchDux from '../../../../state/fetch-dux';
import { getShop } from '../../../../utils/shop-utils';
import CheckoutCart from '../CheckoutCart';
import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';
import { formValueSelector } from 'redux-form';

const FORM_NAME = 'checkout-payment-form';

const getInitialValues = ({
  billingAddress,
  shippingAddress,
  billing
}) => {
  const { paymentOption } = billing || { paymentOption: -1 };
  if (shippingAddress) {
    const address = billingAddress ? billingAddress : shippingAddress;
    return {
      billingAddress: address,
      paymentOption,
      details: { ...billing }
    }
  }
  return {
    billingAddress: {},
    paymentOption,
    details: { ...billing }
  };
}

class CheckoutPayment extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const {
      getCart,
      user,
      getLocationsByCompany
    } = this.props;

    const shop = getShop(user || {})
    getCart({ companyId: shop })
    getLocationsByCompany({ companyId: shop });
  }

  componentDidUpdate() {
    const {
      cart,
      cartItemsLoading,
      history
    } = this.props;
    const { shoppingCartItems, shippingAddress } = cart || {}
    if (!cartItemsLoading) {
      if (!shoppingCartItems || (shoppingCartItems && shoppingCartItems.length === 0)) {
        history.push("/shop");
      }
      else if (!shippingAddress) {
        history.push("/checkout/shipping");
      }
    }
  }

  onPaymentFormSubmitHandler = (values) => {
    const {
      saveBillingAddress,
      clearSaveBillingAddressResult,
      history,
      setBilling,
      cart
    } = this.props;
    const { shoppingCartId } = cart;
    const { details, billingAddress, paymentOption } = values || {};
    saveBillingAddress(
      { address: billingAddress, shoppingCartId },
      null,
      null,
      (result) => {
        if (result) {
          clearSaveBillingAddressResult();
          setBilling({ ...details, paymentOption });
          history.push({
            pathname: "/checkout/review"
          })
        }
      });
  }


  render() {
    const {
      showModal,
      cart,
      locations,
      cartItemsLoading,
      locationsLoading,
      user,
      saveBillingAddressLoading,
      saveBillingAddressFailed,
      paymentOption: selectedPaymentOption,
      billing
    } = this.props;
    const { shippingAddress, billingAddress } = cart || {};
    const { userPaymentOptions } = user;
    const { message } = saveBillingAddressFailed || {};
    const metadata = {
      title: 'Payment | Checkout | Concept Services'
    };
    const initialValues = getInitialValues({ shippingAddress, billingAddress, billing });

    return (
      <Layout route="checkout-payment" metadata={metadata} fluid>
        <main>

          <Row noGutters>
            <Col md={7} lg={8}>

              <div className="checkout-body">

                <div className="steps">
                  <ol>
                    <li>
                      <span>Shipping</span>
                    </li>
                    <li className="active">
                      <span>Payment</span>
                    </li>
                    <li>
                      <span>Review</span>
                    </li>
                  </ol>
                </div>

                {(cartItemsLoading || locationsLoading || saveBillingAddressLoading)
                  ?
                  <Loading />
                  :
                  <CheckoutPaymentForm
                    enableReinitialize
                    showModal={showModal}
                    locations={locations}
                    shippingAddress={shippingAddress}
                    onSubmit={this.onPaymentFormSubmitHandler}
                    initialValues={initialValues}
                    paymentOptions={userPaymentOptions}
                    saveBillingAddressLoading={saveBillingAddressLoading}
                  />
                }
              </div>

            </Col>
            <Col md={5} lg={4}>
              {!cartItemsLoading
                &&
                !saveBillingAddressLoading
                &&
                <CheckoutCart selectedPaymentOption={selectedPaymentOption} />
              }

            </Col>
          </Row>

          {saveBillingAddressFailed &&
            (
              <Notification
                key="save-billingaddress-fail"
                duration={3}
                closable={true}
                type="danger"
              >
                {message ? message : 'Failed to save billing address password. Please try again'}
              </Notification>
            )
          }

          {/* <EditBillingAddressModal showModal={showModal} /> */}
        </main>
      </Layout>
    );
  }
}

const selector = formValueSelector(FORM_NAME)
const mapState = state => {
  const { billing } = state || {};
  const {
    result: user
  } = state.validateLogin.toJS();

  const {
    inProgress: cartItemsLoading,
    result: cart
  } = state.getCart.toJS();

  const {
    result: locations,
    inProgress: locationsLoading
  } = state.getLocationsByCompany.toJS();

  const {
    inProgress: saveBillingAddressLoading,
    error: saveBillingAddressFailed
  } = state.saveBillingAddress.toJS();

  const paymentOption = selector(state, 'paymentOption') || {};

  return {
    cartItemsLoading,
    cart,
    user,
    locationsLoading,
    locations,
    billing,
    saveBillingAddressLoading,
    saveBillingAddressFailed,
    paymentOption
  }
};
const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  ...billingActions,
  getCart: fetchDux.getCart.createAction,
  getLocationsByCompany: fetchDux.getLocationsByCompany.createAction,
  saveBillingAddress: fetchDux.saveBillingAddress.createAction,
  clearSaveBillingAddressResult: fetchDux.saveBillingAddress.clearAction
}, dispatch);

export default connect(mapState, mapDispatch)(CheckoutPayment);
