import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import numeral from 'numeral';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import { getShop } from '../../../../utils/shop-utils';
import CheckoutCart from '../CheckoutCart';
import CheckoutShippingForm from './CheckoutShippingForm';
import queryString from 'query-string';
import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';

const getInitialValues = ({ locations, user: result, shippingAddress }) => {
  const location = shippingAddress ? shippingAddress : locations ? locations[0] : {};
  const {
    ID,
    Address1,
    Address2,
    City,
    PostalCode,
    StateProvince,
    specialInstructions,
    businessName
  } = location || {};

  const { user } = result || {};

  const { nameFirst, nameLast } = user || {};

  return {
    orderLocation: ID || -1,
    address: {
      Address1,
      Address2,
      City,
      PostalCode,
      StateProvince,
      nameFirst,
      nameLast,
      ID: ID,
      specialInstructions,
      businessName
    }
  }
}

class CheckoutShipping extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      getCart,
      getLocationsByCompany,
      user,
      location: { search },
      getApprovalCart
    } = this.props;

    const query = queryString.parse(search);
    const shoppingCartApprovalId = query.shoppingCartApprovalId;
    if (shoppingCartApprovalId) {
      getApprovalCart({ shoppingCartApprovalId }, null, null, (cart) => {
        if (cart) getLocationsByCompany({ companyId: cart.companyId });
      });
    } else {
      const shop = getShop(user || {})
      getCart({ companyId: shop })
      getLocationsByCompany({ companyId: shop });
    }
  }

  componentDidUpdate() {
    const {
      cart,
      cartItemsLoading,
      history,
      approvalCartLoading
    } = this.props;
    const { shoppingCartItems } = cart || {}
    if (!cartItemsLoading && !approvalCartLoading) {
      if (!shoppingCartItems || (shoppingCartItems && shoppingCartItems.length === 0)) {
        history.push("/shop");
      }
    }
  }

  render() {
    const {
      showModal,
      cart,
      locations,
      user,
      saveShippingAddress,
      deleteCartItem,
      clearSaveShippingAddressResult,
      history,
      cartItemsLoading,
      approvalCartLoading,
      locationsLoading,
      saveShippingAddressLoading,
      saveShippingAddressFailed
    } = this.props;
    const metadata = {
      title: 'Shipping | Checkout | Concept Services'
    };

    const { message } = saveShippingAddressFailed || {};

    const {
      shoppingCartId,
      shippingAddress
    } = cart || {};

    const initialValues = getInitialValues({ locations, user, shippingAddress })

    return (
      <Layout route="checkout-shipping" metadata={metadata} fluid>
        <main>
          <Row noGutters>
            <Col md={7} lg={8}>
              <div className="checkout-body">
                <div className="steps">
                  <ol>
                    <li className="active">
                      <span>Shipping</span>
                    </li>
                    <li>
                      <span>Payment</span>
                    </li>
                    <li>
                      <span>Review</span>
                    </li>
                  </ol>
                </div>

                {(
                  cartItemsLoading ||
                  approvalCartLoading ||
                  locationsLoading ||
                  saveShippingAddressLoading
                )
                  ?
                  <Loading />
                  :
                  (
                    <CheckoutShippingForm
                      enableReinitialize
                      locations={locations}
                      showModal={showModal}
                      initialValues={initialValues}
                      saveShippingAddressLoading={saveShippingAddressLoading}
                      onSubmit={
                        (shippingAddress) => {
                          const orderLocation = shippingAddress.orderLocation
                            || locations && locations[0] && locations[0].ID;
                          saveShippingAddress(
                            { address: shippingAddress.address, shoppingCartId, orderLocation },
                            null,
                            null,
                            (data) => {
                              if (data) {
                                clearSaveShippingAddressResult();
                                history.push("/checkout/payment");
                              }
                            })
                        }
                      }
                    />
                  )
                }
              </div>

            </Col>
            <Col md={5} lg={4}>
              {
                !cartItemsLoading &&
                !approvalCartLoading &&
                !saveShippingAddressLoading &&
                <CheckoutCart
                  showDelete
                  onCheckoutPage
                />
              }

            </Col>
          </Row>

          {/* <EditShippingAddressModal showModal={showModal} /> */}

          {saveShippingAddressFailed &&
            (
              <Notification
                key="save-shippingaddress-fail"
                duration={3}
                closable={true}
                type="danger"
              >
                {message ? message : 'Failed to save shipping address password. Please try again'}
              </Notification>
            )
          }
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user
  } = state.validateLogin.toJS();

  const {
    result: locations,
    inProgress: locationsLoading,
  } = state.getLocationsByCompany.toJS();

  const {
    inProgress: cartItemsLoading,
    result: cart
  } = state.getCart.toJS();

  const {
    inProgress: approvalCartLoading,
    result: approvalCart
  } = state.getApprovalCart.toJS();

  const {
    inProgress: saveShippingAddressLoading,
    error: saveShippingAddressFailed
  } = state.saveShippingAddress.toJS()

  return {
    user,
    cart: cart || approvalCart,
    locations,
    cartItemsLoading,
    approvalCartLoading,
    locationsLoading,
    saveShippingAddressLoading,
    saveShippingAddressFailed
  }
};
const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  getCart: fetchDux.getCart.createAction,
  deleteCartItem: fetchDux.deleteCartItem.createAction,
  getLocationsByCompany: fetchDux.getLocationsByCompany.createAction,
  saveShippingAddress: fetchDux.saveShippingAddress.createAction,
  clearSaveShippingAddressResult: fetchDux.saveShippingAddress.clearAction,
  getApprovalCart: fetchDux.getApprovalCart.createAction
}, dispatch);

export default connect(mapState, mapDispatch)(CheckoutShipping);
