import React from 'react';
import { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import RcNotification from 'rc-notification';

const getNotification = props =>
  new Promise((resolve, reject) => {
    RcNotification.newInstance(props, resolve);
  });

export default class Notification extends Component {
  constructor(props) {
    super(props);
  }
  async componentWillMount() {
    const { prefixCls } = this.props;
    this.notification = await getNotification({
      prefixCls,
      style: { left: 0, top: 'auto', bottom: 0 }
    });

    const {
      id,
      closable,
      onClose,
      duration,
      type,
      children
    } = this.props;

    const content = (
      <Alert variant={type || 'info'}>
        {children}
      </Alert>
    );
    const style = { left: '20px' };
    this.notification.notice({
      key: id,
      closable,
      onClose,
      duration,
      content,
      style
    });
  }
  componentWillUnmount() {
    try {
      this.notification.destroy(this.props.id);
    } catch (err) {}
  }
  render() {
    return null;
  }
}
