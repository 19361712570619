import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DocumentsSearchResults from './DocumentsSearchResults';
import SearchDocumentsForm from './SearchDocumentsForm';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import { getDocuments } from '../../../../api/api';
import queryString from 'query-string';
import Loading from '../../../ui/Loading';

const formatQuery = (
  {
    search
  }
) => ({
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};
class DocumentsSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }
  componentDidMount() {
    const {
      getDocuments
    } = this.props;
    getDocuments();
  }
  render() {
    const {
      getDocumentsResult,
      location: { pathname, search },
      history,
      inProgress
    } = this.props;

    const result = getDocumentsResult || [];
    const metadata = {
      title: 'Documents | Concept Services'
    };
    const handleSearchChange = (e) => {
      const {
        value
      } = e.target || {};

      const qs = queryString.stringify({ search: value });
      history.push({ pathname, search: qs });
    };
    const query = getQueryFromSearch(search);

    const {
      search: urlSearchValue
    } = query || {};
    return (
      <Layout route="documents-search" metadata={metadata} sidebar fluid>
        <main>

          <Row>
            <Col md={7}>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/documents">Documents</Link>
                  </li>
                </ol>
              </nav>
              <h2 className="layout-subheader">
                Documents
              </h2>
            </Col>
            {
              !inProgress &&
              <Col md={5} className="text-right">
                <SearchDocumentsForm
                  handleSearchChange={handleSearchChange}
                  initialValues={{ search: urlSearchValue }}
                />
              </Col>
            }

          </Row>

          {
            inProgress ?
              <Loading />
              :
              <div className="box box-table">
                <DocumentsSearchResults result={result}
                  search={urlSearchValue} />
              </div>
          }

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  {
    const {
      result: getDocumentsResult,
      error: getDocumentsError,
      inProgress
    } = state.getDocuments.toJS();
    return {
      getDocumentsResult,
      getDocumentsError,
      inProgress
    }
  }
};
const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  getDocuments: fetchDux.getDocuments.createAction
}, dispatch);

export default connect(mapState, mapDispatch)(DocumentsSearch);
