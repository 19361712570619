import React, { useState } from 'react';
import numeral from 'numeral';
import Icon from '../../../ui/Icon';

const QuoteItemsRow = props => {
  const { row, ModelNoAddToWeb, ManufacturerAddToWeb, CategoryAddToWeb, SpecAddToWeb } = props || {};
  const [isOpen, toggle] = useState(false);
  return (
    <tbody>
      <tr className={isOpen ? 'active' : ''}>
        <td className="toggle">
          {(row.lineItems || []).filter((lineItem) => lineItem.Show).length > 0 &&
            <a onClick={() => toggle(!isOpen)}>
              {!isOpen && <Icon fixedWidth name="chevron-right" />}
              {isOpen && <Icon fixedWidth name="chevron-down" />}
            </a>}
        </td>
        <td>{row.ItemNumber}</td>
        <td>{row.Quantity}</td>
        {ManufacturerAddToWeb && <td>{row.Manufacturer}</td>}
        {ModelNoAddToWeb && <td>{row.Model}</td>}
        {CategoryAddToWeb && <td>{row.Category}</td>}
        {SpecAddToWeb && <td>{row.Spec}</td>}
        <td>${numeral(row.UnitPrice).format('0,0.00')}</td>
        <td>${numeral(row.SellPrice).format('0,0.00')}</td>
      </tr>
      {isOpen &&
        <tr className="items">
          <td colSpan={9}>
            <table>
              {/*
                <thead>
                  <tr>
                    <th width="34" />
                    <th width="90">Item No</th>
                    <th width="60">Qty</th>
                    <th>Description</th>
                    <th width="110">Unit Price</th>
                    <th width="110">Sell Price</th>
                    <th width="70" />
                  </tr>
                </thead>
              */}
              <tbody>
                {(row.lineItems || []).map((item, index) => {
                  if (!item.Show) return null
                  return (
                    <tr key={index}>
                      <td width="34" />
                      <td width="90">{row.ItemNumber}.{index + 1}</td>
                      <td width="60">{item.Quantity}</td>
                      <td><b>Description:</b> {item.c_ItemDescription}</td>
                      {/* <td width="110">
                        ${numeral(item.NetPrice).format('0,0.00')}
                      </td> */}
                      {/* There is no status on the item, hence commenting below */}
                      {/* <td width="110">{item.status}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>}
    </tbody>
  );
};

const QuoteItems = props => {
  const { items, ManufacturerAddToWeb, ModelNoAddToWeb, SpecAddToWeb, CategoryAddToWeb } = props || {};
  return (
    <div className="quote-items">
      <table>
        <thead>
          <tr>
            <th width="34" />
            <th width="90">Item No</th>
            <th width="60">Qty</th>
            {ManufacturerAddToWeb && <th>Manufacturer</th>}
            {ModelNoAddToWeb && <th>Model No</th>}
            {CategoryAddToWeb && <th>Category</th>}
            {SpecAddToWeb && <th>Spec</th>}
            <th width="110">Unit Price</th>
            <th width="110">Extended Price</th>
          </tr>
        </thead>
        {(items || []).map((item, index) => {
          return <QuoteItemsRow
            key={index}
            row={item}
            {...{ ModelNoAddToWeb, ManufacturerAddToWeb, CategoryAddToWeb, SpecAddToWeb }}
          />;
        })}
      </table>
    </div>
  );
};

export default QuoteItems;
