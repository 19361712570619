import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import TablePagination from '../../../ui/TablePagination';
import numeral from 'numeral';
import moment from 'moment';

import QuoteStatus from '../../../ui/QuoteStatus';

const formatMoney = value => numeral(value).format('0,0.00');
const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM Do, Y');
};

const columns = [
  {
    Header: 'Quote Name',
    accessor: 'Name',
    Cell: ({ value, original }) => {
      const { Status, ExpirationDate } = original;
      const hasExpired = Status === 5 || moment(ExpirationDate).isBefore(moment());
      return hasExpired ?
        <span>{value}</span> :
        <Link to={`/quotes/${original.Id}`}>{value}</Link>
    }
  },
  {
    Header: 'Proposal Number',
    accessor: 'QuoteNumber'
  },
  {
    Header: 'Purchaser',
    accessor: 'Purchaser'
  },
  {
    Header: 'Expires',
    accessor: 'ExpirationDate',
    Cell: ({ value, original }) => {
      const { Status, ExpirationDate } = original;
      const hasExpired = Status === 5 || moment(ExpirationDate).isBefore(moment());
      return hasExpired ?
        <span className='text-danger'>{formatTime(value)}</span> :
        <span>{formatTime(value)}</span>
    }
  },
  {
    Header: 'Plans Dated',
    accessor: 'PlanDate',
    Cell: ({ value }) => <span>{formatTime(value)}</span>
  },
  {
    Header: 'Price',
    accessor: 'GrandSellTotal',
    Cell: ({ value }) => <span>${formatMoney(value)}</span>
  },
  {
    Header: 'Status',
    accessor: 'Status',
    Cell: ({ value, original }) => {
      const { Status, ExpirationDate } = original;
      const hasExpired = Status === 5 || moment(ExpirationDate).isBefore(moment());
      return <QuoteStatus status={hasExpired ? 5 : value } />
    }
  }
];

const QuotesSearchResults = (
  {
    error,
    result
  }
) => {
  return (
    <Table
      data={result}
      columns={columns}
      noDataText="No quotes found"
      PaginationComponent={TablePagination}
    />
  );
};

export default QuotesSearchResults;
