import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const passwordMatch = (...props) =>{
  const {password, confirmPassword} = props[1] || {};
  return password !== confirmPassword ? "Passwords do not match." : undefined;
}

const passwordLength = (...props) =>{
  const {password} = props[1] || {};
  return password.length < 8 ? "Password must be at least 8 characters long." : undefined;
}

const CreatePasswordForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <p className="text-muted">
        Create your account password to access Concept Services Shop Portal.
      </p>
      <Field
        name="email"
        type="text"
        component={FormField.Input}
        label="Email"
        validate={[
          required
        ]}
      />
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        label="Password"
        validate={[
          required,
          passwordLength
        ]}
      />
      <Field
        name="confirmPassword"
        type="password"
        component={FormField.Input}
        label="Confirm Password"
        validate={[
          required,
          passwordMatch
        ]}
      />
      <div>
        <Button variant="primary" type="submit" disabled={submitting}>
          Create Password
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'create-password-form'
})(CreatePasswordForm);
