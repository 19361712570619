import React from 'react';
import { Link } from 'react-router-dom';

const CardShopCategory = ({ categoryName, categoryId, shop, image }) =>{
  const encodedCategory = encodeURIComponent(categoryId);
  return (
    <div
      className="box-shop-category"
      style={{ backgroundImage: `url(${image})` }}
    >
      <h4>{categoryName}</h4>
      <Link to={`/shop/categories?category=${categoryId}`}>View All</Link>
    </div>
  );
}

export default CardShopCategory;
