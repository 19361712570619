import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CheckoutShipping from './checkout-shipping/CheckoutShipping.js';
import CheckoutPayment from './checkout-payment/CheckoutPayment.js';
import CheckoutReview from './checkout-review/CheckoutReview.js';
import CheckoutComplete from './checkout-complete/CheckoutComplete.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={CheckoutShipping} />
    <Route exact path={`${match.url}/shipping`} component={CheckoutShipping} />
    <Route exact path={`${match.url}/payment`} component={CheckoutPayment} />
    <Route exact path={`${match.url}/review`} component={CheckoutReview} />
    <Route exact path={`${match.url}/complete`} component={CheckoutComplete} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
