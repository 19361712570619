import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ShopHomepage from './shop-homepage/ShopHomepage.js';
import ShopPackage from './shop-package/ShopPackage.js';
import ShopCategories from './shop-categories/ShopCategories.js';
import ShopProduct from './shop-product/ShopProduct.js';

export default ({ match }) => (
  <Switch>
    <Route 
      exact 
      path={`${match.url}`} 
      component={ShopHomepage} 
    />
    <Route
      exact
      path={`${match.url}/categories`}
      component={ShopCategories}
    />
    <Route 
      exact 
      path={`${match.url}/products`} 
      component={ShopProduct} 
    />
    <Route 
      exact 
      path={`${match.url}/packages`} 
      component={ShopPackage} 
    />
    <Redirect 
      to={`${match.url}`} 
    />
  </Switch>
);
